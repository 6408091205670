/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@mixin animated-bottom-border($color1, $color2) {
    &::before {
        content: "";
        position: absolute;
        inset-block-start: 100%;
        width: 100%;
        inset-inline-start: 0;
        height: 2px;
        background-color: $color1;
    }

    &::after {
        content: "";
        position: absolute;
        inset-block-start: 100%;
        height: 2px;
        transition: width .3s cubic-bezier(.165,.84,.44,1);
        inset-inline-start: auto;
        inset-inline-end: 0;
        width: 0;
        background-color: $color2;
    }

    &:hover {
        &::after {
            inset-inline-start: 0;
            inset-inline-end: auto;
            width: 100%;
        }
    }
}

@mixin expanded-content {
    padding: 1rem;
    opacity: 1;
    max-height: 100%;
}

@mixin placeholder-animation-with-padding {
    background-image: var(--placeholder-image);
    background-size: var(--placeholder-size);
    animation: var(--placeholder-animation);
    color: transparent;
}

@mixin placeholder-animation {
    @include placeholder-animation-with-padding;

    padding: 0;
}

@mixin isArabic {
    html[dir='rtl'] & {
        @content;
    }
}

@mixin isNotArabic {
    html[dir='ltr'] & {
        @content;
    }
}

@mixin content-type-image-size-adaption-animation(
    $animation-length,
    $min-height,
    $max-height,
    $maximum-max-height: 1500
) {
    .Image {
        &-Image {
            min-height: 0;
            max-height: $maximum-max-height * 1px;
            transition: min-height $animation-length * 1s ease-in, max-height $animation-length * 1s ease-out;

            &_isLoading {
                overflow: hidden;
                min-height: $min-height * 1px;
                max-height: $max-height * 1px;
            }
        }
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

.StyleGuide {
    padding: 8rem 4rem 0 8rem;

    h3 {
        border-bottom: 1px solid var(--color-gray-nero);
        padding-block-end: 10px;
    }

    & > div {
        margin-block-end: 50px;
    }

    &-ColorItem {
        display: flex;
        width: 300px;
        justify-content: space-between;
        margin-block-end: 10px;

        h4 {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-inline-end: 10px;
        }
    }

    &-ColorBlock {
        width: 50px;
        height: 50px;

        &_color {
            &_black {
                background: var(--color-black);
            }

            &_gray-white {
                background: var(--color-white);
            }

            &_gray-nero {
                background: var(--color-gray-nero);
            }

            &_gray-title {
                background: var(--color-gray-title);
            }

            &_gray-text {
                background: var(--color-gray-text);
            }

            &_gray-button {
                background: var(--color-gray-button);
            }

            &_gray-disabled {
                background: var(--color-gray-disabled);
            }

            &_silver {
                background: var(--color-silver);
            }

            &_input-border {
                background: var(--color-input-border);
            }

            &_input-label {
                background: var(--color-input-label);
            }

            &_brown-mongoose {
                background: var(--color-brown-mongoose);
            }

            &_brown-mongoose-light {
                background: var(--color-brown-mongoose-light);
            }

            &_gray-mortar {
                background: var(--color-gray-mortar);
            }

            &_orange-jaffa {
                background: var(--color-orange-jaffa);
            }

            &_pink-rose {
                background: var(--color-pink-rose);
            }

            &_red {
                background: var(--color-red);
            }

            &_red-dark {
                background: var(--color-red-dark);
            }


            &_red-cardinal {
                background: var(--color-red-cardinal);
            }
        }
    }

    &-FontsBlock {
        &_font {
            &_ffdin-bold {
                font-family: var(--font-FFDIN);
                font-weight: bold;
            }
        }
    }

    &-MixinItem {
        h4 {
            &:first-child {
                font-family: var(--font-FFDIN);
                font-weight: bold;
            }
        }

        display: flex;
        width: 300px;
        justify-content: space-between;
    }

    &-IconItem {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;
        max-width: 1000px;
        margin-block-end: 10px;

        & > span,
        & > h4 {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        span {
            font-family: var(--font-FFDIN);
            font-weight: bold;
            font-size: 14px;
        }

        div {
            &:nth-child(4) {
                background-color: var(--color-gray-nero);
            }

            &.Icon {
                &-Puma {
                    &_color_black {
                        background-color: transparent;
                    }
                }
            }
        }

        .Icon {
            &-Facebook,
            &-Instagram,
            &-Twitter,
            &-Youtube,
            &-Puma,
            &-PumaWithText {
                background-color: var(--color-gray-nero);
            }
        }
    }

    &-ButtonItem {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        max-width: 700px;
        margin-block-end: 10px;

        span {
            font-family: var(--font-FFDIN);
            font-weight: bold;
            font-size: 14px;
        }

        & > span,
        & > h4 {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    &-LinkItem {
        display: flex;
        gap: 30px;
        max-width: 700px;
        margin-block-end: 20px;

        span {
            font-family: var(--font-FFDIN);
            font-weight: bold;
            font-size: 14px;
        }

        & > span,
        & > h4 {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &_background_black {
            &>div {
                background-color: var(--color-gray-nero);
                padding: 10px;
            }

            a {
                height: fit-content;
            }
        }

        a {
            &.Link-Chart,
            &.Link-Primary {
                inset-block-start: 15px;
            }
        }
    }

    &-InputItem {
        margin-block-end: 30px;

        h4 {
            font-family: var(--font-FFDIN);
            font-weight: bold;
        }
    }

    &-InputProps {
        display: flex;
        flex-direction: column;
        font-size: var(--font-size-normal);
    }

    .Field {
        width: 500px;

        input {
            width: 90%;
        }
    }
}

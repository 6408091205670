/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.trending-inline-item {
    &__picture {
        @include content-type-image-size-adaption-animation(.3, 250, 250);

        .Image {
            height: 100%;
            padding-block-end: 0;
            min-height: 250px;
            line-height: 0;
            background-image: var(--placeholder-image);
            background-size: var(--placeholder-size);
            animation: var(--placeholder-animation);

            &-Image {
                position: relative;

                &_isLoading {
                    @include desktop {
                        min-height: 550px;
                        max-height: 550px;
                    }

                    @include small-desktop {
                        min-height: 350px;
                        max-height: 350px;
                    }
                }
            }
        }
    }

    &__title {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 22px;
        font-weight: bold;

        @include desktop {
            font-size: 27px;
        }

        @include small-desktop {
            font-size: 24px;
        }
    }

    &__content {
        text-align: center;
        padding: 20px 23px 48px 23px;

        @include desktop {
            padding: 32px 24px;
        }
    }

    &__button {
        font-size: var(--font-size-medium-small);
        margin-inline-start: .64rem;
        margin-inline-end: .64rem;

        @include small-desktop {
            font-size: var(--font-size-normal-small);
            margin: .5rem;
        }

        &:last-child {
            margin-block-start: 15px;

            @include small-mobile {
                margin-block-start: 0;
            }

            @include small-desktop {
                margin-block-start: .5rem;
            }
        }
    }

    &__text,
    &__text:last-of-type {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 1.76rem;
        line-height: 1.25;
        margin-block-end: 1.2rem;

        @include desktop {
            font-size: 1.8rem;
            margin-block-end: .46rem;
        }

        @include small-desktop {
            font-size: 15px;
            line-height: 1.35;
            margin: 0 0 1rem;
        }
    }
}

@media only screen and (min-width: 1025px) {
    .trending-inline-group {
        display: flex;
        justify-content: center
    }
}

@media (max-width: 767px) {
    .trending-inline-group {
        padding-inline-start: 0;
        padding-inline-end: 0
    }
}

@media only screen and (min-width: 1025px) {
    .trending-inline-item {
        width: 33.33333333%;
    }
}

.trending-inline-item__media-w {
    position: relative;
}

.trending-inline-item__media {
    display: block;
}

.trending-inline-item__media::after {
    content: '';
    background: #000;
    position: absolute;
    height: calc(100% - 6px);
    inset-block-start: 0;
    inset-block-end: 0;
    inset-inline-start: 0;
    inset-inline-end: 0;
    z-index: 1;
    opacity: 0;
    transition: opacity .3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .trending-inline-item__media:hover::after {
        opacity: .2;
    }
}

.trending-inline-item__img {
    display: block;
    width: 100%;

    @include desktop {
        display: none;
    }

    &_desktop {
        display: none;

        @include small-desktop {
            display: contents;
        }

        .TrendingInlineGroupCarousel-Container & {
            @include small-desktop {
                display: block;
            }
        }
    }

    &_tablet {
        display: none;

        @include desktop {
            display: contents;
        }

        @include small-desktop {
            display: none;
        }

        .TrendingInlineGroupCarousel-Container & {
            @include desktop {
                display: block;
            }

            @include small-desktop {
                display: none;
            }
        }
    }
}

.trending-inline-item__title-w {
    line-height: 1;
    margin: 0 0 .8rem;
    text-transform: uppercase
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .trending-inline-item__title-w {
        margin-block-end: .6rem
    }
}

@media (max-width: 767px) {
    .trending-inline-item__title-w {
        margin-block-end: 1.3rem
    }
}

.trending-inline-group-grid {
    column-gap: 0.5rem;

    @include mobile {
        display: grid;
        grid-template-columns: 49.5% 49.5%;
        column-gap: 1%;
        margin-inline: 2%;
        overflow: hidden;

        .trending-inline-item {
            &__content {
                z-index: 5;
                padding: 12px 8px 8px;
                text-align: center;
                pointer-events: none;
            }

            &__buttons {
                margin-block-end: 6px;
            }

            &__button {
                margin-block-start: 10px;
            }

            &__picture {
                .Image {
                    min-height: unset;
                }
            }

            &__title {
                font-size: 20px;

                &-w {
                    margin-block-end: 0;
                }
            }

            &__text {
                /* stylelint-disable-next-line declaration-no-important */
                margin: 0 !important;
            }
        }
    }
}

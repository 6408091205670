@font-face {
    font-family: 'FFDIN';
    font-weight: bold;
    font-display: swap;
    src: local('FFDIN Bold'),
        local('FFDIN-Bold'),
        url(../fonts/FFDINforPUMA-Bold.woff2) format('woff2'),
        url(../fonts/FFDINforPUMA-Bold.woff) format('woff');
}

@font-face {
    font-family: 'FFDIN';
    font-weight: normal;
    font-display: block;
    src: local('FFDIN Regular'),
        local('FFDIN-Regular'),
        url(../fonts/FFDINforPUMA-Regular.woff2) format('woff2'),
        url(../fonts/FFDINforPUMA-Regular.woff) format('woff');
}

@font-face {
    font-family: 'DINArabic';
    font-weight: normal;
    font-display: block;
    src: local('DINArabic Regular'),
        local('DINArabic-Regular'),
        url(../fonts/DINOffcW05-Regular.woff2) format('woff2'),
        url(../fonts/DINOffcW05-Regular.woff) format('woff');
}

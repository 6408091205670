/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --input-border-color: #{$c-input-border};
    --input-background-color: #{$white};
    --input-padding: 13px 15px;
    --input-color: #{$c-input-border};
    --input-margin-block-end: 21px;
    --input-position-bottom: -18px;

    @include after-mobile {
        --input-position-bottom: -20px;
    }
}

input,
textarea,
select {
    border: 1px solid var(--input-color);
    background-color: var(--input-background-color);
    padding: var(--input-padding);
    appearance: none;
    caret-color: #{$black};
    font-weight: normal;

    &:focus {
        border: 1px solid var(--input-color);
        font-family: var(--font-FFDIN);
        font-weight: bold;

        @include isArabic {
            font-family: var(--font-DINArabic);
        }
    }

    &:not(:first-of-type) {
        margin-block-start: 1rem;
    }
}

textarea {
    width: 25rem;
}

[type='number'] {
    width: var(--input-type-number-width);
    font-family: var(--font-FFDIN);
    font-weight: bold;
    padding-inline-end: 0;
    -moz-appearance: textfield;

    @include isArabic {
        font-family: var(--font-DINArabic);
    }

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        appearance: none;
        margin: 0;
    }

    ~ button {
        position: absolute;
        inset-inline-start: calc(var(--input-type-number-width) - 1.5rem);
        padding: 0;
        margin: 0;
        height: 50%;
        width: 1.5rem;
        font-size: 1.5rem;
        line-height: 0;

        &:disabled {
            opacity: .2;
            cursor: not-allowed;
        }

        &:last-child {
            inset-block-end: 0;
        }
    }
}

.FieldInput {
    &-Wrapper {
        position: relative;
    }

    &-Select {
        font-size: var(--font-size-small);

        @include after-mobile {
            font-size: var(--font-size-medium);
        }
    }

    &-Input {
        font-size: var(--font-size-medium);

        &:focus,
        &_isFilled {
            padding-block-start: 17px;
            padding-block-end: 9px;

            @include after-mobile {
                padding-block-start: 19px;
                padding-block-end: 7px;
            }
        }

        &:focus ~ label.FieldInput-Label,
        & ~ label.FieldInput-Label_isFilled,
        &_isFilled ~ label.FieldInput-Label {
            font-size: var(--font-size-pico);
            inset-block-start: 35%;

            @include after-mobile {
                font-size: var(--font-size-nano);
            }
        }
    }

    &-Label {
        position: absolute;
        inset-block-start: 50%;
        transform: translateY(calc(-50% - 1px));
        text-transform: uppercase;
        color: var(--color-input-label);
        inset-inline-start: 15px;
        transition: all .3s ease;

        @include tablet {
            inset-block-start: 50%;
        }

        html[dir='rtl'] & {
            inset-inline-end: 15px;
        }

        &_isRequired {
            &::after {
                content: '*';
                position: relative;
                inset-inline-start: 2px;
                inset-block-end: 1px;

                html[dir='rtl'] & {
                    inset-inline-end: 2px;
                }
            }
        }
    }
}

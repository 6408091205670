/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

.MobileLoginForm {
    display: none;

    .LoginPage {
        margin-block-start: -5rem;
    }

    .MyAccountOverlay-Buttons {
        inset-block-start: 1rem;

        @include mobile {
            inset-block-start: -1rem;
        }

        .MobileRegister {
            &:hover {
                @include mobile {
                    background-color: var(--color-white);
                    color: var(--color-black);
                }
            }
        }
    }

    &-Terms {
        margin-block-start: -28px;
        inset-block-start: -48px;

        span {
            color: $c-orange-jaffa;
        }
    }

    @include mobile {
        display: block;
        inset-block-start: 5rem;
        width: 95%;
        padding-block-end: 1rem;
        margin-block-end: 1rem;
    }

    &-Checkbox_isConfirmed {
        z-index: 2;
        width: 28px;
    }

    .Icon {
        &-CheckGreen {
            @include mobile {
                position: absolute;
                inset-inline-end: 1rem;
                inset-block-start: 19px;
                width: 13px;
                height: 12px;
            }
        }
    }

    .Form {
        .Field {
            margin-block-end: 2px;

            @include mobile {
                margin-block-start: 0;
            }

            &-Label {
                @include mobile {
                    font: .8rem var(--font-FFDIN);
                    font-weight: bold;
                    align-items: center;
                    inset-block-end: 3px;

                    @include isArabic {
                        font: .8rem var(--font-DINArabic);
                    }
                }
            }

            &-Message {
                @include mobile {
                    margin-block-end: 0;
                    font: .7rem var(--font-FFDIN);
                    font-weight: bold;
                    position: absolute;
                    inset-block-start: 2.9rem;

                    @include isArabic {
                        font: .7rem var(--font-DINArabic);
                    }
                }
            }
        }

        .FieldInput {
            &-Wrapper {
                margin-block-end: 0;

                input {
                    @include mobile {
                        width: 100%;
                    }
                }

                div {
                    margin-block-end: 2px;
                }

                .Field-CheckboxLabel {
                    .input-control {
                        @include mobile {
                            margin-block-end: 0;
                            height: 28px;
                            width: 28px;
                        }
                    }
                }
            }

            &-Error {
                @include mobile {
                    font: .7rem var(--font-FFDIN);
                    min-height: 5px;
                    inset-block-start: 0;
                    position: relative;

                    @include isArabic {
                        font: .7rem var(--font-DINArabic);
                    }
                }

                ~ label {
                    inset-block-start: 41%;
                }
            }
        }

        .Button {
            &-Forgot {
                @include mobile {
                    font: .7rem var(--font-FFDIN);
                    font-weight: bold;
                    border-bottom: 1px solid var(--color-black);

                    @include isArabic {
                        font: .7rem var(--font-DINArabic);
                    }
                }
            }
        }
    }

    .MobileFormButtons {
        @include mobile {
            display: flex;
            justify-content: space-between;

            ._active {
                // Can't set css values on active button without important flag
                /* stylelint-disable-next-line declaration-no-important */
                color: var(--color-black) !important;
                /* stylelint-disable-next-line declaration-no-important */
                border-bottom: .2rem solid var(--color-black) !important;
                /* stylelint-disable-next-line declaration-no-important */
                opacity: 1 !important;
            }

            .LoginMobileButton,
            .RegisterMobileButton {
                @include mobile {
                    width: 100%;
                    height: 3rem;
                    inset-block-start: -.3rem;
                    padding-block-end: 6rem;

                    > button {
                        font: 1rem var(--font-FFDIN);
                        font-weight: bold;
                        line-height: 3.2rem;
                        color: var(--color-black);
                        margin-block-start: 1.3rem;
                        letter-spacing: -.05rem;
                        border-bottom: .2rem solid var(--color-input-border);
                        opacity: .4;

                        @include isArabic {
                            font: 1rem var(--font-DINArabic);
                        }
                    }
                }

                .Link-Chart {
                    @include mobile {
                        width: 100%;
                        height: 5rem;
                        inset-block-start: -1.3rem;
                    }
                }
            }
        }
    }

    .Link-Chart {
        text-transform: uppercase;
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * Copyright © Scandiweb, Inc. All rights reserved.
 *
 * @category    Puma
 * @package     Puma_MenuFilters
 * @author      Deniss Strombergs <info@scandiweb.com>
 */

.MenuFilters {
    &-MenuFiltersPlaceholder {
        margin-block-end: 30px;

        .TextPlaceholder::after {
            display: block;
            height: 250px;
        }
    }

    @include after-mobile {
        display: flex;
        flex-wrap: wrap;
        margin-block-start: 8px;
        row-gap: 5px;
    }

    .ExpandableContent {
        margin-block-end: 0;

        &-Button {
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: 12px 0 14px 0;

            @include mobile {
                border-top: solid 1px $c-gray-de;
                padding: 11px 0;
            }

            @include after-mobile {
                border: solid 1px $c-gray-de;
                margin-inline-end: 8px;
                padding: 10px 40px 10px 12px;
                width: auto;

                @include isArabic {
                    padding: 10px 12px 10px 40px;
                    margin-inline-end: 0;
                    margin-inline-start: 8px;
                }
            }

            &::after,
            &::before {
                height: 12px;
                width: 2px;
                content: '';
                background-color: $c-gray-c;
            }

            &_isContentExpanded {
                &::after,
                &::before {
                    background-color: $black;
                }
            }
        }

        &-Heading {
            font-size: 17px;
            font-weight: bold;
            text-transform: uppercase;

            @include mobile {
                font-size: 19px;
                font-weight: normal;
            }
        }

        &-SubHeading {
            display: none;
        }

        &-Content {
            max-height: 0;
            overflow: hidden;
            margin: 0;
            opacity: 0;

            @include after-mobile {
                position: absolute;
            }

            &_isContentExpanded {
                @include expanded-content;

                padding: 0;
                padding-block-end: 25px;

                @include after-mobile {
                    background-color: #ffffffff;
                    border: solid 1px $c-gray-de;
                    margin-block-start: 8px;
                    min-width: 200px;
                    min-height: initial;
                    max-height: 220px;
                    overflow: auto;
                    padding: 10px;
                    z-index: 1;
                }

                button {
                    @include after-mobile {
                        font-size: 16px;
                        padding: 3px 0;
                    }
                }
            }
        }
    }

    @include mobile {
        display: none;
    }

    &-Link {
        font-size: 14px;
        color: $black;
        margin-inline-start: 0;

        &:focus {
            text-decoration: none;
        }

        &_isSelected, &:hover {
            color: $c-red;
            text-decoration: none;
        }
    }

    &-MenuFiltersPlaceholders {
        &_isLoading {
            opacity: 0;
            animation: appear 100ms 300ms forwards;

            .MenuFilter-Item {
                @include placeholder-animation;

                display: flex;
                align-items: center;
                margin: 14px 0 30px;
                font-size: 17px;
                font-weight: bold;
                width: fit-content;

                &::before {
                    content: none;
                }
            }
        }
    }
}

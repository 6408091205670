/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --menu-font-color: var(--color-white);
    --sumbenu-wrapper-width: 100vw;
}

@keyframes showImage {
    0% { transform: translateX(-100%); }

    100% { transform: translateX(0); }
}

@keyframes hideImage {
    0% { transform: translateX(0); }

    100% { transform: translateX(100%); }
}

@mixin fullHDMedia() {
    @media (min-width: 1920px) and (max-width: 1921px) {
        @content;
    }
}

@keyframes menuIn {
    0% { opacity: 0; }

    100% { opacity: 1; }
}

.ImageCurrentAnimation {
    animation-name: showImage;
    animation-duration: .4s;
}

.ImagePrevAnimation {
    animation-name: hideImage;
    animation-duration: .4s;
}

.Menu {
    &-MenuWrapperDesktop {
        display: flex;
        min-width: 0px;
        width: max-content;

        @include desktop {
            margin: 0 0 0 48px;
        }

        @include extra-large-desktop {
            margin: 0 0 0 57px;
        }

        .Menu {
            &-SubCategoriesWrapper {
                width: var(--sumbenu-wrapper-width);
                inset-inline-start: calc(((52px + 45px + 3rem) * -1));
                margin-block-start: -14px;
                inset-block-start: calc(var(--header-nav-height) + 8px);

                @include isArabic {
                    inset-inline-start: calc(((52px + 45px - 1rem) * -1));
                }

                @include extra-large-desktop {
                    margin-block-start: -2px;
                    inset-inline-start: calc(((57px + 45px + 3rem) * -1));

                    @include isArabic {
                        inset-inline-start: calc(((57px + 45px - 1.75rem) * -1));
                    }
                }

                @include fullHDMedia() {
                    /* stylelint-disable-next-line declaration-no-important */
                    inset-inline-start: calc(((var(--sumbenu-wrapper-width) - 1920px) / -2) + ((57px + 45px + 3.65rem) * -1)) !important;

                    @include isArabic {
                        /* stylelint-disable-next-line declaration-no-important */
                        inset-inline-start: calc(((var(--sumbenu-wrapper-width) - 1920px) / -2) + ((57px + 45px - 1rem) * -1)) !important;
                    }
                }

                @include largest-screen {
                    inset-inline-start: calc(((var(--sumbenu-wrapper-width) - 1920px) / -2) + ((57px + 45px + 3rem) * -1));

                    @include isArabic {
                        inset-inline-start: calc(((var(--sumbenu-wrapper-width) - 1920px) / -2) + ((57px + 45px - 1rem) * -1));
                    }
                }
            }

            &-SubCategoriesWrapperInner {
                background: var(--color-white);
                transition: clip-path 200ms ease-in;
                will-change: clip-path;
                clip-path: inset(0 0 100% 0);
                animation: menuIn;
                animation-duration: .35s;
                inset-block-start: -3px;

                &_isVisible {
                    clip-path: inset(0);
                }
            }

            &-CustomGroupTitle {
                font-size: 14px;
                font-family: var(--font-FFDIN);
                font-weight: bold;
                margin: 42px 0 10px;

                @include isArabic {
                    font-family: var(--font-DINArabic);
                }

                &::first-letter {
                    text-transform: uppercase;
                }
            }

            &-ItemList {
                display: flex;
                flex-direction: row;
                margin-block-start: 10px;
                flex-wrap: wrap;

                &_type_main {
                    margin-inline-start: 0;
                    margin-block-start: 0;

                    @include desktop {
                        @include menu-scrollbar;

                        max-width: 100%;
                        overflow-x: auto;
                        overflow-y: hidden;
                        padding-block: 10px;
                        flex-wrap: initial;
                    }

                    li {
                        padding: 0;

                        &:last-child {
                            margin: 0;
                        }
                    }

                    &.Menu {
                        &-ItemList {
                            &_isHovered {
                                .Menu-Link {
                                    opacity: .4;
                                    transition: opacity .2s;
                                    transform: translateZ(0);

                                    &_isHovered {
                                        opacity: 1;
                                    }
                                }
                            }
                        }
                    }

                    .Menu {
                        &-Link {
                            div {
                                font-size: var(--font-size-medium-big);
                                padding: 0 15px;

                                @include extra-large-desktop {
                                    padding: 0 20px;
                                }

                                @include after-extra-large-desktop {
                                    padding: 0 30px;
                                    font-size: var(--fs-header-desktop-small);
                                }
                            }

                            &::after {
                                transition: opactity .2s;
                                transition-delay: 0s;
                                content: '';
                                position: absolute;
                                inset-block-start: 100%;
                                inset-inline-start: 0;
                                inset-inline-end: 0;
                                height: 2px;
                                background-color: var(--color-brown-mongoose);
                                transform: translateY(2px);
                                opacity: 0;
                                width: 60%;
                                margin: 0 auto;

                                @include isArabic {
                                    inset-block-start: 140%;
                                }
                            }

                            &_isHovered {
                                &::after {
                                    opacity: 1;
                                }
                            }

                            &_isSmall {
                                div {
                                    font-size: var(--font-size-medium-big);
                                    padding-inline: 15px;
                                }
                            }
                        }
                    }
                }

                &_type_subcategory {
                    &.Menu-ItemList {
                        &_isHover {
                            & > div {
                                &::before {
                                    opacity: .1;
                                }
                            }

                            .Menu-SubItemWrapper_isHover {
                                &::before {
                                    opacity: 1;
                                }
                            }
                        }
                    }

                    & > div {
                        padding-inline-end: 10px;

                        &::before {
                            content: '';
                            transition: opacity .15s ease 0s;
                            position: absolute;
                            inset-block-start: 0;
                            width: calc(100% - 10px);
                            inset-inline-start: 0;
                            height: 2px;
                            background-color: var(--color-gray-nero);
                        }
                    }
                }

                .Menu {
                    &-ItemList {
                        flex-direction: column;
                    }
                }
            }

            &-Item {
                margin-inline-start: 0;

                &::before {
                    content: none;
                }

                @include desktop {
                    height: unset;
                }

                &_isHighlighted {
                    >.Menu-Link {
                        color: var(--color-red);

                        &:hover {
                            color: var(--color-red);
                        }
                    }
                }
            }

            &-ItemName {
                &_isSale {
                    color: var(--color-red);
                }

                &_isSubCategory {
                    position: absolute;
                    inset-block-start: -25px;
                    font-size: var(--font-size-medium-small);
                }

                &_isHighlighted {
                    color: var(--color-red-cardinal);

                    &:hover {
                        color: var(--color-brown-mongoose);
                    }
                }
            }

            &-SubCategories {
                background: var(--color-white);
                padding: 80px 40px;

                @include extra-large-desktop {
                    padding: 80px 0;
                }

                &_isSlider {
                    display: flex;

                    .Menu {
                        &-SubMenu {
                            width: 60%;
                        }

                        &-SubItemWrapper {
                            flex-basis: 0;
                            flex-grow: 1;
                            margin-inline-end: -10px;

                            &_isHidden {
                                & > a {
                                    display: none;
                                }
                            }
                        }

                        &-ImagesContainer {
                            width: 40%;
                            overflow: hidden;
                            min-height: 400px;
                        }

                        &-ImageCurrent,
                        &-ImagePrev {
                            width: 100%;
                            display: inline-block;
                            position: absolute;

                            .Image {
                                display: inline-block;
                                width: 100%;
                                height: 100%;
                            }

                            img {
                                inset-inline-start: 0;
                                inset-inline-end: 0;
                                position: relative;
                            }

                            span {
                                width: 100%;
                                height: 100%;
                                background: var(--color-white);
                                color: var(--color-white);
                                position: relative;
                            }
                        }

                        &-ImageCurrent {
                            position: relative;
                        }

                        &-ImagePrev {
                            .Image {
                                transform: translateX(100%);
                            }
                        }
                    }
                }

                a {
                    color: var(--color-gray-nero);

                    &:hover {
                        color: var(--color-brown-mongoose);
                    }

                    & > div {
                        font-family: var(--font-FFDIN);
                        font-weight: bold;

                        @include isArabic {
                            font-family: var(--font-DINArabic);
                        }
                    }
                }
            }

            &-SubLevelDesktop {
                a {
                    color: var(--color-gray-mortar);
                    font-size: var(--menu-customgroup-title-font);

                    &:hover {
                        color: var(--color-black);
                    }

                    & > div {
                        margin: 0 0 10px;
                        font-family: var(--font-FFDIN);
                        font-weight: normal;
                        font-size: 15px;

                        @include isArabic {
                            font-family: var(--font-DINArabic);
                        }
                    }
                }
            }

            &-SubItemWrapper {
                flex-basis: unset;
                order: 2;
                margin-inline-end: 0;
                margin-block-end: 7px;
                overflow: unset;
                flex-grow: 1;

                &_isPromotional {
                    order: 1;
                    flex-direction: column;
                    justify-content: flex-start;
                    margin-block-start: -30px;

                    a {
                        & > div {
                            font-family: var(--font-FFDIN);
                            font-weight: normal;
                            font-size: 17px;

                            @include isArabic {
                                font-family: var(--font-DINArabic);
                            }
                        }
                    }

                    &::before {
                        display: none;
                    }

                    & > div {
                        width: 100%;
                    }
                }
            }

            &-Link {
                text-transform: capitalize;
                white-space: nowrap;

                &:hover,
                &:focus {
                    color: var(--color-white);
                }
            }
        }

        &_isLoading {
            opacity: 0;
            animation: appear 100ms 300ms forwards;

            .Menu-Item {
                @include placeholder-animation;

                display: flex;
                align-items: center;
                margin: 0 20px 0 20px;
                font-size: 16px;
                opacity: .4;
            }
        }
    }

    &-MainCategories {
        display: block;
        padding-inline-start: 1rem;
        padding-inline-end: 40px;
        z-index: 100;
        max-width: 100%;
        width: 100%;
        margin: auto;

        @include isArabic {
            padding-inline-start: 40px;
            padding-inline-end: 1rem;
        }

        .Menu-Link {
            color: var(--color-white);
        }
    }

    &-SubCategoriesWrapper {
        position: absolute;

        &::before {
            content: '';
            position: absolute;
            inset-block-end: 0;
            inset-inline: 0;
            inset-block-start: calc(var(--header-nav-height) * -1);
        }
    }

    &-SubCategories,
    &-Promotion {
        max-width: var(--content-wrapper-width);
        margin: auto;
    }

    &-Overlay {
        background-color: rgba(122, 122, 122, .44);
        height: 100vh;
        width: 100%;
        position: absolute;
        z-index: -1;
        inset-block-start: -3px;
        inset-inline-start: 0;
        opacity: 0;
        transition: opacity 200ms ease-in;
        will-change: transition;

        &_isVisible {
            opacity: 1;
        }
    }
}

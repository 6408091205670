/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

.Router {
    &-FooterPlaceholder {
        width: 100%;
        min-height: 600px;

        @include mobile {
            min-height: 100vh;
        }
    }
}

//noinspection ALL
@supports (-webkit-touch-callout: none) {
    html {
        height: -webkit-fill-available;

        @include mobile() {
            height: var(--window-inner-height-checkout);
        }
    }

    body {
        min-height: 100vh;
        min-height: -webkit-fill-available;
    }

    #root, .LocalizationWrapper {
        min-height: -webkit-fill-available;
    }
}

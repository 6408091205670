/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

blockquote {
    line-height: 1.6;
    margin-block-end: 1rem;
    padding: .5rem 1.25rem 0 1.2rem;
    border-left: 1px solid var(--secondary-dark-color);
    color: var(--secondary-dark-color);
    font-size: 1rem;
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --slider-arrow-height: 19px;
    --slider-arrow-width: 19px;
}

.Slider {
    direction: ltr;

    &-Crumbs {
        inset-block-end: 70px;

        @include desktop {
            inset-block-end: 55px;
        }
    }

    &-Crumb {
        background-color: unset;
        border: 1px solid var(--color-grey-c);
        width: 8px;
        height: 8px;

        &_isActive {
            background-color: var(--color-gray-crumb);
            border-color: var(--color-gray-crumb);
            width: 10px;
            height: 10px;
        }
    }

    &-Arrow {
        @include desktop {
            border: solid var(--color-grey-c);
            border-width: 0 2px 2px 0;
            height: var(--slider-arrow-height);
            width: var(--slider-arrow-width);
            transition: border-color .25s ease-in-out;

            &:hover {
                border-color: var(--color-red);
                transition: border-color .25s ease-in-out;
            }

            &_type {
                &_left {
                    transform: rotate(135deg);
                    position: absolute;
                    inset-block-start: 48%;
                    inset-inline-end: 100%;

                    @include isArabic {
                        inset-inline-end: unset;
                        inset-inline-start: 100%;
                    }

                    .ProductGallery-SliderOverlay_isVisible & {
                        inset-inline-end: 96%;
                        inset-block-start: 50%;
                        z-index: 20;

                        @include extra-large-desktop {
                            inset-inline-end: 97%;
                        }
                    }
                }

                &_right {
                    transform: rotate(-45deg);
                    position: absolute;
                    inset-block-end: 48%;
                    inset-inline-start: 100%;

                    @include isArabic {
                        inset-inline-start: unset;
                        inset-inline-end: 100%;
                    }

                    .ProductGallery-SliderOverlay_isVisible & {
                        z-index: 20;
                        inset-inline-start: 96%;
                    }
                }
            }

            &_noNextSlide,
            &Container_noNextSlide {
                display: none;
            }
        }

        span {
            display: none;
        }
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

$white: #fff;
$black: #000;
$default-primary-base-color: #f26323;
$default-primary-dark-color: #d1490c;
$default-primary-light-color: #f69e77;
$default-secondary-base-color: #eee;
$default-secondary-dark-color: #929292;
$default-secondary-light-color: #f8f8f8;
$font-muli: 'Muli', sans-serif;
$font-standard-size: 12px;
$font-mobile-size: 14px;

// Colors:
$c-gray-nero: #181818; //header bg
$c-gray-title: #222; //title color
$c-gray-text: #818181; //text color in footer
$c-gray-button: #323232; //button bg
$c-gray-disabled: #747474;
$c-silver: #cbcbcb; //display when hovering over $c-white text
$c-input-border: #ebebeb;
$c-input-label: #aaa;
$c-brown-mongoose: #ae946d; //text color/underline on hover, button
$c-brown-mongoose-light: #b7a07d;
$c-charcoal: #464646;
$c-white-smoke: #f6f6f6;
$c-orange-jaffa: #f1913b; //banner bg
$c-pink-rose: #e8c0bc; //banner bg
$c-red: #f00;
$c-red-dark: #c00;
$c-red-vivid: #ff1f1f;
$c-red-cardinal: #ba2026; //menu item important
$c-red-primary-link: #ff2525;
$c-gray-mortar: #545454; //menu item
$c-gray-gainsboro: #e1e1e1; //vertical slider elements border color
$c-gray-crumb: #888; //slider crumb color
$c-gray-e: #eee;
$c-gray-fb: #fbfbfb;
$c-gray-c: #ccc;
$c-gray-7: #777;
$c-gray-f6: #f6f6f6;
$c-green: #5fb065;
$c-gray-alto: #d1d1d1;
$c-green-notification: #56c946;
$c-gray-de: #dedede;
$c-gray-c8: #c8c8c8;
$c-grey-d5: #d5d5d5;
$c-gray-3: #333;
$c-gray-e4: #e4e4e4;
$c-gray-97: #979797;
$c-gray-ad: #adadad;
$c-gray-d8: #d8d8d8;

// Font famlies:
$font-FFDIN: 'FFDIN', Arial, sans-serif;
$font-DINArabic: 'DINArabic', Arial, sans-serif;

// File path:
$icons-path: '../../public/assets/images/icons/';
$globals-path: '../../public/assets/images/global/';

// Font sizes:
$fs-pico: 8px;
$fs-nano: 9px;
$fs-micro: 10px;
$fs-small: 11px;
$fs-normal-small: 12px;
$fs-normal: 13px;
$fs-medium-small: 14px;
$fs-medium: 15px;
$fs-medium-big: 16px;
$fs-header-desktop-small: 18px;
$fs-header-desktop: 19px;

// Scrollbar
$scrollbar-track-color: rgb(241, 241, 241);
$scrollbar-track-hover-color: rgb(241, 241, 241);
$scrollbar-thumb-color: rgb(193, 193, 193);
$scrollbar-thumb-hover-color: rgb(168, 168, 168);
$scrollbar-thumb-active-color: rgb(120, 120, 120);

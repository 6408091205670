/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.pac {
    &-container {
        /* stylelint-disable-next-line declaration-no-important */
        inset-inline-start: unset !important;
        /* stylelint-disable-next-line declaration-no-important */
        inset-block-start: unset !important;
        border: 1px solid #d9d9d9;
        border-top: 0;
    }

    &-item {
        cursor: pointer;

        span {
            text-transform: uppercase;
        }
    }
}

.FieldAddress {
    &-Suggestions {
        position: absolute;
        inset-block-start: 47px;
        width: 100%;
        height: fit-content;
        background: var(--color-white);
        z-index: 3;
        border: 1px solid var(--input-color);

        &_isHidden {
            display: none;
        }

        li:last-child {
            margin-block-end: 0;
        }
    }

    &-Suggestion {
        padding: 10px 15px 10px 15px;

        &:hover {
            background-color: var(--secondary-light-color);
        }

        &::before {
            display: none;
        }
    }
}

.FieldInput-Input_isHidden {
    color: white;
}

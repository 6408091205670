/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --vertical-slider-arrow-height: 9px;
    --vertical-slider-arrow-width: 9px;
}

.AdditionalSlider {
    --animation-speed: 0;
    --slider-height: 0;
    --translateY: 0;

    max-height: 392px;
    overflow: hidden;
    touch-action: pan-x;
    transition: height 300ms;
    width: 61px;

    &-Wrapper {
        align-items: flex-start;
        display: flex;
        height: max-content;
        width: fit-content;
        inset-block-end: 12px;
        inset-inline-start: 22px;
        transform: translate3d(0, var(--translateY), 0);
        transition: transform var(--animation-speed);
        will-change: transform;

        > * {
            flex-grow: 1;
            flex-shrink: 0;
            height: 100%;
            width: 100%;
        }

        &_isLoading {
            inset-block-end: unset;
            inset-block-start: 0;
            grid-row-gap: 10px;

            .Slider {
                &-Arrow {
                    display: none;
                }
            }
        }

        .Slider-Arrow {
            @include desktop {
                border-width: 0 1px 1px 0;
                height: var(--vertical-slider-arrow-height);
                position: absolute;
                inset-inline-end: calc(50% - 5px);
                width: var(--vertical-slider-arrow-width);

                &_type {
                    &_left {
                        inset-block-start: 0;
                        transform: rotate(-135deg);

                        @include isArabic {
                            inset-block-start: unset;
                            inset-block-end: 0;
                            inset-inline-start: calc(50% - 5px);
                            transform: rotate(45deg);
                        }
                    }

                    &_right {
                        inset-block-end: 0;
                        inset-block-start: 100%;
                        transform: rotate(45deg);
                        inset-inline-start: unset;

                        @include isArabic {
                            inset-block-end: unset;
                            inset-block-start: 0;
                            inset-inline-start: calc(50% - 5px);
                            transform: rotate(-135deg);
                        }
                    }
                }
            }
        }
    }
}

.regions-content__popup {
    background-color: var(--color-grey-e);

    .locale-selector-language {
        text-align: center;

        h6 {
            font-weight: bold;
            font-size: 16px;
            margin-block-end: 7px;
        }

        .Link-LanguageSwitcher {
            font-weight: bold;
            text-transform: uppercase;
            border-bottom: 2px solid var(--color-black);
            text-decoration: none;
            font-size: 17px;
            font-family: var(--font-DINArabic);

            &:hover {
                color: var(--color-red);
                border-color: var(--color-red);
            }
        }

        a {
            font-weight: bold;
            text-transform: uppercase;
            border-bottom: 2px solid var(--color-black);
            text-decoration: none;
            font-size: 17px;

            &:hover {
                color: var(--color-red);
                border-color: var(--color-red);
            }
        }
    }

    &-inner {
        max-width: 900px;
        margin: 0 auto;
    }

    .hide-on-mobile {
        display: none;

        @include after-mobile() {
            display: block;
            font-size: 17px;
            padding: 40px 0 24px;
            font-family: var(--font-FFDIN);
            font-weight: bold;
            text-align: center;

            @include isArabic {
                font-family: var(--font-DINArabic);
            }
        }
    }

    .countries {
        &__row {
            flex-direction: column;
            flex-wrap: wrap;
            position: relative;
            display: flex;

            @include after-mobile() {
                flex-direction: row;
            }
        }

        &__col {
            padding-inline-end: 0;
            width: 45%;

            &:last-child {
                @include after-mobile() {
                    margin-inline-end: 0;
                }
            }

            @include after-mobile() {
                padding-inline-end: 5rem;
                width: 31%;
            }

            @include small-desktop() {
                padding-inline-end: 7rem;
            }

            &._narrow {
                @include after-mobile() {
                    order: 5;
                    padding-inline-end: 0;
                    width: 15%;
                    z-index: 1;
                }
            }

            &._wide {
                padding-block-end: 1.5rem;
                position: absolute;
                inset-inline-end: 0;

                html[dir='rtl'] & {
                    inset-inline-start: 0;
                    inset-inline-end: unset;
                }

                inset-block-start: 0;

                @include after-mobile() {
                    width: 54%;
                    position: relative;
                    inset-inline-end: unset;
                    inset-inline-start: unset;
                    padding-inline-end: 5rem;
                }
            }
        }

        &__part {
            &-name {
                color: $c-red;
                text-transform: uppercase;
                overflow: hidden;
                margin: 17px 0 11px;
                font-family: var(--font-FFDIN);
                font-weight: normal;

                @include isArabic {
                    font-family: var(--font-DINArabic);
                }

                @include after-mobile() {
                    font-size: $fs-medium;
                }

                @include small-desktop() {
                    font-size: $fs-header-desktop;
                    margin: 20px 0;
                }

                span {
                    @include after-mobile() {
                        &::after {
                            content: '';
                            border-bottom: solid 1px var(--color-grey-c);
                            margin-inline-start: 1rem;
                            position: absolute;
                            inset-block-end: 2px;
                            inset-inline-start: 100%;
                            height: 0;
                            width: 700px;
                        }
                    }
                }
            }

            &._middle {
                @include after-mobile() {
                    width: 50%;
                }
            }

            &._last {
                @include after-mobile() {
                    margin-inline-start: -177%;
                }

                @include small-desktop() {
                    margin-inline-start: -191%;
                }
            }
        }

        &__combine-parts {
            @include after-mobile() {
                min-height: 411px;
            }

            @include small-desktop() {
                min-height: 470px;
            }
        }

        &__list {
            .ExpandableContent {
                &-Button {
                    min-height: unset;
                    padding: 0;
                    height: fit-content;

                    &::before,
                    &::after {
                        content: none;
                    }
                }

                &-Heading {
                    font-family: var(--font-FFDIN);
                    font-weight: normal;
                    font-size: $fs-micro;
                    text-transform: uppercase;
                    cursor: pointer;

                    @include isArabic {
                        font-family: var(--font-DINArabic);
                    }

                    @include after-mobile() {
                        font-size: $fs-normal;
                    }

                    @include small-desktop() {
                        font-size: $fs-medium;
                    }
                }

                &-Content {
                    padding: 0;
                }
            }

            li {
                list-style: none;
                margin: 0;
                padding: 0;
                text-transform: uppercase;

                &::before {
                    content: none;
                }
            }

            &-row {
                @include after-mobile() {
                    display: flex;
                    gap: 20px;
                }
            }
        }

        &__item {
            margin: 2px 0;

            &-locales {
                padding-inline-start: 10px;

                @include after-mobile {
                    padding-block-start: 5px;
                    padding-inline-start: 17px;
                    padding-block-end: 3px;
                }
            }

            &-locale {
                font-size: $fs-nano;

                @include after-mobile() {
                    font-size: $fs-small;
                    line-height: 16px;
                }

                @include small-desktop() {
                    font-size: $font-mobile-size;
                }

                a {
                    text-transform: none;
                    color: #888;

                    &:hover {
                        color: $c-red;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * @author Vladislavs Zimnikovs <vladislavs.zimnikovs@scandiweb.com>
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package puma-mx
 */

.Tabs {
    &-TabList {
        border-bottom: 1px solid var(--color-input-border);
        border-top: 1px solid var(--color-input-border);
        margin: 0 -2.4rem;
        padding: 0 2.4rem;

        @include desktop {
            margin: 0 -4.8rem;
            padding: 0 4.8rem;
        }
    }

    &-InactiveTabList {
        .Tabs-TabButton {
            &_isActive {
                display: none;
            }
        }
    }

    &-TabButton {
        padding: 1.2rem 0;
        font-weight: bold;
        will-change: color;
        transition: color .3s;
        font-size: var(--font-size-normal-small);

        @include isArabic {
            padding-inline-start: 1.2rem;
            padding-inline-end: 0;
        }

        @include desktop {
            padding: 2.1rem 2rem 2rem;
            font-size: initial;

            @include isArabic {
                padding-inline-end: 0;
                padding-inline-start: 2.1rem;
            }
        }

        &:first-of-type {
            padding-inline-start: 0;
        }

        &:last-of-type {
            padding-inline-end: 0;
        }

        &:hover {
            will-change: color;
            transition: color .3s;
            color: var(--color-red);
        }

        &_isActive {
            color: var(--color-red);
        }
    }
}

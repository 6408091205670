/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.PasswordChangePage {
    min-height: calc(100vh - var(--header-height));
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

    @include before-desktop {
        h1 {
            margin-block-end: 1.5rem;
        }
    }

    @include after-mobile {
        h1 {
            margin-block-end: 2.5rem;
        }
    }

    @include mobile {
        margin-block-start: 0;
    }

    &-Wrapper {
        flex-grow: 1;

        button {
            margin-block-start: 0px;

            @include mobile {
                width: 100%;
            }
        }
    }

    .Field_type_password {
        &:has(.Icon-CheckGreen) {
            display: flex;
            margin-block-end: 20px;
        }

        .FieldInput-Wrapper {
            width: 93%;

            @include mobile {
                width: 88%;
            }
        }

        .Icon-CheckGreen {
            margin: auto;
            height: 12px;
            width: 12px;
        }
    }
}

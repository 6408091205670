/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

.SizeSelectorPopup {
    .Popup-Header {
        button.Popup-CloseBtn {
            @include mobile {
                inset-block-start: 2px;
            }
        }
    }

    .Loader {
        overflow: hidden;
    }

    &-Options {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        min-width: 100%;

        @include after-mobile {
            min-height: 43px;
            min-width: unset;
            width: 688px;
        }

        > div {
            border: 1px solid $c-gray-c;
            text-align: center;
            flex-basis: calc(25% - 6px);

            @include after-mobile() {
                flex-basis: calc(20% - 7px);
            }

            @media (max-width: 500px) {
                flex-basis: calc(50% - 4px);
            }

            &:hover {
                /* stylelint-disable-next-line declaration-no-important */
                border: 1px solid var(--button-border) !important;
            }
        }
    }
}

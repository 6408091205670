/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --header-height: 61px;
    --minicart-item-count-background: var(--color-red-cardinal);
    // stylelint-disable-next-line
    --header-top-banner-height: 0px; // wont work without 'px'
    --header-nav-max-width: 1920px;
    --search-field-height: 39px;

    @include extra-large-desktop {
        --header-height: 81px;
        --search-field-height: 45px;
    }
}

.Header {
    position: fixed;
    inset-block-start: 10000px;
    height: 0;
    background: var(--color-gray-nero);
    padding: 4px 0;
    width: unset;

    @include after-mobile {
        --header-nav-height: 61px;

        border-bottom: none;
    }

    @include large-desktop {
        position: sticky;
        inset-block-start: 0;
        height: var(--header-height);
    }

    &-LogoWrapper {
        inset-inline-end: unset;
        min-height: unset;
        height: 45px;
        width: 45px;
        position: relative;
        margin: 0 0 0 4px;
        z-index: 1;
        flex-shrink: 0;

        @include extra-large-desktop {
            margin: 0;
            padding: 3px;
        }
    }

    &-Nav {
        padding: 0 3rem;
        max-width: var(--header-nav-max-width);
        height: 100%;
        justify-content: space-between;
        display: flex;
    }

    &-NavLeft,
    &-NavRight {
        align-items: center;
        display: flex;
    }

    &-NavLeft {
        max-width: 70%;
    }

    &-NavRight {
        min-height: var(--search-field-height);
        max-width: 35%;
    }

    &-MinicartButtonWrapper {
        border: unset;
        padding-inline-end: 12px;
        margin-inline-start: 0px;

        @include isArabic {
            padding-inline-end: 6px;
            margin-inline-start: 4px;
        }

        svg {
            height: 24px;
            width: 24px;

            &:hover {
                opacity: .6;
            }
        }
    }

    &-WishlistButton {
        inset-block-start: -2px;
    }

    &-MiniWishlistItemCount,
    &-MinicartItemCount {
        background-color: var(--minicart-item-count-background);
        border-radius: 50%;
        padding: 0px;
        color: $white;
        inset-block-end: 12px;
        inset-inline-end: 2px;
        position: absolute;
        font-size: var(--font-size-small);
        font-weight: 700;
        display: flex;
        width: 21px;
        height: 21px;
        justify-content: center;
        align-items: center;
        font-family: Arial, sans-serif;
    }

    &-MiniWishlistItemCount {
        inset-block-end: 3px;

        @include isArabic {
            inset-block-end: 12px;
            inset-inline-end: 0px;
        }
    }

    &_name {
        &_cart_overlay {
            .Header-MinicartItemCount {
                @include after-mobile {
                    color: $white;
                }
            }
        }
    }

    &-LanguageSwitch {
        color: var(--color-white);
        font-size: var(--font-size-medium-big);
        height: 38px;
        margin-inline-end: 12px;
        margin-inline-start: 20px;

        @include large-desktop {
            height: 44px;
        }

        @include isArabic {
            margin-inline-end: unset;
        }
    }

    &-MyAccount {
        @include desktop {
            height: auto;
            inset-block-start: 2px;
        }
    }

    &-MyAccountWrapper {
        svg {
            height: 38px;
            width: 38px;

            &:hover {
                opacity: .6;
            }
        }

        .Icon-User {
            height: 38px;
            width: 38px;

            &:hover {
                opacity: .6;
            }
        }
    }

    &-Button {
        &_type {
            &_minicart {
                @include desktop {
                    height: auto;
                    margin-inline-end: 0;
                    margin-inline-start: 0;
                    padding: 8px;
                    width: auto;
                    inset-block-start: 2px;
                }
            }
        }
    }

    &-MinicartWrapper {
        transition: background-color, 0.2s;

        &_isVisible {
            position: fixed;
            inset-inline-start: 0px;
            inset-block-start: 0px;
            height: 100vh;
            width: 100vw;
            background-color: rgba(0, 0, 0, 0.1);
            z-index: 1000;

            .Header-MinicartButtonWrapper {
                display: none;
            }
        }
    }

    &-MinicartWrapperPlaceHolder {
        width: 75px;
        height: 41px;
        display: block;
    }

    &-MinicartOutside {
        width: 100%;
        height: 100%;
    }

    &-MiniWishlistIcon {
        /* stylelint-disable-next-line declaration-no-important */
        fill: white !important;
        margin-inline: 10px;

        @include isArabic {
            inset-block-start: unset;
            inset-block-end: 3px;
        }
    }
}

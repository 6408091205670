/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

@mixin very-small-mobile {
    @media (max-width: 400px) {
        @content;
    }
}

.PromoTile {
    padding: 0;
    margin: 20px 0;

    &::before {
        content: none;
    }

    .grid__item {
        height: 100%;

        * {
            height: 100%;
        }

        img {
            object-fit: cover;
        }
    }

    .promo-tile {
        &__button {
            @include very-small-mobile {
                font-size: 10px;
                min-width: 100%;
            }
        }

        &__title {
            @include very-small-mobile {
                word-break: break-word;
            }
        }

        &__content {
            position: absolute;
            height: unset;
            padding: 20px;

            &_horizontal {
                &-left {
                    inset-inline-start: 0;
                    text-align: start;
                }

                &-center {
                    text-align: center;
                    width: 100%;
                }

                &-right {
                    inset-inline-end: 0;
                    text-align: end;
                }
            }

            &_vertical {
                &-top {
                    inset-block-start: 0;
                }

                &-middle {
                    inset-block-start: 50%;
                    transform: translateY(-50%);
                }

                &-bottom {
                    inset-block-end: 0;
                }
            }
        }
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

.Popup {
    &_block_SubscriptionPopupNonRegister {
        .Popup {
            &-Content {
                padding: 2rem 4rem 3rem 3rem;
                width: 60rem;
                background-color: var(--color-black);
                position: relative;
                inset-block-start: 50px;

                @include mobile {
                    padding: 1rem 3rem 2rem 2rem;
                }
            }

            &-Header {
                .Popup-CloseBtn {
                    html[dir='rtl'] & {
                        inset-inline-start: -1rem;
                    }
                }

                button {
                    inset-block-start: 0;
                    inset-inline-start: 3rem;

                    @include mobile {
                        inset-inline-start: 2rem;
                    }

                    &::before,
                    &::after {
                        inset-block-start: 0;
                    }
                }
            }
        }
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

.side-by-side-item {
    width: 50%;
    position: relative;

    .Image {
        &-Image_isLoading {
            min-height: 350px;

            @include desktop {
                min-height: 500px;
            }
        }
    }

    img {
        object-fit: cover;
        position: relative;
    }
}

@media (max-width: 1024px) {
    .side-by-side-item {
        width: 100%;
    }
}

.side-by-side-item__img {
    width: 100%;
    vertical-align: top;
    display: block;

    @include desktop {
        display: none;
    }

    &_desktop {
        display: none;

        @include small-desktop {
            display: contents;
        }
    }

    &_tablet {
        display: none;

        @include desktop {
            display: contents;
        }

        @include small-desktop {
            display: none;
        }
    }
}

@media (max-width: 767px) {
    .side-by-side-item__img {
        display: block;
        margin: 0 auto;
    }
}

.side-by-side-item__title {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font: 54px 'FFDIN', Arial, sans-serif;
    line-height: 1;
    text-transform: uppercase;
    color: $white;
    letter-spacing: 2px;
    font-weight: bold;
}

@media only screen and (min-width: 768px) and (max-width: 1180px) {
    .side-by-side-item__title {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font: 27px 'FFDIN', Arial, sans-serif;
        line-height: 1;
        font-weight: bold;
    }
}

@media (max-width: 767px) {
    .side-by-side-item__title {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font: 22px 'FFDIN', Arial, sans-serif;
        line-height: 32px;
        font-weight: bold;
    }
}

.side-by-side-item__button {
    margin: 16px 0 0;
    min-width: auto;
}

@media (max-width: 1024px) {
    .side-by-side-item__button {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font: 14px 'FFDIN', Arial, sans-serif;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1180px) {
    .side-by-side-item__button {
        margin-block-start: 20px;
    }
}

@media (max-width: 767px) {
    .side-by-side-item__button {
        margin-block-start: 17px;
    }
}

.side-by-side-item__cnt {
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
}

@media only screen and (min-width: 1181px) {
    .side-by-side-item__cnt {
        position: absolute;
        inset-block-start: 50%;
        inset-inline-start: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
}

@media only screen and (min-width: 768px) and (max-width: 1180px) {
    .side-by-side-item__cnt {
        margin-block-start: 35px;
        margin-block-end: 60px;
    }
}

@media (max-width: 767px) {
    .side-by-side-item__cnt {
        margin-block-start: 30px;
        margin-block-end: 50px;
    }
}

.side-by-side-group {
    display: flex;
    justify-content: center;
}

@media (max-width: 1024px) {
    .side-by-side-group {
        display: block;
    }
}

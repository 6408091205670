/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

.DatePicker {
    display: flex;
    justify-content: center;

    .Field {
        select {
            padding: 13px 18px 13px 8px;
            margin-block-start: 1rem;
            color: var(--color-input-label);
            font-weight: unset;
            text-transform: unset;

            @include desktop {
                span {
                    text-transform: unset;
                }
            }

            @include isArabic {
                padding: 13px 8px 13px 18px;
            }
        }

        .FieldSelect {
            &-Option {
                text-transform: unset;
            }

            &-Options_isExpanded {
                max-height: 200px;
            }

            &::after {
                height: 5px;
                width: 5px;
                inset-inline-end: 10px;
            }
        }
    }

    &-Years {
        .FieldSelect-Option {
            padding-inline-start: .7rem;
        }
    }

    &-Months {
        min-width: 107px;
    }

    &-Days {
        min-width: 56px;
    }

    &-Years,
    &-Months {
        margin-inline-end: 10px;
    }
}

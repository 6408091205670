/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

.split-hero {
    display: flex;
    direction: ltr;

    .Image {
        padding-block-end: 0;

        &-Image_isLoading {
            min-height: 500px;
        }
    }

    img {
        object-fit: cover;
        position: relative;
    }
}

@media only screen and (min-width: 1025px) {
    .split-hero {
        align-items: stretch;
    }
}

@media (max-width: 1024px) {
    .split-hero {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
}

.split-hero__media {
    display: block;
    position: relative;
}

@media only screen and (min-width: 1025px) {
    .split-hero__media {
        width: 66.66666667%;
    }
}

@media (max-width: 1024px) {
    .split-hero__media {
        max-height: 100%;
        min-height: 100%;
        width: 100%;
    }
}

.split-hero__img {
    width: 100%;
    display: block;
    height: 100%;
    object-fit: cover;

    @include desktop {
        display: none;
    }

    &_desktop {
        display: none;

        @include desktop {
            display: block;
            height: 100%;
        }
    }
}

.split-hero__content {
    position: relative;

    @include isArabic {
        direction: rtl;
    }
}

@media only screen and (min-width: 1025px) {
    .split-hero__content {
        width: 33.33333333%;
    }
}

@media (max-width: 1024px) {
    .split-hero__content {
        order: 2;
        width: 100%;
    }
}

.split-hero__content_right {
    order: 2;
}

.split-hero__content-i {
    overflow: hidden;
    padding: 24px;
    text-align: center;
}

@media (max-width: 1024px) {
    .split-hero__content-i {
        padding-block-end: 48px;
    }
}

@media only screen and (min-width: 1025px) {
    .split-hero__content-i {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
    }
}

.split-hero__logo {
    width: 100%;
    object-fit: contain;
}

.split-hero__title-w {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font: 40px 'FFDIN', Arial, sans-serif;
    line-height: 1;
    letter-spacing: 1px;
    margin: 8px 0;
    text-transform: uppercase;
    font-weight: bold;
}

.split-hero__title {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font: 55px 'FFDIN', Arial, sans-serif;
    line-height: 1.1;
    margin: 10px;
    font-weight: bold;
}

@media only screen and (min-width: 1025px) and (max-width: 1199px) {
    .split-hero__title {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font: 48px 'FFDIN', Arial, sans-serif;
        font-weight: bold;
    }
}

@media (max-width: 1024px) {
    .split-hero__title {
        margin-block-end: 10px;
        font-weight: bold;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .split-hero__title {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font: 27px 'FFDIN', Arial, sans-serif;
        font-weight: bold;
    }
}

@media (max-width: 767px) {
    .split-hero__title {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font: 22px 'FFDIN', Arial, sans-serif;
        line-height: 1.1;
        font-weight: bold;
    }
}

.split-hero__text {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font: 20px 'FFDIN', Arial, sans-serif;
    line-height: 1.25;
    /* stylelint-disable-next-line declaration-no-important */
    margin: 10px 0 !important // _reset.scss override fix;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .split-hero__text {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font: 18px 'FFDIN', Arial, sans-serif;
    }
}

@media (max-width: 767px) {
    .split-hero__text {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font: 18px 'FFDIN', Arial, sans-serif;
    }
}

.split-hero__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (min-width: 1024px) {
    .split-hero__buttons {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
}

.split-hero__button {
    font-size: 14px;
    margin: 8px auto;
}

@media (max-width: 1024px) {
    .split-hero__button {
        margin-inline-start: 6px;
        margin-inline-end: 6px;
    }
}

@media (min-width: 1024px) {
    .split-hero__button {
        margin: 8px;
    }
}

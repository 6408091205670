/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * @author Vladislavs Zimnikovs <vladislavs.zimnikovs@scandiweb.com>
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package puma-mx
 */

.SizeChartPopup {
    &_isVisible {
        height: 100%;
    }

    .Tabs {
        &-InactiveTabList {
            position: fixed;
            inset-block-end: 0;
            inset-inline-start: 0;
            width: 100%;
            z-index: 1;
            background: var(--color-white);
            border-top: 1px solid var(--color-input-border);

            @include desktop {
                display: none;
            }

            .Tabs-TabButton_notActive {
                width: 48%;
                text-align: start;
                padding-inline-start: 2.4rem;

                @include isArabic {
                    padding-inline-end: 2.4rem;
                    padding-inline-start: 0;
                    text-align: end;
                }
            }
        }

        &-TabList {
            &[name='main-tabs-list'] {
                .Tabs-TabButton_notActive {
                    display: none;

                    @include desktop {
                        display: initial;
                    }
                }
            }
        }
    }

    &-KidsTabs,
    &-MenTabs,
    &-WomenTabs {
        .Tabs {
            &-TabList {
                border: none;
            }

            &-TabButton {
                padding: 2.1rem 2rem 10px 0;
            }
        }
    }

    p {
        color: var(--color-black);
        text-transform: uppercase;
        font-size: var(--font-size-pico);

        @include desktop {
            font-size: var(--font-size-small);
        }
    }

    table {
        width: 100%;

        tr {
            background: none;
        }

        td {
            padding-inline-end: 0;
            padding-inline-start: 0;
            text-align: center;
            font-size: var(--font-size-micro);

            @include desktop {
                min-width: 107px;
                font-size: var(--font-size-normal-small);
            }

            @include small-desktop {
                font-size: var(--font-size-normal);
            }
        }

        strong {
            font-weight: 800;
        }
    }

    &-Content .Table-Wrapper {
        overflow: hidden;

        td {
            &:first-child {
                font-weight: 700;
            }
        }
    }

    .Popup {
        &-Content {
            height: 100%;
            width: 100vw;
            max-height: unset;
            padding: 2.4rem 2.5rem 2.35rem;

            @include desktop {
                padding: 3.4rem 4.8rem 5.3rem;
                width: 69rem;
                margin: 0 40px;
                height: inherit;
                max-height: 80%;
            }

            @include small-desktop {
                padding: 3.4rem 4.8rem 5.3rem;
                width: 75rem;
                margin: 0;
                height: inherit;
            }
        }

        &-Header {
            button.Popup-CloseBtn {
                inset-block-start: 0;

                @include isArabic {
                    inset-inline-start: -1rem;
                }

                &::before, &::after {
                    inset-block-start: 0;
                }
            }
        }

        &-Heading {
            font-size: var(--font-size-medium);

            @include desktop {
                font-size: 24px;
            }

            @include small-desktop {
                font-size: 30px;
            }
        }
    }

    .ProductMeasurements-Table table {
        width: initial;
    }
}

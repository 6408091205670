/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

:root {
    --border-color-dark: rgba(238, 238, 238, .2);
    --border-color-light: rgba(255, 255, 255, .2);
    --border-color-light-hover: rgba(255, 255, 255, .15);
}

@mixin custom-column-ratio {
    padding-inline-start: 24px;
    padding-inline-end: 24px;

    @include after-mobile {
        display: grid;
        grid-template-columns: 6fr 4fr;
        gap: 101px;
        padding-inline-start: 30px;
        padding-inline-end: 30px;
    }

    @include large-desktop {
        grid-template-columns: 7fr 3fr;
        padding-inline-start: 40px;
        padding-inline-end: 40px;
        gap: 113px;
    }

    @include extra-large-desktop {
        gap: 150px;
    }
}

.Footer {
    width: 100%;
    background-color: var(--color-gray-nero);
    padding-block-start: 50px;
    min-height: 600px;

    @include mobile {
        min-height: 100vh;
    }

    @include desktop {
        z-index: 91;
    }

    &_isPDP {
        margin-block-end: calc(var(--add-to-cart-btn-height) + 2 * var(--add-to-cart-btn-padding));

        @include desktop {
            margin-block-end: 0;
        }
    }

    @include after-mobile {
        padding-block-start: 70px;
    }

    @include extra-large-desktop {
        padding-block-start: 90px;
    }

    &-Top {
        padding-block-end: 40px;
        max-width: 1920px;
        margin: 0 auto;

        @include custom-column-ratio();

        @include large-desktop {
            padding-block-end: 40px;
            /* stylelint-disable-next-line number-max-precision */
            grid-template-columns: 7.2fr 2.8fr;
        }

        @include extra-large-desktop {
            padding-block-end: 60px;
        }

        .Subscription {
            .Field {
                margin-block-end: 0;
            }
        }
    }

    .ChooseLangBlock {
        margin-block-end: 30px;

        @include after-mobile {
            margin-block-end: 0;
        }

        &-PopupBtn {
            display: flex;
            justify-content: space-between;
            width: 100%;
            transition: border .15s ease 0s;
            border: 1px solid var(--border-color-light);
            padding: 10px 10px 10px 20px;

            &:hover {
                border-color: var(--border-color-light-hover);
            }

            @include after-mobile {
                max-width: 260px;
            }

            @include isArabic {
                padding: 10px 20px  10px 10px;
            }
        }

        .Icon-Flag {
            height: 14px;
            inset-block-start: 5px;
            margin-inline-end: 15px;
        }

        .Icon-CustomFlag {
            width: 24px;
            height: 14px;
            margin-inline-end: 15px;
            inset-block-start: 5px;
        }

        &-FlagWrapper {
            display: flex;
            justify-content: space-between;
        }

        &-Prefix {
            text-transform: uppercase;
            color: var(--color-white);
            font-size: 13px;
            inset-block-start: 5px;
            font-weight: bold;
        }
    }

    .StoreSwitcher {
        display: none;
        grid-column: 1 / 2;
    }

    &-Copyright {
        color: var(--color-white);
        grid-column: 2 / 3;

        @include after-mobile {
            text-align: center;
        }
    }

    &-TermsAndConditions {
        padding: 7px 0;
        color: var(--color-gray-text);
        grid-column: 3 / 4;
        font-size: 14px;

        @include after-mobile {
            text-align: end;
            justify-self: right;
        }
    }

    &-Middle {
        max-width: 1920px;
        margin: 0 auto;

        @include custom-column-ratio();

        @include large-desktop {
            /* stylelint-disable-next-line number-max-precision */
            grid-template-columns: 7.2fr 2.8fr;
        }
    }

    .ExpandableContent {
        &:last-of-type {
            border-top: 1px solid var(--border-color-dark);
            border-bottom: 1px solid var(--border-color-dark);

            @include after-mobile {
                border-bottom-style: none;
            }
        }

        @include after-mobile {
            /* stylelint-disable-next-line declaration-no-important */
            border-top: 2px solid var(--color-white) !important; // Gonna leave important for now, since this affects only footer
        }

        &-Button {
            min-height: auto;
            padding: 8px 0;

            &::before {
                display: none;
            }

            &::after {
                content: '';
                width: 6px;
                height: 6px;
                border: 2px solid transparent;
                border-bottom-color: #{$white};
                border-right-color: #{$white};
                background-color: transparent;
                transform: rotate(-45deg);
                inset-inline-end: 10px;

                @include isArabic {
                    transform: rotate(135deg);
                }

                transition: transform .5s;
            }

            &_isContentExpanded {
                &::after {
                    transform: rotate(45deg);
                    transition: transform .5s;

                    @include isArabic {
                        transform: rotate(45deg);
                        transition: transform .5s;
                    }
                }
            }

            @include desktop {
                padding: 0;

                &::after {
                    content: none;
                }
            }
        }

        &-Heading {
            font-size: 14px;
            text-transform: capitalize;
            color: var(--color-white);

            @include after-mobile {
                inset-block-start: -40px;
                position: absolute;
            }
        }

        &-Content {
            opacity: 1;
            padding: 0;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(128px, 1fr));
            transition: max-height .3s ease-out, padding .3s ease-out;

            @include after-mobile {
                inset-block-start: -15px;
            }

            &_isContentExpanded {
                max-height: 500px;
                transition: max-height .3s ease-in;

                @include mobile {
                    padding-block-end: 15px;
                }
            }
        }
    }

    &-Navigation {
        border-top: 2px solid var(--color-white);
        padding-block-start: 20px;

        @include after-mobile {
            border-top-style: none;
            display: grid;
            gap: 8px;
            grid-template-columns: 1fr 1fr;
            padding-block-start: 0;
            padding-block-end: 70px;
        }

        @include small-desktop {
            padding-block-end: 90px;
        }

        .ExpandableContent-Button {
            @include after-mobile {
                @include isArabic {
                    text-align: end;
                }
            }
        }
    }

    &-Links {
        list-style: none;
        padding-inline-end: 15px;

        li {
            margin: 0;
            padding: 7px 0;

            @include after-mobile {
                padding: 3px 0;
            }

            &::before {
                display: none;
            }
        }
    }

    &-Logo {
        @include after-mobile {
            display: inline-block;
            height: 48px;
            width: 48px;
            margin: 10px;

            &::before {
                content: url('../../../public/assets/images/global/logo.svg');
            }
        }
    }

    &-External {
        padding: 40px 0;

        @include after-mobile {
            padding: 32px 0 0 0;
            border-top: 2px solid var(--color-white);
        }
    }

    &-SocialMedia {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 11px 0;

        @include small-desktop {
            margin: 16px 0;
        }

        @include after-mobile {
            justify-content: flex-start;
        }

        &:hover > .Footer-SocialMedium {
            opacity: .5;
            transition: opacity .15s ease-in-out;
        }

        &:hover > .Footer-SocialMedium:hover {
            opacity: 1;
            transition: opacity .15s ease-in-out;
        }
    }

    &-SocialMedium {
        height: 32px;
        margin: 0;
        margin-inline-start: 0;
        padding-inline-end: 23px;
        width: 55px;

        @include desktop {
            padding-inline-end: 16px;
            width: 48px;
        }

        @include small-desktop {
            padding-inline-end: 30px;
            width: 63px;
        }

        &:last-child {
            padding-inline-end: 0;
            width: 32px;

            @include isArabic {
                padding-inline-start: 0;
                padding-inline-end: 0;
            }
        }

        &_name {
            &_facebook {
                &::before {
                    content: url('../../../public/assets/images/icons/facebook-icon.svg');
                }
            }

            &_twitter {
                &::before {
                    content: url('../../../public/assets/images/icons/twitter-icon.svg');
                }
            }

            &_instagram {
                &::before {
                    content: url('../../../public/assets/images/icons/instagram-icon.svg');
                }
            }

            &_youtube {
                &::before {
                    content: url('../../../public/assets/images/icons/youtube-icon.svg');
                }
            }
        }
    }

    &-Featured {
        padding-block-start: 30px;
        margin-block-start: 30px;
        border-top: 1px solid var(--border-color-dark);

        @include after-mobile {
            margin-block-start: 95px;
        }
    }

    &-TracLogo {
        display: flex;
        align-items: center;

        &:focus,
        &:hover {
            text-decoration: none;
        }

        &::before {
            height: 60px;
            width: 60px;
            margin-inline-end: 30px;
            content: url('../../../public/assets/images/global/logo-with-text.svg');
        }

        span {
            font-size: 12px;
            text-transform: uppercase;
            color: var(--color-white);
            white-space: nowrap;

            @include animated-bottom-border(var(--color-brown-mongoose-light), var(--color-white));
        }
    }

    &-Bottom {
        border-top: 1px solid var(--border-color-dark);

        &Container {
            padding: 30px 24px;
            max-width: 1920px;
            margin: 0 auto;

            @include after-mobile {
                display: grid;
                /* stylelint-disable-next-line number-max-precision */
                grid-template-columns: 1.4fr 1fr 1fr;
                justify-content: space-between;
                align-items: center;
                padding: 10px;
                min-height: 88px;
            }

            @include small-desktop {
                padding-inline-start: 40px;
                padding-inline-end: 40px;
            }
        }
    }

    &-PlaceholderFooterContainer {
        background-color: var(--color-gray-nero);
    }

    &-PlaceholderFooter {
        padding: 50px 24px 30px 24px;
        background-color: var(--color-gray-nero);
        max-width: 1920px;
        margin: 0 auto;

        span {
            @include placeholder-animation;

            opacity: .4;
            display: block;
        }
    }

    &-PlaceholderSubscription {
        height: 75px;
        margin-block-end: 50px;
    }

    &-PlaceholderExpandableContent {
        height: 18px;
        margin: 8px 0;

        &:nth-last-child(-n+2) {
            display: none;
        }
    }

    &-PlaceholderFeatured {
        height: 60px;
        width: 231px;
        margin-block-end: 80px;
    }

    &-PlaceholderLanguage {
        height: 46px;
        margin-block-end: 50px;
        width: 100%;
    }

    &-PlaceholderCopyright {
        height: 22px;
        margin: 4px;
        width: 218px;
    }

    &-PlaceholderTAC {
        height: 22px;
        margin: 4px;
        width: 122px;
    }

    &-PlaceholderIcons {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 40px 0 50px;
    }

    &-PlaceholderIcon {
        width: 32px;
        height: 32px;
        margin: 11px;
    }

    @include after-mobile {
        &-PlaceholderLogo {
            width: 68px;
            height: 68px;
        }

        &-PlaceholderExpandableContent {
            height: 100%;
            margin: 0;
            max-width: 140px;

            &:nth-last-child(-n+2) {
                display: block;
            }
        }

        &-PlaceholderTop, &-PlaceholderMiddle {
            grid-template-columns: 6fr 4fr;
            gap: 101px;
            display: grid;
        }

        &-PlaceholderLanguage {
            width: 257px;
            margin: 0;
        }

        &-PlaceholderBottom {
            display: flex;
            margin: 21px 0;
            justify-content: space-between;
            align-items: center;
        }

        &-PlaceholderNavigation {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 8px;
        }

        &-PlaceholderFeatured {
            margin-block-start: 80px;
            margin-block-end: 0px;
        }

        &-PlaceholderIcons {
            justify-content: left;
        }
    }
}

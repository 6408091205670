/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.KeyValueTable {
    margin: 0 0 1.5rem 0;
    min-width: 100%;
    font: 13px var(--font-FFDIN);
    line-height: 1.5;
    letter-spacing: 0px;

    @include isArabic {
        font: 13px var(--font-DINArabic);
    }

    &-Heading {
        font-size: 14px;
    }

    &-Item {
        &-Name {
            border-top: 1px solid rgba(10, 10, 10, .5);
        }
    }

    &-Totals {
        &-Separated {
            border-top: 1px solid rgba(10, 10, 10, .5);
        }
    }

    &-Wrapper {
        border-bottom: 1px solid var(--color-input-border);

        @include mobile {
            max-width: calc(100vw - 2rem);
            border: none;
        }
    }

    .TableEntry {
        &-telephone {
            text-align: start;

            @include isArabic {
                text-align: end;
            }
        }
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

@mixin title-style-mobile {
    font-size: 1.625rem;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    margin: 1.25rem .9375rem .625rem;
}

.affiliate {
    &-block {
        display: block;
        text-align: center;

        &-content {
            @include desktop {
                inset-block-end: 0;
                inset-inline-start: 0;
                padding: 5% 3%;
                position: absolute;
                inset-inline-end: 50%;
                text-align: start;
                inset-block-start: 0;
            }
        }

        .Image {
            padding-block-end: 50%;

            img {
                object-fit: cover;
            }
        }
    }

    .btn {
        padding: unset;
        line-height: 45px;

        &.affiliate-banner__link {
            @include desktop {
                line-height: 40px;
            }
        }
    }

    &-banner {
        &__subtitle, &__title {
            text-transform: uppercase;

            @include desktop {
                color: var(--color-white);
            }
        }

        &__subtitle {
            font-size: 1.25rem;
            font-weight: 400;

            @include desktop {
                font-size: 2vw;
                line-height: 2vw;
                margin: 2% 0 5%;
            }
        }

        &__title {
            font-size: 1.8rem;
            font-weight: bold;

            @include desktop {
                font-size: 4vw;
                line-height: 4vw;
            }
        }

        &__text {
            @include desktop {
                font-size: 2vw;
                color: var(--color-white)
            }
        }

        &__link {
            width: 120px;
            height: 45px;
            /* stylelint-disable-next-line declaration-no-important */
            text-decoration: none!important;
            line-height: 18px;
            margin-block-start: 20px;

            @include desktop {
                background-color: var(--color-white);
                color: var(--color-black);
                height: 40px;
                width: 130px;
                line-height: 13px;
            }
        }
    }

    &-description {
        &__title {
            @include title-style-mobile;

            @include desktop {
                font-size: 2vw;
            }
        }

        &__text {
            font-size: 2vw;
        }
    }

    &-benefit {
        .Image {
            padding-block-end: 75%;
            margin: 10px 0;

            img {
                object-fit: cover;
            }
        }

        &__title {
            background-color: $black;
            color: $white;
            font-size: 1.75rem;
            font-weight: bold;
            text-transform: uppercase;
            padding: 2% 4%;
            margin: 20px 0;
            line-height: 1.2;

            @include desktop {
                font-size: 2vw;
            }

            &-text {
                inset-block-start: 3px;

                @include  desktop {
                    font-size: 3vw;
                }
            }

            &-desc {
                font-size: 1rem;
                margin-block-start: 2.5rem;
                text-transform: none;

                @include desktop {
                    font-size: 2vw;
                }
            }
        }

        &__item {
            @include desktop {
                display: flex;
                max-height: 30vw;
            }

            &_structure, &_offers, &_cookie {
                .Image {
                    order: 1
                }
            }

            .Image {
                @include desktop {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 50%;
                    padding-block-end: 30vw;

                    img {
                        object-fit: cover;
                    }
                }
            }

            &-content {
                @include desktop {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 50%;
                }
            }

            &-title {
                @include title-style-mobile;

                @include desktop {
                    font-size: 2vw;
                    text-align: start;
                    margin: 0 7% 3%;
                }
            }

            &-desc {
                @include desktop {
                    margin: 0 7% 3%;
                    font-size: 2vw;
                }
            }

            &-btn {
                /* stylelint-disable-next-line declaration-no-important */
                text-align: center!important;
                margin-block-start: 20px;

                a {
                    width: 120px;
                    height: 45px;
                    /* stylelint-disable-next-line declaration-no-important */
                    text-decoration: none!important;
                    line-height: 18px;
                }
            }
        }
    }

    &-tools {
        text-align: center;

        &__list {
            @include desktop {
                display: flex;
            }
        }

        &__title {
            font-size: 1.625rem;
            font-weight: 700;
            text-align: center;
            text-transform: uppercase;

            @include desktop {
                text-align: start;
                font-size: 2vw;
            }
        }

        &__item {
            @include desktop {
                padding: 5% 0;
                width: 33.3%;
            }

            &_banners, &_feeds {
                @include desktop {
                    margin-inline-start: 40px;
                }
            }

            &-title {
                font-size: 1.25rem;
                margin: 2.1875rem 0;
                text-transform: uppercase;
                font-weight: 700;

                @include desktop {
                    font-size: 2vw;
                }
            }

            &-desc {
                @include desktop {
                    font-size: 2vw;
                    text-align: start;
                }
            }

            &-img {
                max-width: 3.75rem;
                vertical-align: middle;
                background-size: contain;
                background-repeat: no-repeat;
                height: 50px;
                margin: 30px auto 0;

                @include desktop {
                    max-width: 6vw;
                    height: 6vw;
                }

                &__links {
                    background-image: url('affiliate-images/tools1.svg');
                }

                &__banners {
                    background-image: url('affiliate-images/tools2.svg');
                }

                &__feeds {
                    background-image: url('affiliate-images/tools3.svg');
                }
            }
        }
    }

    &-terms {
        &__title {
            font-size: 1.625rem;
            font-weight: 700;
            text-align: center;
            text-transform: uppercase;
            margin: 40px 0 20px;

            @include desktop {
                text-align: start;
                font-size: 2vw;
            }
        }

        &__desc {
            @include desktop {
                font-size: 2vw;
            }
        }

        &__ask {
            &-title {
                font-size: 1.625rem;
                font-weight: 700;
                text-align: center;
                text-transform: uppercase;
                margin: 40px 0 20px;

                @include desktop {
                    text-align: start;
                    font-size: 2vw;
                }
            }

            &-desc {
                text-transform: none;
                display: none;
                margin-block-end: 20px;

                @include desktop {
                    font-size: 2vw;
                }

                &_open {
                    display: block;
                }
            }

            &-item {
                border-top: 1px solid;

                &:last-child {
                    border-bottom: 1px solid;
                }

                &-title {
                    cursor: pointer;
                    font-size: 1.25rem;
                    padding: .9375rem 25px .9375rem 0;
                    text-transform: uppercase;
                    font-weight: 700;

                    @include desktop {
                        font-size: 2vw;
                        padding: 1vw 7vw 1vw 0;
                    }

                    &::after {
                        content: '\002B';
                        font-size: 2.75rem;
                        font-weight: 400;
                        position: absolute;
                        inset-inline-end: 0;
                        inset-block-start: -.3125rem;

                        @include desktop {
                            font-size: 4vw;
                        }
                    }

                    &_open {
                        &::after {
                            content: '\2014';
                            font-size: 2rem;
                            font-weight: 700;
                            line-height: 1;
                            inset-inline-end: 0;
                            inset-block-start: .875rem;

                            @include desktop {
                                font-size: 2vw;
                                inset-block-start: 2rem;
                            }
                        }
                    }
                }
            }
        }
    }

    &-contact {
        &__title {
            font-size: 1.625rem;
            font-weight: 700;
            text-align: center;
            text-transform: uppercase;
            margin: 20px 0;

            @include desktop {
                font-size: 2vw;
                text-align: start;
            }
        }

        &__desc {
            @include desktop {
                font-size: 2vw;
            }
        }

        &__btn {
            margin-block-start: 20px;

            a {
                width: 120px;
                height: 45px;
                /* stylelint-disable-next-line declaration-no-important */
                text-decoration: none!important;
                line-height: 18px;
            }
        }
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

.FullBleedHeroCarouselItem {
    max-height: 1536px;

    @include after-mobile {
        height: 500px;
        max-height: 500px;
    }

    .FullBleedHeroCarouselItem {
        &-Content {
            overflow: hidden;
            position: absolute;
            inset-block-start: 0;
            inset-block-end: 0;
            inset-inline-start: 0;
            inset-inline-end: 0;
            padding: 32px 96px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            pointer-events: none;

            @include tablet {
                padding-inline-start: 50px;
                padding-inline-end: 50px;
            }

            @include mobile {
                text-align: center;
                padding: 24px;
            }
        }

        &-Title,
        &-Text {
            text-align: center;
            margin-block-start: 0;
            user-select: none;
        }

        &-Title {
            font-size: 55px;
            line-height: 1.1;
            width: 90%;
            margin-block-end: 10px;
            margin-inline-start: auto;
            margin-inline-end: auto;
            font-weight: bold;

            @include tablet {
                font-size: 48px;
            }

            @include mobile {
                font-size: 24px;
            }
        }

        &-Text {
            font-size: 20px;
            margin-block-end: 0;

            @include small-desktop-down {
                font-size: 18px;
            }
        }
    }
}

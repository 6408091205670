/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

.FullBleedHeroCarousel {
    &-Header {
        margin-block-start: 23px;
        margin-block-end: 30px;

        @include mobile {
            padding: 0 23px;
        }
    }

    &-Title {
        font-size: 30px;
        margin: 0 0 10px;
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;

        @include tablet {
            font-size: 27px;
        }

        @include mobile {
            font-size: 22px;
        }
    }

    &-Text {
        font-size: 15px;
        margin: 10px 0;
        text-align: center;

        @include tablet {
            font-size: 18px;
        }

        @include mobile {
            font-size: 18px;
        }
    }

    &-FullBleedHeroCarouselItem {
        display: flex;
        justify-content: center;
        align-self: baseline;
        min-width: var(--slide-width);
    }

    &-BackButton {
        position: absolute;
        inset-inline-start: 0px;
        inset-block-start: 50%;
        transform: translateY(-50%);
        padding: 12px;
        height: 100%;
        width: 4rem;
        z-index: 10;
        opacity: 0;
        transition: all 0.25s ease-in-out;

        &:hover {
            opacity: 1;
        }

        &::after {
            content: '';
            inset-inline-start: 20px;
            position: absolute;
            border-width: 0 3px 3px 0;
            display: inline-block;
            padding: 12px;
            inset-block-start: 50%;
            transform: translateY(-50%) rotate(135deg);
        }
    }

    &-NextButton {
        position: absolute;
        inset-inline-end: 0px;
        inset-block-start: 50%;
        transform: translateY(-50%);
        padding: 12px;
        height: 100%;
        width: 4rem;
        z-index: 10;
        opacity: 0;
        transition: all 0.25s ease-in-out;

        &:hover {
            opacity: 1;
        }

        &::after {
            content: '';
            inset-inline-end: 20px;
            position: absolute;
            border-width: 0 3px 3px 0;
            display: inline-block;
            padding: 12px;
            inset-block-start: 50%;
            transform: translateY(-50%) rotate(-45deg);
        }
    }
}

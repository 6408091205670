/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

.full-bleed-hero {
    position: relative;
    z-index: auto;
    width: 100%;
    direction: ltr;

    picture {
        line-height: 0;
        font-size: 0;
    }

    .Image {
        padding-block-end: 0;
        // Minimal banner size on desktop and mobile.
        // if not set properly Page load score will suffer and users will see elements of the page
        // jumping around during the load. For best result there should be separate min-height
        // setting for each banner height available at each set of meaningful screen breakpoints
        min-height: 250px;
        height: auto;
        line-height: 0;
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        animation: var(--placeholder-animation);
    }

    &__button {
        pointer-events: auto;

        @include mobile {
            min-width: unset;
        }
    }

    * {
        position: static;
    }
}

.full-bleed-hero .counter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-block-end: 20px;
}

.full-bleed-hero .counter__part {
    text-align: center;
}

.full-bleed-hero .counter__value {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font: 72px 'FFDIN', Arial, sans-serif;
    display: block;
    line-height: 1;
}

@media (max-width: 767px) {
    .full-bleed-hero .counter__value {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font: 45px 'FFDIN', Arial, sans-serif;
        line-height: 1;
    }
}

.full-bleed-hero .counter__label {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font: 16px 'FFDIN', Arial, sans-serif;
    display: block;
}

.full-bleed-hero .counter__separator {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font: 70px 'FFDIN', Arial, sans-serif;
    line-height: 1;
    margin: 0 10px;
}

@media (max-width: 767px) {
    .full-bleed-hero .counter__separator {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font: 44px 'FFDIN', Arial, sans-serif;
        line-height: 1;
    }
}

.full-bleed-hero__buttons {
    display: flex;
    flex-direction: row;
    margin-block-start: 20px;
}

@media only screen and (min-width: 1025px) {
    .full-bleed-hero__buttons {
        margin-inline-start: -10px;
        margin-inline-end: -10px;
    }
}

@media (max-width: 1024px) {
    .full-bleed-hero__buttons {
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
}

@media only screen and (min-width: 1025px) {
    .full-bleed-hero__button-w {
        padding: 0 10px;
    }
}

@media (max-width: 1024px) {
    .full-bleed-hero__button {
        margin-inline-start: 6px;
        margin-inline-end: 6px;
    }
}

@media (max-width: 767px) {
    .full-bleed-hero__button {
        margin-block-end: 16px;
    }
}

@media (max-width: 1024px) {
    .full-bleed-hero__media {
        position: relative;
    }
}

.full-bleed-hero__link {
    display: block;
}

.full-bleed-hero__image {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover;

    @include desktop {
        display: none;
    }

    &_desktop {
        display: none;

        @include small-desktop {
            display: block;
        }
    }

    &_tablet {
        display: none;

        @include desktop {
            display: block;
        }

        @include small-desktop {
            display: none;
        }
    }
}

.full-bleed-hero__content-w {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    pointer-events: none;
}

@media (max-width: 1024px) {
    .full-bleed-hero__content-w {
        text-align: center;
    }
}

@media (max-width: 767px) {
    .full-bleed-hero__content-w {
        padding: 24px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .full-bleed-hero__content-w {
        padding: 35px 50px;
    }
}

@media only screen and (min-width: 1025px) {
    .full-bleed-hero__content-w {
        position: absolute;
        inset-block-start: 0;
        inset-block-end: 0;
        inset-inline-start: 0;
        inset-inline-end: 0;
        padding: 32px 96px;
    }

    .full-bleed-hero__content-w_horizontal-center .full-bleed-hero__content {
        text-align: center;
    }

    .full-bleed-hero__content-w_horizontal-center .full-bleed-hero__buttons {
        justify-content: center;
    }

    .full-bleed-hero__content-w_horizontal-left {
        align-items: flex-start;
    }

    .full-bleed-hero__content-w_horizontal-left .full-bleed-hero__content {
        text-align: start;
    }

    .full-bleed-hero__content-w_horizontal-left .full-bleed-hero__buttons {
        justify-content: flex-start;

        @include isArabic {
            justify-content: flex-end;
        }
    }

    .full-bleed-hero__content-w_horizontal-left .counter {
        justify-content: flex-start;
    }

    .full-bleed-hero__content-w_horizontal-right {
        align-items: flex-end;
    }

    .full-bleed-hero__content-w_horizontal-right .full-bleed-hero__content {
        text-align: end;
    }

    .full-bleed-hero__content-w_horizontal-right .full-bleed-hero__buttons {
        justify-content: flex-end;
    }

    .full-bleed-hero__content-w_horizontal-right .counter {
        justify-content: flex-end;
    }

    .full-bleed-hero__content-w_vertical-top {
        justify-content: flex-start;
    }

    .full-bleed-hero__content-w_vertical-middle {
        justify-content: center;
    }

    .full-bleed-hero__content-w_vertical-bottom {
        justify-content: flex-end;
    }
}

.full-bleed-hero__title {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font: 55px 'FFDIN', Arial, sans-serif;
    line-height: 1.1;
    margin-block-end: 10px;
    font-weight: bold;
}

@media (max-width: 1024px) {
    .full-bleed-hero__title {
        margin-block-end: 14px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .full-bleed-hero__title {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font: 27px 'FFDIN', Arial, sans-serif;
    }
}

@media (max-width: 767px) {
    .full-bleed-hero__title {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font: 22px 'FFDIN', Arial, sans-serif;
        line-height: 1.1;
    }
}

.full-bleed-hero__text {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font: 20px 'FFDIN', Arial, sans-serif;
    font-weight: bold;
}

@media (max-width: 1024px) {
    .full-bleed-hero__text {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font: 18px 'FFDIN', Arial, sans-serif;
        margin-block-end: 16px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .full-bleed-hero__text {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font: 18px 'FFDIN', Arial, sans-serif;
    }
}

@media (max-width: 767px) {
    .full-bleed-hero__text {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font: 18px 'FFDIN', Arial, sans-serif;
    }
}

@media (max-width: 1024px) {
    .full-bleed-hero__menu {
        display: none;
    }
}

@media only screen and (min-width: 1025px) {
    .full-bleed-hero__menu {
        position: absolute;
        pointer-events: auto;
        background-color: #f2f2f2;
        text-transform: uppercase;
        min-width: 250px;
        user-select: none;
        inset-inline-start: 100px;
        max-height: 85%;
        overflow-y: auto;

        @include isArabic {
            inset-inline-end: 100px;
            inset-inline-start: unset;
        }

        &-category {
            padding-inline-start: 0;

            a {
                text-decoration: none;
            }

            &::before {
                content: none;
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .full-bleed-hero__menu {
        inset-inline-start: 20px;

        @include isArabic {
            inset-inline-end: 20px;
            inset-inline-start: unset;
        }
    }
}

@media only screen and (min-width: 1025px) {
    .full-bleed-hero__menu-title {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font: 16px 'FFDIN', Arial, sans-serif;
        padding: 20px 20px 15px 25px;
        display: block;
        font-weight: bold;
        font-size: 20px;

        @include isArabic {
            direction: rtl;
        }
    }

    .full-bleed-hero__menu-categories {
        font: 16px 'FFDIN', Arial, sans-serif;

        @include isArabic {
            direction: rtl;
        }
    }

    .full-bleed-hero__menu-category-link {
        display: block;
        padding: 0 25px;
    }

    .full-bleed-hero__menu-category-link:hover {
        color: #ff2525;
    }

    .full-bleed-hero__menu-category-name {
        display: block;
        border-bottom: 1px solid #d1d1d1;
        padding: 10px 0;
    }

    .full-bleed-hero__menu-category:last-child .full-bleed-hero__menu-category-name {
        border-bottom: none;
    }

    .full-bleed-hero__menu-category:first-child .full-bleed-hero__menu-category-name {
        border-top: 1px solid #d1d1d1;
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.feature-hero {
    position: relative;

    &__main-img,
    &__secondary-img {
        height: 100%;
    }

    &__main-media,
    &__secondary-media {
        .Image {
            padding-block-end: 0;
            display: block;

            &-Image {
                height: 100%;
                position: relative;
            }
        }
    }

    &__main-img {
        @include content-type-image-size-adaption-animation(.4, 400, 400);

        width: 100%;

        .Image {
            &-Image {
                &_isLoading {
                    @include desktop {
                        min-height: 700px;
                        max-height: 700px;
                    }
                }
            }
        }
    }

    &__secondary-img {
        @include content-type-image-size-adaption-animation(.4, 200, 200);

        width: 100%;

        .Image {
            &-Image {
                &_isLoading {
                    @include desktop {
                        min-height: 350px;
                        max-height: 350px;
                    }
                }
            }
        }
    }

    &__title {
        margin-block-end: 15px;
    }

    & &__title-text {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 22px;
        font-weight: bold;
        letter-spacing: .12rem;
        text-transform: uppercase;
        color: #222;
        margin: 0 0 10px 0;
        text-align: center;
        line-height: 1;

        @include desktop {
            font-size: 27px;
            letter-spacing: 0;
        }

        @include small-desktop {
            font-size: 55px;
            letter-spacing: .05rem;
            text-align: unset;
        }
    }

    &__buttons {
        margin-block-start: 0;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include small-desktop {
            display: block;
        }
    }

    &__button {
        padding: 13px 16px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 14px;
        font-weight: bold;
        display: block;
        text-transform: uppercase;
        margin-block-end: 15px;

        @include small-desktop {
            font-size: 15px;
        }
    }

    &__content {
        padding: 0 24px 24px 40px;

        @include large-desktop {
            padding: 48px 24px 24px 24px;
        }
    }

    &__text {
        margin-block-end: 15px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font: 18px 'FFDIN Medium', 'FFDIN', Arial, sans-serif;
        font: 1.75rem 'FFDIN Medium', 'FFDIN', Arial, sans-serif;
        color: #444
    }

    &__text-optional {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 11px;
        font-style: italic;
        text-transform: capitalize;
        margin-block-end: 8px;
        color: #666;
        text-align: center;

        @include desktop {
            font-size: 10px;
        }

        @include small-desktop {
            text-align: unset;
            font-size: 12px;
        }
    }

    &__link {
        margin: 0;

        &:hover {
            text-decoration: none;
        }
    }

    &__main-media-button {
        inset-block-start: 50%;
        inset-inline-start: 50%;
        transform: translateX(-50%) translateY(-50%);
        position: absolute;
        display: block;
        opacity: 0;
        transition: opacity .2s linear;
    }

    &__main-media-link {
        display: block;
        cursor: pointer;

        &:hover {
            .feature-hero__main-media-button {
                @include small-desktop {
                    opacity: 1;
                }
            }
        }
    }
}

.feature-hero__background {
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;
    background-color: #fff;
    width: 100%;
    height: 500px;
}

.feature-hero__inner {
    max-width: 118rem;
    margin: 0 auto;
    position: relative;
    display: flex;
    padding-block-start: 4.5rem;
    padding-block-end: 3rem
}

@media only screen and (min-width: 1025px) {
    .feature-hero__inner {
        flex-flow: row wrap
    }
}

@media (max-width: 1024px) {
    .feature-hero__inner {
        flex-direction: column;
        padding-block-start: 0
    }
}

@media only screen and (min-width: 1025px) {
    .feature-hero__content {
        padding-inline-end: 2rem
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1180px) {
    .feature-hero__content {
        padding-inline-start: 2.5rem
    }
}

@media (max-width: 1024px) {
    .feature-hero__content {
        background-color: #fff;
        order: 2;
        overflow: auto;
        padding-block-start: 2.5rem;
        padding-block-end: 1.5rem
    }
}

.feature-hero__logo-wrapper {
    margin-block-end: 1.8rem
}

@media (max-width: 1024px) {
    .feature-hero__logo-wrapper {
        margin: 0 auto 1.5rem
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .feature-hero__logo-wrapper {
        margin-block-end: 1.7rem
    }
}

@media (max-width: 1024px) {
    .feature-hero__logo-img {
        margin-inline-start: auto;
        margin-inline-end: auto
    }
}

@media (max-width: 1024px) {
    .feature-hero__text {
        text-align: center
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .feature-hero__text {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font: 18px 'FFDIN Medium', 'FFDIN', Arial, sans-serif;
        font: 1.8rem 'FFDIN Medium', 'FFDIN', Arial, sans-serif;
        line-height: 2.25rem
    }
}

@media (max-width: 767px) {
    .feature-hero__text {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font: 18px 'FFDIN Medium', 'FFDIN', Arial, sans-serif;
        font: 1.76rem 'FFDIN Medium', 'FFDIN', Arial, sans-serif;
        line-height: 2.2rem
    }
}

@media only screen and (min-width: 1025px) {
    .feature-hero__wrapper-left {
        flex-basis: 42.4%;
        order: 1
    }
}

@media (max-width: 1024px) {
    .feature-hero__wrapper-left {
        order: 4;
        width: 100%;
        height: auto
    }
}

.feature-hero__secondary-media {
    width: 100%
}

@media only screen and (min-width: 1025px) {
    .feature-hero__secondary-media {
        max-width: 58rem;
        max-height: 32.7rem
    }
}

.feature-hero__secondary-img-wrapper {
    width: 100%;
    height: 100%
}

.feature-hero__video, .feature-hero__img {
    position: relative;
    width: 116%
}

@media (max-width: 1024px) {
    .feature-hero__video, .feature-hero__img {
        width: 100%
    }
}

.feature-hero__video .container-video {
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;
    z-index: 1;
    width: 100%;
    height: 100%
}

.feature-hero__video .inline-video {
    display: block;
    width: 100%;
    height: 100%
}

.feature-hero__media {
    position: relative;
    z-index: 1;
    overflow: hidden
}

.feature-hero__video-img {
    width: 100%
}

@media only screen and (min-width: 1025px) {
    .feature-hero__video-img {
        position: relative;
        max-width: none
    }
}

.feature-hero__video-img_youtube {
    margin-block-start: -9.313%;
    margin-block-end: -9.313%
}

.feature-hero__icon-wrapper {
    inset-block-start: 50%;
    inset-inline-start: 50%;
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    z-index: 2;
    cursor: pointer
}

.feature-hero .icon-play {
    width: 8rem;
    height: 8rem;
    fill: #fff
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .feature-hero .icon-play {
        width: 6.5rem;
        height: 6.5rem
    }
}

@media (max-width: 1024px) {
    .feature-hero .icon-play {
        width: 5rem;
        height: 5rem
    }
}

@media only screen and (min-width: 1025px) {
    .feature-hero__main-media {
        flex-basis: 57.6%;
        order: 2;
        max-width: 68rem;
        max-height: 77.5rem
    }
}

@media (max-width: 1024px) {
    .feature-hero__main-media {
        order: 1;
        width: 100%;
        height: auto;
        overflow: auto
    }
}

@media (max-width: 1024px) {
    .feature-hero__main-media-button {
        display: block
    }
}

.feature-hero__main-img-wrapper {
    position: relative;
    width: 100%;
    height: 100%
}

.feature-hero img {
    display: block
}

.feature-hero .product-carousel {
    max-width: 116rem;
    margin-block-start: 5rem;
    margin-inline-end: 0;
    margin-inline-start: 0;
    min-width: 100%;
    width: 0
}

@media only screen and (min-width: 1025px) {
    .feature-hero .product-carousel {
        order: 4
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1199px) {
    .feature-hero .product-carousel {
        margin-inline-end: auto;
        margin-inline-start: auto
    }
}

@media (max-width: 1024px) {
    .feature-hero .product-carousel {
        margin-block-start: 1rem;
        order: 5
    }
}

.feature-hero .product-carousel__title {
    display: none
}

.feature-hero .product-carousel__products {
    max-width: 116rem
}

.feature-hero .product-carousel .slick-arrow {
    inset-block-start: 50%
}

@mixin menu-scrollbar {
    &::-webkit-scrollbar {
        height: 5px;
    }

    &::-webkit-scrollbar-track { /* Track */
        background-color: transparent;

        &:hover {
            background-color: transparent;
        }
    }

    &::-webkit-scrollbar-thumb { /* Handle */
        border: 0;
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

:root {
    --expandable-content-color: var(--color-black);
    --expandable-content-divider-color: var(--primary-divider-color);
    --popup-background-color: var(--color-grey-e);
    --mobile-langpopup-top: calc(var(--navigation-tabs-height) + var(--visible-global-banner-part));
    --mobile-langpopup-max-height: calc(100vh - var(--mobile-langpopup-top));
    --navigation-tabs-height-large: 73px;
}

@mixin expanded-content {
    padding: 1rem;
    opacity: 1;
    max-height: 100%;
}

.Popup_block_ChooseLangBlock {
    width: 100%;
    z-index: 100;
    inset-block-start: calc(var(--navigation-tabs-height) + var(--visible-global-banner-part));
    max-height: calc(100vh - var(--navigation-tabs-height) - var(--visible-global-banner-part));
    height: 100%;

    @include extra-large-desktop {
        inset-block-start: calc(var(--navigation-tabs-height-large) + var(--visible-global-banner-part));
        max-height: calc(100vh - var(--navigation-tabs-height-large) - var(--visible-global-banner-part));
    }

    .Popup-Content {
        background-color: var(--popup-background-color);
        padding: 24px 30px;
        max-width: unset;
        max-height: unset;
        width: 100%;
        height: 100%;
    }

    .ExpandableContent {
        border-top: 1px solid var(--expandable-content-divider-color);
        margin: 3px 0;

        &:first-of-type {
            border-top: 0;
        }

        &:last-of-type {
            border-bottom: 1px solid var(--expandable-content-divider-color);
        }

        &-Button {
            text-align: start;
            width: 100%;
            will-change: border-color;
            min-height: var(--header-nav-height);
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: .5rem 1rem;
        }

        &-Heading,
        &-SubHeading {
            display: block;
            color: var(--expandable-content-color);
        }

        &-Heading {
            line-height: 1.4;
            word-break: break-all;
            font-family: var(--font-FFDIN);
            font-size: var(--font-size-micro);
            text-transform: uppercase;

            @include isArabic {
                font-family: var(--font-DINArabic);
            }

            &:hover {
                color: var(--color-red);
            }

            @include after-mobile() {
                font-size: var(--font-size-normal);
            }

            @include desktop {
                cursor: auto;
                font-size: 15px;
                font-weight: bold;
                text-transform: none;
            }
        }

        &-SubHeading {
            font-weight: 300;
            font-size: .8rem;
            line-height: 1.1;
        }

        &-Content {
            padding: 0 1rem;
            max-height: 0;
            overflow: hidden;
            opacity: 0;

            @include desktop {
                margin-block-start: 0;
            }

            &_isContentExpanded {
                @include expanded-content;
            }
        }
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

:root {
    --menu-font-color: var(--color-white);
    --menu-mobile-font-size: 24px;
    --menu-mobile-item-padding: 9px 0;
    --menu-mobile-subitem-padding: 8px 0;
    --menu-submenu-heading-color: var(--color-grey-f6);
    --menu-mobile-subitem-font-size: 20px;
    --menu-explore-more-underline: var(--color-grey-c);
    --menu-explore-more-font-size: 12px;
    --menu-mobile-item-border: var(--color-grey-e);
    --menu-customgroup-title-font: 14px;
    --window-inner-height: 100%;
}

.Menu {
    &-MenuWrapperMobile {
        height: var(--window-inner-height);

        .Menu {
            &-MenuWrapper {
                padding: 0;
                margin: 0;
            }

            &-MainCategories {
                padding-inline-start: 0;
                padding-inline-end: 0;
                width: 100%;
                margin: 0;
                z-index: 1;
                overflow-y: auto;
                overflow-x: hidden;
                display: block;
                height: var(--window-inner-height);
                pointer-events: auto;

                @include isArabic {
                    padding-inline-start: 0;
                    padding-inline-end: 0;
                }

                ul {
                    width: 85%;
                    margin: 0 auto;

                    li {
                        padding: 0;

                        &:first-child {
                            margin-block-start: 25px;
                        }
                    }
                }

                >ul {
                    display: block;
                }

                &_isHidden {
                    .Menu-QuickLinksWrapper {
                        opacity: 0;
                        transition: all .5s ease .3s;
                    }
                }
            }

            &-QuickLinksWrapper {
                position: relative;
                width: 100vw;
                margin-inline-start: calc(-50vw + 50%);

                @include isArabic {
                    margin-inline-end: calc(-50vw + 50%);
                }

                background: var(--color-links-hover);
                margin-block-start: 50px;

                & > div {
                    margin: 0 auto;
                }
            }

            &-Item {
                color: var(--menu-font-color);
                margin: 0;
                height: unset;

                &::before {
                    content: none;
                }

                &:not(:first-of-type) {
                    margin-inline-start: 0;
                }

                @include desktop {
                    padding: 0;
                }

                >.Menu-Link {
                    >.Menu-ItemName_type_main {
                        border-bottom: 1px solid var(--menu-mobile-item-border);
                    }
                }

                &_isHighlighted {
                    >.Menu-Link,
                    >.Menu-SubCatLink {
                        >.Menu-ItemName {
                            color: var(--color-red);

                            &:hover {
                                color: var(--color-red);
                            }
                        }
                    }
                }
            }

            &-ItemName {
                font-size: var(--menu-mobile-font-size);
                padding: var(--menu-mobile-item-padding);
                color: var(--color-gray-nero);
                text-transform: capitalize;
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid var(--menu-mobile-item-border);

                &_isHighlighted {
                    color: var(--color-red-cardinal);
                }

                .Icon-Right {
                    inset-block-start: 2px;

                    @include isArabic {
                        transform: rotate(180deg);
                    }
                }
            }

            &-SubMenu {
                inset-block-start: 0;
                inset-inline-end: -150vw;
                transition: all .5s ease;
                position: absolute;
                height: 100vh;
                background: var(--color-white);
                opacity: 1;
                z-index: 2;

                @include before-desktop {
                    inset-inline-start: unset;
                    position: absolute;
                }

                &_isVisible {
                    inset-inline-end: 0;
                    width: 100%;
                    height: var(--window-inner-height);

                    @include mobile {
                        overflow-y: auto;
                        overflow-x: hidden;
                        padding-inline: 0;
                    }
                }

                .Icon-Left {
                    position: absolute;
                    inset-block-start: 50%;
                    transform: translateY(-50%);
                    inset-inline-start: 35px;

                    @include isArabic {
                        transform: rotate(180deg) translateY(50%);
                        inset-inline-end: 20px;
                    }
                }

                @include after-mobile {
                    background: var(--color-white);
                }

                .Menu {
                    &-ItemList {
                        padding: 55px 90px 0;
                        column-count: unset;

                        &_isPromotional,
                        &_isCustomGroup {
                            padding: 0 90px;
                        }

                        .Menu-SubMenuHeading {
                            inset-block-end: 7px;
                            margin: 0;

                            .Icon-Left {
                                inset-inline-start: 10px;

                                @include isArabic {
                                    transform: rotate(180deg) translateY(50%);
                                }
                            }
                        }
                    }

                    &-ItemName {
                        font-size: var(--menu-mobile-subitem-font-size);
                        padding: var(--menu-mobile-subitem-padding);
                    }

                    &-SubMenu {
                        inset-block-start: -50px;
                        z-index: 3;
                    }

                    &-CustomGroupTitle {
                        font-family: var(--font-FFDIN);
                        font-weight: bold;
                        font-size: var(--menu-customgroup-title-font);
                        margin-block-end: 15px;

                        &::first-letter {
                            text-transform: capitalize;
                        }

                        @include isArabic {
                            font-family: var(--font-DINArabic);
                        }
                    }
                }
            }

            &-SubmenuTitle {
                display: block;
                text-align: center;
                font-size: var(--menu-mobile-subitem-font-size);
                text-transform: capitalize;
            }

            &-SubMenuHeading {
                background-color: var(--menu-submenu-heading-color);
                padding: 18px 0;
                margin: 0;
                width: 100%;
            }

            &-SubItemWrapper {
                flex-basis: 0;
                margin: 0;

                a {
                    padding: 0;

                    >.Menu-ItemName {
                        border-bottom: 1px solid var(--menu-mobile-item-border);
                    }
                }

                &:last-child {
                    margin-block-end: 50px;
                }

                @include desktop {
                    margin: 0;
                }
            }

            &-ExploreMore {
                background: var(--color-white);
                font-family: var(--font-FFDIN);
                font-weight: normal;
                padding: 2% 90px;

                @include isArabic {
                    font-family: var(--font-DINArabic);
                }

                .ExploreMore {
                    font-size: var(--menu-explore-more-font-size);
                }

                .Link-Chart {
                    border-color: var(--menu-explore-more-underline);
                    border-width: 2px;
                }
            }

            &-SubCatLink {
                cursor: pointer;
            }
        }
    }
}

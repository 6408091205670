/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * Copyright © Scandiweb, Inc. All rights reserved.
 *
 * @category    Puma
 * @package     Puma_SimpleSearch
 * @author      Deniss Strombergs <info@scandiweb.com>
 */
 
.SimpleSearch {
    @include small-mobile {
        display: flex;
    }

    @include after-mobile {
        width: 58%;
        min-width: 560px;
    }

    &-Input {
        margin-inline-end: 10px;
        padding: 10px 17px;
        font-size: 12px;
        text-transform: uppercase;
        width: 100%;
        margin-block-end: 16px;

        &:focus {
            font-weight: normal;
        }

        @include small-mobile {
            width: 69%;
            margin-block-end: 0px;
        }

        @include after-mobile {
            width: 69%;
            font-size: 14px;
        }
    }

    &-Submit {
        width: 100%;
        text-transform: uppercase;
        font-weight: bold;
        padding: 11px 16px 13px;
        color: $white;
        border: solid 1px $black;
        background-color: $black;
        transition: background-color .3s, color .3s, border-color .3s;
        font-size: 12px;
        line-height: 1;

        @include small-mobile {
            width: 31%;
        }

        @include after-mobile {
            width: 31%;
            font-size: 15px;
            padding: 15px 16px 16px;
            margin-block-end: 0px;
        }

        &:hover {
            background-color: $white;
            color: $black;
        }
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

.FieldInput {
    &-Error {
        color: var(--color-red);
        position: absolute;
        inset-block-end: var(--input-position-bottom);
    }

    &-Input {
        &_isValid {
            &_false {
                border-color: var(--color-red);

                &:focus {
                    border-color: var(--color-red);
                }
            }
        }
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.CookiePopup {
    background-color: transparent;
    display: flex;
    justify-content: center;
    padding: 0px;
    border-top: none;
    inset-block-end: 0;
    inset-inline-start: unset;
    transform: unset;
    opacity: 1;
    width: 100vw;

    @include isArabic {
        inset-inline-start: unset;
        transform: unset;
    }

    @include small-mobile {
        padding: 0px 20px 20px 20px;
    }

    @include mobile {
        margin: 0;
    }

    &-ContentWrapper {
        padding: 0px;

        @include small-mobile {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    &-Wrapper {
        background-color: $c-gray-nero;
        padding: 23px 30px;
        max-width: 1110px;
        width: 100%;

        @include small-desktop {
            padding: 23px 40px;
        }
    }

    &-Heading {
        color: $white;
        font-size: 16px;
        margin: 0px;
        text-transform: none;
        text-align: start;
        direction: ltr;
    }

    &-Content {
        color: $c-gray-ad;
        max-width: none;
        font-size: 14px;
        font-weight: unset;
        text-align: start;

        @include isArabic {
            padding-inline-end: 0;
        }

        @include mobile {
            padding-block-end: 0px;
        }
    }

    &-Link {
        color: $white;
        font-weight: bold;
        font-size: 14px;
        margin-inline-start: 5px;
    }

    &-CTA {
        margin-inline-start: 2rem;

        @include mobile {
            margin-block-start: 0;
            margin-inline-start: 0;
            margin: 0;

            @include isArabic {
                margin-inline-end: 0;
            }
        }

        button {
            min-width: 186px;
            padding: 13px 16px;
            min-height: 47px;
            font-weight: bold;
            border-radius: 2px;
            font-size: 14px;
            text-transform: none;
            margin-block-start: 20px;

            @include small-mobile {
                margin-block-start: 0px;
            }

            @include mobile {
                width: 100%;
            }
        }
    }
}

:root {
    --header-padding: 2.6rem 0 2.3rem;
    --header-background-color: #{$c-gray-e};
    --header-title-font: 1.5rem;
    --header-link-font-big: 1.4rem;
    --header-link-font-small: .8rem;

    @include small-mobile {
        --header-title-font: 2.1rem;
        --header-link-font-small: 1rem;
    }

    @include after-mobile {
        --header-padding: 2.6rem 0 2.8rem;
    }

    @include small-desktop {
        --header-padding: 3.1rem 0 3.5rem;
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

.SearchItem {
    padding: 1rem;

    @include desktop {
        border: 2px solid transparent;
        padding: 1.5rem 1rem;
    }

    &::after {
        content: none;
    }

    &:hover {
        @include desktop {
            cursor: pointer;
            border: 2px solid var(--color-grey-e);
        }
    }

    &-Content {
        @include isArabic {
            text-align: end;
        }
    }

    &-Wrapper {
        display: grid;
        grid-template-columns: 5rem calc(100% - 7rem);
        grid-column-gap: 2rem;
    }

    .ProductPrice {
        &-CurrentPrice {
            font-size: 14px;

            span {
                font-weight: normal;
                color: var(--color-gray-mortar);
                text-transform: uppercase;
            }
        }
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --link-color: var(--color-gray-nero);
    --link-hover: #{$white};
    --color-animated-before: #ccc;
    --color-animated-after: #777;
}

a {
    color: var(--link-color);

    &.Link {
        &-Menu {
            font-size: 18px;
            color: #{$white};

            &:hover {
                text-decoration: none;

                &::after {
                    transition: all 0.15s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
                    transition-property: all;
                    transition-duration: 0.15s;
                    transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
                    transition-delay: 0s;
                    content: "";
                    position: absolute;
                    inset-block-start: 100%;
                    inset-inline-start: 0px;
                    inset-inline-end: 0px;
                    height: 2px;
                    background-color: rgb(174, 148, 109);
                    transform: translateY(2px);
                }
            }
        }

        &-Chart {
            color: #{$black};
            font-size: 14px;
            font-family: var(--font-FFDIN);
            font-weight: bold;
            text-transform: uppercase;
            border-bottom: 1px solid;

            @include isArabic {
                font-family: var(--font-DINArabic);
            }

            &:hover {
                text-decoration: none;
                color: #{$c-red};
            }
        }

        &-Footer {
            color: #{$c-gray-text};
            font-size: 14px;

            &:hover {
                text-decoration: none;
                color: #{$white};
            }
        }

        &-Primary {
            color: #{$c-red-primary-link};
            font-size: 14px;
        }

        &-Animated {
            text-transform: uppercase;

            &_bold_white {
                color: #{$white};
                font-weight: bold;
                text-transform: unset;
            }

            &::before {
                content: "";
                position: absolute;
                inset-block-start: 100%;
                width: 100%;
                inset-inline-start: 0px;
                height: 2px;
                background-color: var(--color-animated-before);
            }

            &::after {
                transition: width 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
                inset-inline-start: auto;
                inset-inline-end: 0px;
                width: 0px;
                content: "";
                position: absolute;
                inset-block-start: 100%;
                height: 2px;
                background-color: var(--color-animated-after);
            }

            &:hover {
                text-decoration: none;

                &::after {
                    inset-inline-start: 0px;
                    inset-inline-end: auto;
                    width: 100%;
                }
            }
        }
    }

    &:hover {
        text-decoration: underline;
    }

    &:focus {
        text-decoration: underline;
    }

    + a {
        margin-inline-start: 1rem;
    }
}

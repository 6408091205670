/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

// Copied from Popup.extended.style.scss to display pop-up that shows banner details with appropriate styling

.Popup {
    opacity: 0;
    pointer-events: none;
    z-index: 200;
    position: fixed;
    background-color: #0003;
    inset-inline-start: 0;
    inset-block-end: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &-Header {
        display: flex;
        justify-content: space-between;

        button.Popup-CloseBtn {
            position: relative;
            inset-inline-end: unset;
            height: 14px;
            width: 14px;
            z-index: 5;
            inset-block-start: -4px;

            @include isArabic {
                inset-inline-start: -1rem;
            }
        }
    }

    &-Heading {
        font-size: 30px;
        text-decoration: none;
        font-family: var(--font-FFDIN);
        font-weight: bold;
        margin-block-start: 0;

        @include isArabic {
            font-family: var(--font-DINArabic);
        }
    }

    &-Content {
        background-color: #{$white};
        border-radius: 0;
        padding: 2rem;
        min-width: unset;
        max-width: calc(var(--content-wrapper-width) * .8);
        max-height: 80%;
        overflow-y: auto;
        width: 90%;
    }

    &_isVisible {
        pointer-events: all;
        opacity: 1;
        width: 100vw;
        height: 100vh;
    }

    &-CloseBtn {
        &::before,
        &::after {
            content: '';
            position: absolute;
            width: 2px;
            height: 100%;
            transform-origin: 50% 50%;
            inset-block-start: 7px;
            transform: rotate(-45deg);
            background-color: #{$c-gray-c};
        }

        &::after {
            transform: rotate(45deg);
        }
    }
}

@media (min-width: 768px) {
    .Popup-Content {
        width: -moz-max-content;
        width: max-content;
    }

    .Popup-Header button.Popup-CloseBtn {
        height: 28px;
        width: 28px;
    }
}

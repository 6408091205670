/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

.TrendingInlineGroupCarousel {
    &-Container {
        display: flex;
        overflow: hidden;
        width: 100%;

        .trending-inline-item {
            width: auto;
        }
    }

    &-Wrapper {
        display: flex;
        transform: translateX(var(--leftOffset));
        width: 100%;
        will-change: transform;
    }

    &-Item {
        min-width: 33.33%;

        @include tablet {
            min-width: 50%;
        }

        @include mobile {
            min-width: 100%;
        }
    }

    &-BackButton {
        position: absolute;
        inset-inline-start: 0px;
        inset-block-start: 50%;
        transform: translateY(-50%);
        padding: 12px;
        height: 100%;
        width: 4rem;
        z-index: 10;
        opacity: 0.5;
        transition: all 0.25s ease-in-out;

        &:hover {
            opacity: 1;
        }

        &::after {
            content: '';
            border-style: solid;
            border-width: 0 3px 3px 0;
            border-color: #ffffff;
            inset-inline-start: 20px;
            position: absolute;
            display: inline-block;
            padding: 12px;
            inset-block-start: 50%;
            transform: translateY(-50%) rotate(135deg);
        }
    }

    &-NextButton {
        position: absolute;
        inset-inline-end: 0px;
        inset-block-start: 50%;
        transform: translateY(-50%);
        padding: 12px;
        height: 100%;
        width: 4rem;
        z-index: 10;
        opacity: 0.5;
        transition: all 0.25s ease-in-out;

        &:hover {
            opacity: 1;
        }

        &::after {
            content: '';
            border-style: solid;
            border-width: 0 3px 3px 0;
            border-color: #ffffff;
            inset-inline-end: 20px;
            position: absolute;
            display: inline-block;
            padding: 12px;
            inset-block-start: 50%;
            transform: translateY(-50%) rotate(-45deg);
        }
    }
}

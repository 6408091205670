/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

:root {
    --window-inner-height-checkout: 100%;
}

.Popup {
    opacity: 0;
    pointer-events: none;
    z-index: 200;
    position: fixed;
    background-color: var(--popup-background);
    inset-inline: 0;
    inset-block: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &_isVisible {
        pointer-events: all;
        opacity: 1;
        width: 100vw;
        height: 100vh;
    }

    &_isVisible.CheckoutNewAddressPopup_isVisible {
        height: var(--window-inner-height-checkout);
    }

    &-Heading {
        font-size: 30px;
        text-decoration: none;
        font-family: var(--font-FFDIN);
        font-weight: bold;
        margin-block-start: 0;

        @include isArabic {
            font-family: var(--font-DINArabic);
        }
    }

    &-Header {
        display: flex;
        justify-content: space-between;

        button {
            &.Popup-CloseBtn {
                position: relative;
                inset-inline-end: unset;
                height: 14px;
                width: 14px;

                @include isArabic {
                    inset-inline-start: unset;
                    inset-inline-end: unset;
                }

                @include desktop {
                    height: 28px;
                    width: 28px;
                }

                &:hover {
                    &::before,
                    &::after {
                        background-color: var(--color-red);
                    }
                }
            }
        }
    }

    &-Content {
        background-color: var(--popup-content-background);
        border-radius: 0;
        padding: var(--popup-content-padding);
        min-width: unset;
        max-width: calc(var(--content-wrapper-width) * .8);
        max-height: 80%;
        overflow-y: auto;
        width: 90%;

        @include desktop {
            width: max-content;
        }

        &::-webkit-scrollbar {
            background: 0 0;
            inset-inline-end: 0;
            width: 12px;
        }

        &::-webkit-scrollbar-thumb {
            background: var(--color-grey-e);
            cursor: pointer;
            position: absolute;
            inset-block-start: 0;
            inset-inline-end: 0;
            inset-inline-start: auto;
            min-height: 20px;
            width: 5px;
        }

        &::-webkit-scrollbar-track {
            background: 0 0;
            border-radius: 0;
            position: relative;
            inset-inline-end: 5px;
            height: 100%;
            width: 5px;
        }

        &::after {
            content: none;
        }
    }

    &-CloseBtn {
        inset-block-start: -4px;

        @include isArabic {
            @include mobile {
                inset-inline-start: unset;
                inset-inline-end: -10px;
                inset-block-start: -5px;
            }
        }
    }

    &_block_SubscriptionBlockNonRegister {
        @include mobile {
            inset-block-start: var(--mobile-popup-top);
        }

        .Popup {
            &-Content {
                margin: auto;

                @include mobile {
                    position: absolute;
                    max-height: var(--mobile-block-max-height);
                    inset-block-start: 0;
                    max-width: unset;
                    height: 100%;
                    width: 100%;
                }

                @include desktop {
                    height: 600px;
                    width: 700px;
                    padding: 0;
                }
            }

            &-Header {
                @include desktop {
                    position: absolute;
                    inset-inline-end: 0;

                    @include isArabic {
                        inset-inline-start: 15px;
                        inset-inline-end: unset;
                    }
                }
            }
        }
    }

    &.SubscriptionBlockNonRegister-Form_isSuccess {
        .Popup {
            &-Content {
                @include desktop {
                    height: 300px;
                }

                .SubscriptionBlock {
                    &-Form {
                        inset-block-start: var(--mobile-block-half-height);
                        transform: translateY(-50%);

                        @include desktop {
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 100%;
                            inset-block-start: unset;
                            transform: unset;
                        }

                        .Icon-Puma {
                            @include mobile {
                                margin: auto;
                            }

                            @include desktop {
                                @include isArabic {
                                    margin-inline-start: 30px;
                                    margin-inline-end: unset;
                                }
                            }
                        }
                    }

                    &-Message {
                        text-align: center;

                        @include desktop {
                            text-align: unset;
                            font-size: 15px;
                        }
                    }
                }
            }
        }
    }

    #adyen-checkout-threeds2-popup {
        >div {
            height: 540px;

            iframe {
                height: 540px;
            }
        }
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

$select-width: 160px !default;
$select-height: 45px !default;
$select-option-height: 41px !default;
$select-arrow-height: 6px !default;
$select-arrow-width: 6px !default;

:root {
    --select-option-background: var(--color-white);
    --select-option-hover-background: var(--color-grey-fb);
    --select-placeholder-font-size: 14px;
}

.FieldSelect {
    cursor: pointer;
    position: relative;

    &::after {
        content: '';
        display: block;
        position: absolute;
        transform: translateY(-120%) rotate(45deg);
        inset-block-start: 50%;
        transition: all .5s ease;
        width: 10px;
        height: 10px;
        border-style: solid;
        border-color: var(--color-gray-nero);
        border-width: 0 1px 1px 0;
        pointer-events: none;
        inset-inline-end: 20px;
        inset-block-end: 0;
        margin: auto;
    }

    &[aria-expanded="true"] {
        &::after {
            transform: translateY(-70%) rotate(225deg);
        }
    }

    &-LabelPlaceholder {
        position: absolute;
        display: block;
        z-index: 2;
        inset-block-start: 5px;
        inset-inline-start: 15px;
        font-size: var(--font-size-medium);
        color: var(--color-input-label);

        @include isArabic {
            font-size: var(--font-size-pico);

            @include after-mobile {
                font-size: var(--font-size-nano);
            }
        }

        &_isRequired {
            &::after {
                content: '*';
                position: relative;
                inset-inline-start: 2px;
                inset-block-end: -2px;
            }
        }
    }

    &-Select {
        padding-block-start: 20px;
        text-transform: uppercase;
        font-weight: bold;
        line-height: 14px;
        font-size: var(--select-placeholder-font-size);
    }

    &-Options {
        transition: none;
        visibility: hidden;
        border: 1px solid var(--input-color);
        border-top: none;
        background-color: var(--color-white);

        &_isExpanded {
            visibility: visible;
            overflow: auto;
        }

        &::-webkit-scrollbar {
            background: 0 0;
            inset-inline-end: 0;
            width: 12px;
        }

        &::-webkit-scrollbar-thumb {
            background: #eee;
            cursor: pointer;
            position: absolute;
            inset-block-start: 0;
            inset-inline-end: 0;
            inset-inline-start: auto;
            min-height: 20px;
            width: 5px;
        }

        &::-webkit-scrollbar-track {
            background: 0 0;
            border-radius: 0;
            position: relative;
            inset-inline-end: 5px;
            height: 100%;
            width: 5px;
        }
    }

    &-Option {
        cursor: pointer;
        transition: none;
        max-height: $select-option-height;
        height: $select-option-height;
        border-bottom: solid 1px #ebebeb;
        text-transform: uppercase;

        > span {
            position: absolute;
            inset-block-start: 50%;
            transform: translateY(-50%);

            > span {
                color: $c-red;
            }
        }

        &:hover,
        &:focus {
            font-family: var(--font-FFDIN);
            font-weight: bold;

            @include isArabic {
                font-family: var(--font-DINArabic);
            }

            @include desktop {
                background: var(--select-option-hover-background);
            }
        }
    }


    &_isNotValid {
        border: 1px solid var(--color-red);
    }
}

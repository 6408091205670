/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * Copyright © Scandiweb, Inc. All rights reserved.
 *
 * @category    Puma
 * @package     Puma_ForgotPassword
 * @author      Deniss Strombergs <info@scandiweb.com>
 */

.ForgotPassword {
    &-Note {
        margin: 17px 0px 14px;
        font-size: 9px;
        font-weight: 300;
        width: 88%;

        @include after-mobile {
            font-size: 14px;
            margin: 34px 0px 26px;
            width: 100%;
        }
    }

    &-ConfirmationEmailContainer {
        display: block;
        align-items: flex-start;

        @include after-mobile {
            display: flex;

            & .Field_type_email {
                width: 100%;
                margin-inline-end: 8px;
            }
        }
    }

    &-Email, &-ConfirmationEmail {
        width: 100%;
        margin-block-start: 0px;
        border-color: $c-gray-c;

        .FieldInput {
            &-Input {
                border-color: #ccc;
                font-size: 12px;
                padding: 17px 40px 9px 15px;
                width: 100%;

                @include isArabic {
                    padding: 17px 15px 9px 15px;
                }

                @include after-mobile {
                    font-size: 14px;
                    padding: 18px 40px 9px 12px;

                    @include isArabic {
                        padding: 18px 12px 9px 12px;
                    }
                }
            }

            &-Error {
                inset-block-end: auto;
                font-size: 9px;

                @include small-desktop {
                    font-size: 11px;
                    min-height: 13px;
                }
            }

            &-Input_isValid_true.FieldInput-Input_isFilled, &-Input:focus:not(.FieldInput-Input_isFilled) {
                border-color: $black;
            }
        }
    }

    &-Submit {
        min-width: 100%;
        height: 47px;
        font-size: 14px;
        transition: background-color .3s, color .3s, border-color .3s;
        padding: 9px 20px 10px;

        @include after-mobile {
            min-width: max-content;
            padding: 16px 13px 11px;
        }

        @include small-desktop {
            min-width: max-content;
            padding: 13px 15px;
        }

        &:not(:disabled) {
            &:hover {
                background-color: $white;
                color: $black;
            }
        }
    }

    &-Form {
        &::before {
            content: '';
            display: block;
            border-bottom: 1px solid $c-input-border;
            position: absolute;
            inset-block-start: -15px;
            inset-inline-start: -25px;
            width: calc(100% + 25px * 2);

            @include small-desktop {
                inset-block-start: -34px;
                inset-inline-start: -48px;
                width: calc(100% + 48px * 2);
            }
        }

        .Icon-CheckGreen {
            height: 12px;
            inset-block-start: 15px;
            inset-inline-end: 1rem;
            position: absolute;
            width: 13px;

            @include desktop {
                inset-inline-end: 1.5rem;
            }
        }
    }

    &-Popup {
        .Popup {
            &-Heading {
                margin-block-end: 20px;
                font-size: 15px;

                @include after-mobile {
                    font-size: 30px;
                    margin-block-end: 34px;
                }
            }

            &-Content {
                padding: 19px 25px 24px;

                @include after-mobile {
                    width: 70%;
                    padding: 34px 48px 53px;
                }

                @include small-desktop {
                    width: 41.1%;
                }
            }
        }
    }

    &_isValid {
        .FieldInput {
            &-Error {
                display: none;
            }
        }
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --input-type-number-width: 80px;
    --placeholder-gradient-color: #d9d9d9;
    --placeholder-image: linear-gradient(to right, #ebebeb 0, var(--placeholder-gradient-color) 15%, #ebebeb 30%);
    --placeholder-size: 1200px 100%;
    --placeholder-animation: placeholderAnimation 2s linear infinite;
    --primary-divider-color: #f0f0f0;
    --secondary-error-color: #fff;
    --primary-error-color: #f00;
    --secondary-success-color: #fff;
    --primary-success-color: #7fcd91;
    --secondary-info-color: #79632e;
    --primary-info-color: #ffd166;

    // Declare imported primary colors to change the theme's colors
    --primary-base-color: var(--imported_primary_base_color, #{$default-primary-base-color});
    --primary-dark-color: var(--imported_primary_dark_color, #{$default-primary-dark-color});
    --primary-light-color: var(--imported_primary_light_color, #{$default-primary-light-color});
    --secondary-base-color: var(--imported_secondary_base_color, #{$default-secondary-base-color});
    --secondary-dark-color: var(--imported_secondary_dark_color, #{$default-secondary-dark-color});
    --secondary-light-color: var(--imported_secondary_light_color, #{$default-secondary-light-color});

    // Color variables
    --color-black: var(--imported_color_black, #{$black});
    --color-white: var(--imported_color_white, #{$white});
    --color-gray-nero: var(--imported_color_grey_nero, #{$c-gray-nero});
    --color-gray-title: var(--imported_color_gray_nero, #{$c-gray-title});
    --color-gray-text: var(--imported_color_gray_text, #{$c-gray-text});
    --color-gray-button: var(--imported_color_gray_button, #{$c-gray-button});
    --color-gray-disabled: var(--imported_color_gray_disabled, #{$c-gray-disabled});
    --color-silver: var(--imported_color_silver, #{$c-silver});
    --color-white-smoke: var(--imported_color_white_smoke, #{$c-white-smoke});
    --color-charcoal: var(--imported_color_charcoal, #{$c-charcoal});
    --color-brown-mongoose: var(--imported_color_brown_mongoose, #{$c-brown-mongoose});
    --color-brown-mongoose-light: var(--imported_color_brown_mongoose_light, #{$c-brown-mongoose-light});
    --color-orange-jaffa: var(--imported_color_orange_jaffa, #{$c-orange-jaffa});
    --color-pink-rose: var(--imported_color_pink_rose, #{$c-pink-rose});
    --color-red: var(--imported_color_red, #{$c-red});
    --color-red-dark: var(--imported_color_red_dark, #{$c-red-dark});
    --color-red-cardinal: var(--imported_color_red_carinal, #{$c-red-cardinal});
    --color-red-vivid: var(--imported_color_red_vivid, #{$c-red-vivid});
    --c-red-primary-link: var(--imported_color_red_carinal, #{$c-red-primary-link});
    --color-gray-mortar: var(--imported_color_gray_mortar, #{$c-gray_mortar});
    --color-gray-crumb: var(--imported_color_gray_crumb, #{$c-gray_crumb});
    --color-grey-c: var(--imported_color, #{$c-gray-c});
    --color-input-border: var(--imported_color_input_border, #{$c-input_border});
    --color-input-label: var(--imported_color_input_label, #{$c-input_label});
    --color-grey-e: var(--imported_color, #{$c-gray-e});
    --color-grey-fb: var(--imported_color, #{$c-gray-fb});
    --color-gray-gainsboro: var(--imported_color_gray_gainsboro, #{$c-gray-gainsboro});
    --color-grey-7: var(--imported_color, #{$c-gray-7});
    --color-grey-f6: var(--imported_color, #{$c-gray-f6});
    --color-green: var(--imported_color, #{$c-green});
    --color-grey-alto: var(--imported_color, #{$c-gray-alto});
    --c-green-notification: var(--imported_color, #{$c-green-notification});
    --color-grey-d5: var(--imported_color, #{$c-grey-d5});

    // Font family:
    --font-FFDIN: var(--imported_font, #{$font-FFDIN});
    --font-DINArabic: var( --imported_font, #{$font-DINArabic});

    // Font size:
    --font-size-pico: var(--imported_font_size, #{$fs-pico});
    --font-size-nano: var(--imported_font_size, #{$fs-nano});
    --font-size-micro: var(--imported_font_size, #{$fs-micro});
    --font-size-small: var(--imported_font_size, #{$fs-small});
    --font-size-normal-small: var(--imported_font_size, #{$fs-normal-small});
    --font-size-normal: var(--imported_font_size, #{$fs-normal});
    --font-size-medium: var(--imported_font_size, #{$fs-medium});
    --font-size-medium-small: var(--imported_font_size, #{$fs-medium-small});
    --font-size-medium-big: var(--imported_font_size, #{$fs-medium-big});
    --fs-header-desktop-small: var(--imported_font_size, #{$fs-header-desktop-small});
}

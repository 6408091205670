/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

.SubscriptionPreferences {
    width: 100%;

    .Icon-Close {
        inset-inline-end: 20px;
        position: absolute;
        z-index: 2;
        height: 48px;
        width: 48px;

        @include isArabic {
            inset-inline-start: 20px;
            inset-inline-end: unset;
        }

        &:hover {
            // Making svg color red with filter help
            filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);
        }
    }
}

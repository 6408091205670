/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

.ScrollToTop {
    &-Button {
        background: #fff;
        box-shadow: 0 0 25px var(--color-silver);
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        inset-block-end: 4.5rem;
        height: 5rem;
        width: 5rem;
        inset-inline-end: 1.5rem;
        transform: rotate(270deg);
        z-index: 110;

        @include mobile {
            width: 3.5rem;
            height: 3.5rem;
            inset-block-end: 1.5rem;

            &_isProductPage {
                inset-block-end: 6rem;
            }
        }

        &:hover {
            fill: red;
        }

        .Arrow {
            width: 1.5rem;
        }
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

// 'div' is used to overpower css in content/view/global_promo_banner/view.phtml
// Will show global promo banner content when pwa (and this CSS) loads

span.global-promo-banner__text_mobile,
a.global-promo-banner__action {
    display: inline;
}

@media only screen and (min-width: 768px) {
    div.global-promo-banner {
        font-size: 14px;
    }

    span.global-promo-banner__text_mobile {
        display: none;
    }

    span.global-promo-banner__text_desktop {
        display: inline;
    }
}

div.global-promo-banner {
    z-index: 99;

    a {
        color: #fff;
        text-decoration: none;
        margin: 0;

        &:not(:first-child) {
            margin-inline-start: 5px;
        }

        &:hover {
            text-decoration: underline;
        }

        @include desktop {
            &:not(:first-of-type) {
                margin-inline-start: 90px;
            }
        }

        @include mobile {
            animation: appear 1s ease-in;

            &.hidden {
                display: none;
            }
        }
    }
}

div.global-promo-banner-list__item {
    display: inline-block;
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/* stylelint-disable csstools/use-logical */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.StickySubscribeButton {
    &-Wrapper {
        position: fixed;
        inset-inline-start: 1.5rem;
        inset-block-end: 4.5rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 40px;
        z-index: 100;

        @include mobile {
            inset-block-end: 5.5rem;
        }
    }

    &-Outer {
        clip-path: polygon(-2px -2px, -2px calc(100% + 2px), calc(100% + 2px) calc(100% + 2px), calc(100% + 2px) -2px);

        &::before {
            content: "";
            position: absolute;
            inset-block-start: -2px;
            left: calc(-100% - 4px);
            width: calc(100% + 2px);
            height: 2px;
            background-color: var(--color-black);
            animation: 10s ease 2s 5.5 normal forwards running attention-button-top-border;

            @include isArabic {
                left: unset;
                right: calc(-100% - 4px);
                animation: 10s ease 2s 5.5 normal forwards running attention-button-top-border-arabic;
            }
        }

        &::after {
            content: "";
            position: absolute;
            inset-block-end: -2px;
            right: calc(-100% - 4px);
            width: calc(100% + 2px);
            height: 2px;
            background-color: var(--color-black);
            animation: 10s ease 2s 5.5 normal forwards running attention-button-bottom-border;

            @include isArabic {
                right: unset;
                left: calc(-100% - 4px);
                animation: 10s ease 2s 5.5 normal forwards running attention-button-bottom-border-arabic;
            }
        }
    }

    &-Inner {
        clip-path: polygon(-2px -2px, -2px calc(100% + 2px), calc(100% + 2px) calc(100% + 2px), calc(100% + 2px) -2px);

        &::before {
            content: "";
            position: absolute;
            inset-inline-start: -2px;
            inset-block-end: calc(-100% - 4px);
            width: 2px;
            height: calc(100% + 2px);
            background-color: var(--color-black);
            animation: 10s ease 2s 5.5 normal forwards running attention-button-left-border;
        }

        &::after {
            content: "";
            position: absolute;
            inset-inline-end: -2px;
            inset-block-start: calc(-100% - 4px);
            width: 2px;
            height: calc(100% + 2px);
            background-color: var(--color-black);
            animation: 10s ease 2s 5.5 normal forwards running attention-button-right-border;
        }
    }

    &-Button {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        min-width: max-content;
        border-width: 1px;
        color: var(--color-black);
        background-color: var(--color-white);
        border-color: var(--color-white);
        border-radius: 0.125rem;
        padding-inline-start: 1rem;
        padding-inline-end: 1rem;
        cursor: pointer;
    }

    &-Text {
        padding-inline: 0.5rem;
        line-height: 1.5;
        font-size: 16px;
        font-weight: 700;
        inset-block-start: 1px;
    }
}

@keyframes attention-button-top-border {
    15% {
        left: calc(-100% - 4px);
    }

    20% {
        left: -2px;
    }

    50% {
        left: -2px;
    }

    55% {
        left: calc(-100% - 4px);
    }
}

@keyframes attention-button-top-border-arabic {
    15% {
        right: calc(-100% - 4px);
    }

    20% {
        right: -2px;
    }

    50% {
        right: -2px;
    }

    55% {
        right: calc(-100% - 4px);
    }
}

@keyframes attention-button-left-border {
    10% {
        inset-block-end: calc(-100% - 4px);
    }

    15% {
        inset-block-end: -2px;
    }

    55% {
        inset-block-end: -2px;
    }

    60% {
        inset-block-end: calc(-100% - 4px);
    }
}

@keyframes attention-button-right-border {
    0% {
        inset-block-start: calc(-100% - 4px);
    }

    5% {
        inset-block-start: -2px;
    }

    65% {
        inset-block-start: -2px;
    }

    70% {
        inset-block-start: calc(-100% - 4px);
    }
}

@keyframes attention-button-bottom-border {
    5% {
        right: calc(-100% - 4px);
    }

    10% {
        right: -2px;
    }

    60% {
        right: -2px;
    }

    65% {
        right: calc(-100% - 4px);
    }
}

@keyframes attention-button-bottom-border-arabic {
    5% {
        left: calc(-100% - 4px);
    }

    10% {
        left: -2px;
    }

    60% {
        left: -2px;
    }

    65% {
        left: calc(-100% - 4px);
    }
}

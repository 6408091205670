/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

.info-hotspot-hero {
    position: relative;

    &__img-w {
        .Image-Image_isLoading {
            min-height: 60px;

            @include desktop {
                min-height: 200px;
            }

            @include small-desktop {
                min-height: 75px;
            }
        }
    }

    .Image {
        padding-block-end: 0;
        height: 100%;
    }

    img {
        position: relative;
    }
}

.info-hotspot-hero__hotspot {
    display: none;
    width: 3rem;
    height: 3rem;
    position: absolute;
    border-radius: 50%;
    border: .3rem solid #222;
    box-shadow: 0 0 1.5rem -.1rem #fff;
    transition: all .3s ease
}

@media only screen and (min-width: 1025px) {
    .info-hotspot-hero__hotspot:hover {
        border: .3rem solid red;
        box-shadow: none
    }

    .info-hotspot-hero__hotspot:hover::before {
        content: '';
        position: absolute;
        inset-block-start: -.6rem;
        inset-inline-start: -.6rem;
        width: 3.6rem;
        height: 3.6rem;
        border-radius: 50%;
        border: .6rem solid red
    }

    .info-hotspot-hero__hotspot:hover::after {
        background: radial-gradient(circle closest-side, #000, #000 .2rem, #fff .2rem, #fff 1.1rem, #888 .2rem, #888)
    }
}

.info-hotspot-hero__hotspot::after {
    content: '';
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
    background: radial-gradient(circle closest-side, red, red .2rem, #fff .2rem, #fff .7rem, rgba(255, 255, 255, .25) .8rem, rgba(255, 255, 255, .25))
}

.info-hotspot-hero .ui-dialog {
    /* stylelint-disable-next-line declaration-no-important */
    position: absolute !important;
    /* stylelint-disable-next-line declaration-no-important */
    inset-block-start: 50% !important;
    /* stylelint-disable-next-line declaration-no-important */
    inset-inline-start: 50% !important;
    /* stylelint-disable-next-line declaration-no-important */
    transform: translateX(-50%) translateY(-50%) !important;
    background: #fff;
    padding: 2.5rem 2rem
}

@media (max-width: 1024px) {
    .info-hotspot-hero .ui-dialog {
        /* stylelint-disable-next-line declaration-no-important */
        display: none !important
    }
}

.info-hotspot-hero .ui-dialog .ui-dialog-titlebar-close {
    appearance: none;
    background: 0 0;
    border: none;
    border-radius: 0;
    cursor: pointer;
    display: block;
    font-size: 0;
    outline: none;
    padding: 0;
    position: absolute;
    inset-block-start: 1.5rem;
    inset-inline-end: 1.5rem;
    width: 2rem;
    height: 2rem
}

.info-hotspot-hero .ui-dialog .ui-dialog-titlebar-close::before, .info-hotspot-hero .ui-dialog .ui-dialog-titlebar-close::after {
    content: '';
    background: #222;
    display: inline-block;
    position: absolute;
    inset-block-start: 50%;
    inset-inline-start: 50%;
    transition: background .2s;
    height: 100%;
    width: 2px
}

.info-hotspot-hero .ui-dialog .ui-dialog-titlebar-close::before {
    transform: translate(-50%, -50%) rotate(45deg)
}

.info-hotspot-hero .ui-dialog .ui-dialog-titlebar-close::after {
    transform: translate(-50%, -50%) rotate(-45deg)
}

.info-hotspot-hero .ui-dialog .ui-dialog-titlebar-close:-moz-focusring {
    /* stylelint-disable-next-line declaration-no-important */
    color: transparent !important;
    /* stylelint-disable-next-line declaration-no-important */
    text-shadow: 0 0 0 #000 !important
}

.info-hotspot-hero .ui-dialog .ui-dialog-titlebar-close:focus, .info-hotspot-hero .ui-dialog .ui-dialog-titlebar-close:active {
    outline: none
}

.info-hotspot-hero .ui-dialog .ui-dialog-titlebar-close:hover::before, .info-hotspot-hero .ui-dialog .ui-dialog-titlebar-close:hover::after {
    background: red
}

.info-hotspot-hero__main-content {
    position: relative
}

.info-hotspot-hero__main-img {
    width: 100%;
    height: auto;
    display: block;

    @include desktop {
        display: none;
    }

    &_desktop {
        display: none;

        @include small-desktop {
            display: block;
        }
    }

    &_tablet {
        display: none;

        @include desktop {
            display: block;
        }

        @include small-desktop {
            display: none;
        }
    }

    &_tablet,
    &_desktop {
        .Image-Image_isLoading {
            min-height: 550px;
        }
    }

    .Image-Image_isLoading {
        min-height: 250px;
    }
}

@media (max-width: 1024px) {
    .info-hotspot-hero__main-img-w {
        position: relative
    }
}

.info-hotspot-hero__main-title {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font: 45px 'FFDIN', Arial, sans-serif;
    font: 4.5rem 'FFDIN', Arial, sans-serif;
    line-height: 1.1;
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;
    inset-inline-end: 0;
    margin-block-start: 3rem;
    text-align: center;
    text-transform: uppercase;
    color: #222
}

@media (max-width: 1024px) {
    .info-hotspot-hero__main-title {
        position: static;
        margin-block-start: 3.5rem;
        margin-block-end: 3.5rem
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .info-hotspot-hero__main-title {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font: 27px 'FFDIN', Arial, sans-serif;
        font: 2.7rem 'FFDIN', Arial, sans-serif
    }
}

@media (max-width: 767px) {
    .info-hotspot-hero__main-title {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font: 22px 'FFDIN', Arial, sans-serif;
        font: 2.24rem 'FFDIN', Arial, sans-serif
    }
}

.info-hotspot-hero__container-w {
    margin-block-end: 3rem
}

@media only screen and (min-width: 1025px) {
    .info-hotspot-hero__container-w {
        margin-inline-start: 2.3rem;
        margin-inline-end: 2.3rem
    }
}

@media (max-width: 1024px) {
    .info-hotspot-hero__container-w {
        display: flex;
        margin-block-start: 3.5rem;
        padding-inline-start: 3.7rem;
        padding-inline-end: 3.7rem
    }
}

.info-hotspot-hero__container {
    margin-inline-start: .8rem;
    margin-inline-end: .8rem
}

@media (max-width: 1024px) {
    .info-hotspot-hero__container {
        width: 32%
    }
}

@media only screen and (min-width: 1025px) {
    .info-hotspot-hero__content {
        margin-block-end: 1.5rem
    }
}

@media (max-width: 1024px) {
    .info-hotspot-hero__content {
        text-align: center
    }
}

@media only screen and (min-width: 1025px) {
    .info-hotspot-hero__text-content {
        padding-inline-end: 2rem;
        padding-inline-start: 2rem
    }
}

@media only screen and (min-width: 1025px) {
    .info-hotspot-hero__img-w {
        margin-block-end: 1.5rem
    }
}

@media (max-width: 1024px) {
    .info-hotspot-hero__img-w {
        padding-block-end: 1.5rem;
        margin-block-end: 1.25rem;
        border-bottom: .1rem solid #e1e1e1
    }
}

@media only screen and (min-width: 1025px) {
    .info-hotspot-hero__img {
        display: block;
        margin: 0 auto;
        width: 10rem;
        max-width: 18.4rem
    }
}

@media (max-width: 1024px) {
    .info-hotspot-hero__img {
        width: 100%;
        margin-inline-start: auto;
        margin-inline-end: auto
    }
}

@media only screen and (min-width: 1025px) {
    .info-hotspot-hero__title, .info-hotspot-hero__text {
        text-align: center
    }
}

.info-hotspot-hero__title {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font: 23px 'FFDIN', Arial, sans-serif;
    font: 2.3rem 'FFDIN', Arial, sans-serif;
    margin-block-end: 1rem;
    color: #222
}

@media (max-width: 1024px) {
    .info-hotspot-hero__title {
        text-transform: uppercase;
        line-height: 1
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .info-hotspot-hero__title {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font: 18px 'FFDIN', Arial, sans-serif;
        font: 1.8rem 'FFDIN', Arial, sans-serif
    }
}

@media (max-width: 767px) {
    .info-hotspot-hero__title {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font: 17px 'FFDIN', Arial, sans-serif;
        font: 1.7rem 'FFDIN', Arial, sans-serif
    }
}

.info-hotspot-hero__text {
    font: 15px 'FFDIN', Arial, sans-serif;
    font: 1.5rem 'FFDIN', Arial, sans-serif;
    color: #222;
    word-break: break-all;
}

@media (max-width: 767px) {
    .info-hotspot-hero__text {
        font: 14px 'FFDIN', Arial, sans-serif;
        font: 1.4rem 'FFDIN', Arial, sans-serif
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

.FieldInput {
    &-Error {
        color: var(--color-red);
        display: inline-block;
    }

    &-Input {
        &_isValid {
            &_false {
                border-color: var(--color-red);

                &:focus {
                    border-color: var(--color-red);
                }
            }
        }

        &[is-data-valid="false"] {
            &:not(:focus) {
                border-color: var(--color-red);
            }
        }
    }

    &-InputWrapper {
        &_isInvalid {
            input {
                border: 1px solid var(--color-red);

                &:not(#postcode) {
                    @include isArabic {
                        text-align: start;
                    }
                }
            }
        }

        label {
            &.FieldInput {
                &-Label {
                    &_isFilled {
                        inset-block-start: 35%;
                        font-size: var(--font-size-nano);
                    }
                }
            }
        }

        &_isFilled,
        &_isFocused {
            input {
                padding-block-start: 19px;
                padding-block-end: 7px;

                &:not(#postcode) {
                    @include isArabic {
                        text-align: start;
                    }
                }
            }
        }
    }

    &-Wrapper {
        .Icon-CheckGreen {
            display: inline-block;
            width: 12px;
            height: 12px;
            margin-inline-start: 5px;
        }

        .Icon-Question {
            inset-block-start: 50%;
            transform: translateY(-50%);
            margin: 0 10px;
            width: 16px;
            height: 16px;
            cursor: pointer;

            @include isArabic {
                transform: translateY(-50%) scaleX(-1);
            }
        }
    }

    &-QuestionIconWrapper {
        cursor: pointer;
    }

    &-QuestionIconText {
        display: none;

        &_isToolTipVisible {
            display: inline-block;
            position: absolute;
            width: 145px;
            height: fit-content;
            background: var(--color-white);
            padding: 10px;
            inset-block-end: 43px;
            z-index: 2;
            font-size: 14px;
            border-radius: 5px;
            box-shadow: 0 .1rem 1rem 0 var(--color-grey-c);
            inset-inline-end: 3px;

            @include isArabic {
                text-align: end;
                inset-inline-end: unset;
                inset-inline-start: .3rem;
            }

            span {
                z-index: 3;
                width: 100%;

                @include isArabic {
                    margin-inline-end: 0;
                    margin-inline-start: 10px;
                    text-align: end;
                }
            }

            &::after {
                content: '';
                border: 1.2rem solid transparent;
                border-top: .8rem solid var(--color-white);
                inset-block-end: -1.6rem;
                inset-inline-end: 5px;
                inset-block-start: auto;
                position: absolute;

                @include isArabic {
                    inset-inline-end: unset;
                    inset-inline-start: 0;
                }
            }
        }
    }

    &-Example {
        position: absolute;
        z-index: 10;
        inset-block-start: 19px;
        inset-inline-start: 58px;
        font-size: 16px;
        color: var(--color-animated-before);

        @include isArabic {
            inset-inline-start: unset;
            inset-inline-end: 83px;
        }

        &_isKSA {
            @include isArabic {
                inset-inline-end: 10px;
            }
        }
    }

    &-FirstPart {
        margin-inline-end: 8px;
    }

    &-SecondPart {
        margin-inline-end: 10px;

        @include isArabic {
            margin-inline-end: 6px;
        }
    }

    &-ThirdPart {
        margin-inline-end: 8px;
    }

    &-FourthPart {
        @include isArabic {
            margin-inline-end: 8px;
        }
    }
}

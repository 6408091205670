/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import 'abstract/variables';
@import 'abstract/media';
@import 'abstract/button';
@import 'abstract/loader';
@import 'abstract/icons';
@import 'abstract/parts';
@import 'abstract/util';
@import 'abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import './abstract/abstract';
@import './base/font';
@import './base/breakpoints';
@import './base/keyframes';
@import './base/root';
@import './base/reset';
@import './base/button';
@import './base/list';
@import './base/link';
@import './base/table';
@import './base/input';
@import './base/fieldset';
@import './base/blockquote';
@import './base/icons';
@import './base/header';
@import './base/scroll';
@import './cms/block';

body>iframe[style*='2147483647'] {
    display: none;
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

.feature-grid-item {
    display: flex;
    justify-content: center;
    color: #222;
    direction: ltr;

    &-solo {
        .feature-grid-item__media {
            .feature-grid-item__img {
                img {
                    @include desktop {
                        object-fit: cover;
                        /* stylelint-disable-next-line declaration-no-important */
                        width: 100%!important;
                    }
                }
            }
        }

        .feature-grid-item__info-text {
            @include desktop {
                width: 100%;
            }
        }
    }

    @include desktop {
        max-width: 750px;
        margin: 0 auto;

        &:hover {
            .feature-grid-item__media-btn_desktop {
                z-index: 2;
                opacity: 1;
                transition: opacity .5s;
            }
        }

        &:not(:hover) {
            .feature-grid-item__media-btn_desktop {
                z-index: 2;
                opacity: 0;
                transition: opacity .5s;
            }
        }
    }

    @include small-desktop {
        max-width: 1500px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .feature-grid-item {
        flex-direction: column;
    }
}

@media (max-width: 767px) {
    .feature-grid-item {
        flex-direction: column
    }
}

@media only screen and (min-width: 1025px) {
    .feature-grid-item_reverse {
        flex-direction: row-reverse
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .feature-grid-item_reverse {
        flex-direction: column;
    }
}

.feature-grid-item__media, .feature-grid-item__info {
    width: 100%;
}

@include small-desktop {
    .feature-grid-item__media, .feature-grid-item__info {
        width: 50%;
    }
}

.feature-grid-item__media {
    position: relative;
    overflow: hidden;

    .feature-grid-item__img {
        padding-block-end: 50%;

        .Image-Image {
            /* stylelint-disable-next-line declaration-no-important */
            width: 99% !important; // for equal side margins in mobile wiew

            @include desktop {
                width: 100%;
            }
        }
    }

    @include small-desktop {
        max-width: 750px;
    }
}

.feature-grid-item__img {
    display: block;
    width: 100%;
    height: auto;

    .Image-Image {
        &_isLoading {
            min-height: 180px;

            @include desktop {
                min-height: 350px;
            }
        }
    }
}

.feature-grid-item__media-img {
    vertical-align: top
}

.feature-grid-item__media-btn {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font: 1.2rem 'FFDIN', Arial, sans-serif;

    @include desktop {
        font-size: 1.44rem;
    }
}

@media (max-width: 1024px) {
    .feature-grid-item__media-btn {
        padding-block-start: 1.28rem;
        padding-block-end: 1.28rem;
    }
}

.feature-grid-item__media-btn_desktop {
    position: absolute;
    inset-block-start: 50%;
    inset-inline-start: 25%;
    transform: translateX(-50%) translateY(-50%);
    z-index: -100;
    opacity: 0;
    transition: opacity .5s;
}

.feature-grid-item__info {
    text-align: center;
    display: flex;
    justify-content: center
}

@media only screen and (min-width: 1025px) {
    .feature-grid-item__info {
        position: relative
    }
}

.feature-grid-item_reverse .feature-grid-item__info {
    flex-direction: row-reverse
}

.feature-grid-item__info-title {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font: 2rem 'FFDIN', Arial, sans-serif;
    line-height: 1;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 0 0 1.5rem;
    font-weight: bold;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .feature-grid-item__info-title {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font: 2rem 'FFDIN', Arial, sans-serif;
        line-height: 1;
        font-weight: bold;
    }
}

@media (max-width: 767px) {
    .feature-grid-item__info-title {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font: 1.375rem 'FFDIN', Arial, sans-serif;
        line-height: 1.1;
        font-weight: bold;
    }
}

.feature-grid-item__info-desc {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font: 1.3rem 'FFDIN', Arial, sans-serif;
    line-height: 1.6rem;
}

@media (max-width: 1024px) {
    .feature-grid-item__info-desc {
        margin-block-end: 1.5rem
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .feature-grid-item__info-desc {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

@media (max-width: 767px) {
    .feature-grid-item__info-desc {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

.feature-grid-item__info-text, .feature-grid-item__info-img-w {
    width: 50%
}

@media (max-width: 767px) {
    .feature-grid-item__info-text, .feature-grid-item__info-img-w {
        width: 100%
    }
}

.feature-grid-item__info-text {
    display: flex;
    align-items: center
}

@media only screen and (min-width: 1025px) {
    .feature-grid-item__info-img-w {
        overflow: hidden;
        max-width: 375px;
    }
}

.feature-grid-item__info-text-i {
    padding: 1rem 4rem;
    margin-inline-start: auto;
    margin-inline-end: auto
}

@media (max-width: 767px) {
    .feature-grid-item__info-text-i {
        padding-block-start: 3rem;
        padding-block-end: 2rem;
    }
}

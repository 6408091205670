/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --search-bar-width: 270px;
    --search-stroke-color: rgb(119, 119, 119);
    --input-border-color: rgba(255, 255, 255, .2);
}

.SearchField {
    transition: color .25s;
    padding: 0 30px;

    @include desktop {
        border-radius: 2px;
        border: 1px solid var(--input-border-color);
        transition: border-color .15s ease 0s;
    }

    @include large-desktop {
        height: var(--search-field-height);
        max-width: 385px;
        text-align: end;
    }

    @include extra-large-desktop {
        height: var(--search-field-height);
    }

    @include large-desktop {
        padding: 0;
    }

    &:hover {
        border-color: rgba(255, 255, 255, .15);
        transition: border-color .15s ease 0s;
    }

    &::before,
    &::after {
        box-sizing: inherit;
        position: absolute;
        border: 1px solid transparent;
        width: 0;
        height: 0;
        content: none;

        @include large-desktop {
            content: '';
        }
    }

    &::before {
        inset-block-start: 0;
        inset-inline-start: 0;
    }

    &::after {
        inset-block-end: 0;
        inset-inline-end: 0;
    }

    &_isActive {
        --search-bar-width: 100%;

        &::before,
        &::after {
            width: 100%;
            height: 100%;
            content: none;

            @include large-desktop {
                content: '';
            }
        }

        &::before {
            border-top-color: var(--color-charcoal);
            border-right-color: var(--color-charcoal);
            transition: width .35s ease-out, height .15s ease-out .15s;
        }

        &::after {
            border-bottom-color: var(--color-charcoal);
            border-left-color: var(--color-charcoal);
            transition: border-color 0s ease-out .5s, width .15s ease-out .2s, height .15s ease-out .55s;
        }
    }

    &_isVisible {
        opacity: 1;
        pointer-events: all;
    }

    &-Wrapper {
        max-width: 520px;
        margin: 0 auto;

        @include desktop {
            width: 100%;
        }

        @include large-desktop {
            max-width: unset;
            margin: 0;
        }
    }

    &-Input {
        transition-property: opacity, background-color;
        transition-duration: 200ms;
        border: unset;
        font-size: 16px;
        max-width: 0;
        min-width: 0;
        opacity: 1;
        background-color: var(--color-white);
        caret-color: var(--color-black);
        color: var(--color-black);
        width: 100%;
        inset-block-start: 8px;
        inset-inline-end: unset;
        padding: 16px 50px 12px;

        @include large-desktop {
            background-color: var(--color-gray-nero);
            width: 98%;
            caret-color: var(--color-white);
            color: var(--color-white);
            inset-block-start: 1px;
            inset-inline-end: 1px;
            padding-block-end: 5px;
            padding-inline-start: 42px;
            padding-block-start: 4px;
        }

        @include before-desktop {
            max-width: 100%;
        }

        @include desktop {
            max-width: 520px;
        }

        @include large-desktop {
            height: 36px;
            padding-block-start: 7px;
        }

        @include extra-large-desktop {
            height: 42px;
        }

        &:focus {
            border: unset;
        }

        &_noValue {
            padding-inline-end: 20px;

            @include isArabic {
                padding-inline-end: 50px;
            }
        }
    }

    &-CloseButton {
        background-color: var(--secondary-base-color);
        width: 1.3em;
        height: 1.3em;
        border-radius: 100%;
        float: inline-end;
        inset-block-end: 40px;
        inset-inline-end: 16px;
        cursor: unset;
        opacity: 0;
        transition: opacity .4s ease-in-out;

        @include large-desktop {
            background-color: unset;
            inset-block-start: 8px;
            position: absolute;
            width: 20px;
            height: 20px;
            inset-block-end: 41px;
        }

        @include extra-large-desktop {
            inset-block-end: 45px;
            inset-block-start: 12px;
        }

        &_hasValue {
            z-index: 20;
            opacity: 1;
            cursor: pointer;
            transition: opacity .4s ease-in-out;

            @include large-desktop {
                transition: opacity .1s ease-in-out;
            }

            &:hover {
                @include large-desktop {
                    opacity: .6;
                    transition: opacity .1s ease-in-out;
                }
            }
        }

        svg {
            position: absolute;
            inset-block-start: 50%;
            inset-inline-start: 50%;
            transform: translateX(-50%) translateY(-50%);
            width: 65%;
            stroke: var(--search-stroke-color);
            stroke-width: 2px;

            @include large-desktop {
                stroke: none;
                stroke-width: 0;
                fill: var(--color-white);
                width: unset;
            }

            @include isArabic {
                @include large-desktop-down {
                    inset-block-start: 45%;
                    inset-inline-start: -15%;
                }
            }
        }
    }

    &-SearchFieldIcon {
        height: 15px;
        width: 15px;
        inset-block-end: 23px;
        inset-inline-start: 15px;

        @include large-desktop() {
            position: absolute;
            width: 48px;
            inset-block-start: 12px;
            inset-inline-start: 0;
            z-index: 2;
        }

        @include extra-large-desktop {
            width: 17px;
            inset-block-end: 28px;
            inset-inline-start: 19px;
            inset-block-start: 15px;
        }

        svg {
            fill: var(--color-black);

            @include large-desktop {
                height: 15px;
                width: 15px;
                fill: var(--color-white);
            }
        }
    }

    #search-field {
        @include large-desktop {
            z-index: 2;
        }
    }

    &-CloseIcon {
        @include close-button;

        &::before,
        &::after {
            height: 22px;
            inset-inline-start: 8px;
            inset-block-start: 2px;
            width: 2px;
        }
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';
@import '../../../../../../src/style/abstract/util';
@import '../../../../../../src/style/abstract/menu-scrollbar';

/**
 * Adyen Payments compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

.AdyenPayment {
    &-Icon {
        height: 50px;
        margin-inline-end: 10px;
    }

    &-Label {
        height: 100%;
        margin-block-end: 0px;
    }

    .CheckoutPayment {
        &-Button {
            align-items: center;
        }
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

$select-width: 160px !default;
$select-height: 45px !default;
$select-option-height: 36px !default;
$select-arrow-height: 6px !default;
$select-arrow-width: 6px !default;

:root {
    --select-option-background: #fff;
    --select-option-hover-background: #f0f0f0;
}

.FieldSelect {
    &-Select {
        width: 100%;
        min-width: 100%;

        @include isArabic {
            padding-inline-end: 15px;
        }

        @include desktop {
            pointer-events: none;
        }

        &_isExpanded {
            border-color: var(--input-color);
        }
    }

    &-Option {
        @include after-mobile {
            @include isArabic {
                padding-inline-start: 1.1rem;
            }
        }
    }
}

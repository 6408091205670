/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.SearchOverlay {
    --placeholder-animation: placeholderAnimation 2s linear infinite;

    @include desktop {
        transition-property: width, opacity, transform, border-color;
        transition-duration: 200ms;
        transform-origin: 0 0;
        transform: scaleY(0);
        will-change: width, transform, border;
        width: var(--search-bar-width);
        text-align: start;
        inset-block-start: calc(100% - 1px);
        border-width: 0 2px 2px;
        border-style: solid;
        max-height: 50vh;
        overflow-y: scroll;
    }

    &_isVisible {
        z-index: 100;

        @include small-desktop {
            transform: scaleY(1);
            border-color: var(--search-field-border-color);
            min-width: 700px;
            inset-inline-end: -30%;

            html[dir='rtl'] & {
                inset-inline-start: -30%;
                inset-inline-end: unset;
            }
        }
    }

    &::before {
        content: '';
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: 1px;
        width: calc(100% - 2px);
        height: 1px;
        background-color: var(--search-criteria-background);
    }

    &-Criteria {
        margin-block-end: 0;
        padding: 0;
        max-height: 0;
        opacity: 0;
        transition: opacity 200ms;
        will-change: max-height, padding, opacity;
        overflow: hidden;
        text-transform: uppercase;
        color: var(--color-input-label);
        font-weight: bold;
        font-size: 16px;
        text-align: start;

        html[dir='rtl'] & {
            text-align: end;
        }

        &_isVisible {
            opacity: 1;
            padding: .7rem 1rem .5rem;
            max-height: 100px;
        }

        @include small-desktop {
            max-width: 580px;
            margin: 0 auto;
            padding: 2rem 1rem 0;
            text-align: start;
        }
    }

    &-Results {
        padding: 2rem;
        background: var(--color-white);
        margin: 22px calc((100vw - 100%) / -2) 0;
        display: flex;
        flex-direction: column-reverse;

        ul {
            margin: 0 auto;

            @include large-desktop {
                padding: 0 1rem 1rem;
            }
        }

        @include large-desktop {
            margin: unset;
            max-height: calc(100vh - var(--header-total-height) - 7rem);
            overflow-y: auto;
            padding: 0;
            flex-direction: row;
        }

        > p {
            @include desktop {
                text-align: start;
            }
        }
    }

    &-NoResults {
        text-transform: uppercase;
        font-size: 16px;
        color: var(--color-input-label);
        font-weight: bold;
        padding-block-end: 1.5rem;

        @include small-desktop {
            padding: 2rem 1rem;
            text-align: start;
        }
    }

    &-ItemsWrapper {
        @include small-desktop {
            flex-grow: 1;
            flex-basis: 0;
        }
    }

    &-SuggestionsWrapper {
        display: flex;
        flex-direction: column;
        padding: .7rem 1rem 1rem;
        position: relative;

        @include small-desktop {
            flex-grow: 1;
            flex-basis: 0;
            padding: 0 0 0 20px;
            margin-inline-start: 10px;
            border-left: 1px solid var(--color-white-smoke);

            @include isArabic {
                text-align: end;
                padding: 0 20px 0 0;
                margin-inline-end: 10px;
                margin-inline-start: unset;
                border-right: 1px solid var(--color-white-smoke);
                border-left: unset;
            }
        }

        div {
            text-transform: uppercase;
            font-size: 16px;
            color: var(--color-input-label);
            font-weight: bold;
            padding-block-end: 1.5rem;

            @include small-desktop {
                padding: 2rem 1rem 0 0;
                text-align: start;

                @include isArabic {
                    text-align: end;
                    padding: 2rem 0 0 1rem;
                }
            }
        }

        span {
            color: var(--color-input-label);
            font-size: 16px;

            b {
                font-weight: bold;
                color: var(--color-black);
            }
        }

        &::after {
            inset-inline-start: calc((100vw - 100%) / -2);
            inset-block-end: 0;
            content: '';
            position: absolute;
            width: 100vw;
            height: 1px;
            background: var(--color-white-smoke);

            @include small-desktop {
                content: none;
            }
        }
    }

    &-SuggestionsLink {
        margin: 0;
        padding: .6rem 0;

        @include small-desktop {
            padding: .5em 0px;
        }
    }

    &-Link {
        color: inherit;

        &:hover {
            text-decoration: none;
        }
    }
}

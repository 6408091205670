/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
@mixin button-disabled {
    opacity: .25;
    cursor: not-allowed;
}

@mixin button(
    $bg-color: var(--button-background),
    $text-color: var(--button-color),
    $border-color: var(--button-border),
    $bg-hover-color: $bg-color,
    $text-hover-color: $text-color,
    $border-hover-color: $border-color
) {
    text-decoration: none; // because we never need underlines in buttons
    display: inline-block;
    border-radius: 0;
    font-size: 12px;
    font-family: var(--font-FFDIN);
    font-weight: bold;
    line-height: normal;
    text-transform: uppercase;
    padding: var(--button-padding);
    color: $text-color;
    background-color: $bg-color;
    transition-property: background-color, color, border;
    will-change: background-color, color, border;
    transition-timing-function: ease-out;
    transition-duration: .25s;
    cursor: pointer;
    border: {
        width: var(--button-border-width);
        style: solid;
        color: $border-color;
    }

    @include isArabic {
        font-family: var(--font-DINArabic);
    }

    &:hover,
    &:focus {
        text-decoration: none;
    }

    &:not([disabled]) {
        &:hover {
            @include after-mobile {
                border-color: $border-hover-color;
                background-color: $bg-hover-color;
                color: $text-hover-color;
            }
        }
    }

    &[disabled] {
        @include button-disabled();
    }

    &_isHollow {
        border-color: $bg-color;
        color: $bg-color;
        background: transparent;
    }

    &_likeLink {
        --button-border: transparent;
        --button-background: transparent;
        --button-color: var(--primary-base-color);
        --button-hover-border: transparent;
        --button-hover-background: transparent;
        --button-hover-color: var(--primary-base-color);

        text-transform: none;
        margin: 0;
        padding: 0;
        font-weight: normal;
        text-align: start;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

:root {
    --content-wrapper-width: 1400px;
}

.ContentWrapper {
    max-width: var(--content-wrapper-width);
    padding-inline: 1rem;
    margin-inline: auto;

    &.PageHeader {
        display: flex;
        justify-content: space-between;

        h1 {
            font-size: var(--header-title-font);
            line-height: 3.6rem;
            text-transform: uppercase;
            letter-spacing: -.05rem;
            margin: 0;
            font-weight: normal;
        }

        a {
            font-size: var(--header-link-font-big);
            font-weight: normal;
        }
    }
}

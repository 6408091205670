.Icon {
    position: relative;

    &-Facebook {
        width: 24px;
        height: 24px;
        background: url(../#{$icons-path}/facebook-icon.svg) center center no-repeat;
    }

    &-Instagram {
        width: 24px;
        height: 24px;
        background: url(../#{$icons-path}/instagram-icon.svg) center center no-repeat;
    }

    &-Twitter {
        width: 24px;
        height: 24px;
        background: url(../#{$icons-path}/twitter-icon.svg) center center no-repeat;
    }

    &-Youtube {
        width: 24px;
        height: 24px;
        background: url(../#{$icons-path}/youtube-icon.svg) center center no-repeat;
    }

    &-ArrowRight {
        width: 24px;
        height: 24px;
        background: url(../#{$icons-path}/arrow-right-black-icon.svg) center center no-repeat;

        &_color_white {
            background: url(../#{$icons-path}/arrow-right-white-icon.svg) center center no-repeat;
        }
    }

    &-Cart {
        width: 24px;
        height: 24px;
        background: url(../#{$icons-path}/cart-black-icon.svg) center center no-repeat;

        &_color_white {
            background: url(../#{$icons-path}/cart-white-icon.svg) center center no-repeat;
        }
    }

    &-User {
        width: 24px;
        height: 24px;
        background: url(../#{$icons-path}/user-black-icon.svg) center center no-repeat;

        &_color_white {
            background: url(../#{$icons-path}/user-white-icon.svg) center center no-repeat;
        }
    }

    &-ArrowScroll {
        width: 24px;
        height: 24px;
        background: url(../#{$icons-path}/arrow-scroll-black-icon.svg) center center no-repeat;
        transform: rotate(-90deg);

        &:hover {
            background: url(../#{$icons-path}/arrow-scroll-red-icon.svg) center center no-repeat;
        }
    }

    &-Searchbar {
        width: 24px;
        height: 24px;
        background: url(../#{$icons-path}/searchbar-black-icon.svg) center center no-repeat;

        &_color_white {
            background: url(../#{$icons-path}/searchbar-white-icon.svg) center center no-repeat;
        }
    }

    &-Right {
        width: 24px;
        height: 24px;
        background: url(../#{$icons-path}/right-black-icon.svg) center center no-repeat;

        &_color_white {
            background: url(../#{$icons-path}/right-white-icon.svg) center center no-repeat;
        }
    }

    &-Bottom {
        width: 24px;
        height: 24px;
        background: url(../#{$icons-path}/right-black-icon.svg) center center no-repeat;
        transform: rotate(90deg);

        &_color_white {
            background: url(../#{$icons-path}/right-white-icon.svg) center center no-repeat;
        }
    }

    &-Left {
        width: 24px;
        height: 24px;
        background: url(../#{$icons-path}/left-black-icon.svg) center center no-repeat;

        &_color_white {
            background: url(../#{$icons-path}/left-white-icon.svg) center center no-repeat;
        }
    }

    &-Up {
        width: 24px;
        height: 24px;
        background: url(../#{$icons-path}/left-black-icon.svg) center center no-repeat;
        transform: rotate(90deg);

        &_color_white {
            background: url(../#{$icons-path}/left-white-icon.svg) center center no-repeat;
        }
    }

    &-Question {
        width: 24px;
        height: 24px;
        background: url(../#{$icons-path}/question-icon.svg) center center no-repeat;
    }

    &-Menu {
        width: 24px;
        height: 24px;
        background: url(../#{$icons-path}/menu-black-icon.svg) center center no-repeat;

        &_color_white {
            background: url(../#{$icons-path}/menu-white-icon.svg) center center no-repeat;
        }
    }

    &-Close {
        width: 24px;
        height: 24px;
        background: url(../#{$icons-path}/close-black-icon.svg) center center no-repeat;

        &_color_white {
            background: url(../#{$icons-path}/close-white-icon.svg) center center no-repeat;
        }
    }

    &-Zoom {
        width: 24px;
        height: 24px;
        background: url(../#{$icons-path}/zoom-icon.svg) center center no-repeat;
    }

    &-CheckGreen {
        width: 24px;
        height: 24px;
        background: url(../#{$icons-path}/check-green-icon.svg) center center no-repeat;
    }

    &-InStock {
        width: 15px;
        height: 15px;
        padding: 7px;
        background: url(../#{$icons-path}/check-green-icon.svg) center center no-repeat;
        background-size: 8px 8px;
        border: 1px solid var(--color-green);
        border-radius: 100%;
    }

    &-Puma {
        width: 60px;
        height: 60px;
        background: url(../#{$globals-path}/logo.svg) center center no-repeat;

        &_color_black {
            background: url(../#{$globals-path}/logo-black.svg) center center no-repeat;
        }
    }

    &-PumaWithText {
        width: 60px;
        height: 60px;
        background: url(../#{$globals-path}/logo-with-text.svg) center center no-repeat;
    }

    &-Flag {
        width: 24px;
        height: 24px;
        background: url(../#{$icons-path}/flag.png) center center no-repeat;
    }

    &-Filter {
        width: 24px;
        height: 24px;
        background: url(../#{$icons-path}/filter.svg) center center no-repeat;
    }

    &-Info {
        border: 1px solid var(--color-input-border);
        border-radius: 50%;
        color: #999;
        display: inline-block;
        font-size: .875rem;
        font-weight: 700;
        height: 1.25rem;
        text-align: center;
        width: 1.25rem;
        margin-inline-end: 5px;

        &::before {
            content: "i";
        }
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

.Sitemap {
    &-Header {
        background-color: #{$c-gray-e};
        padding: 2.6rem 0 2.3rem;

        @include desktop {
            padding: 2.6rem 0 2.8rem;
        }

        @include small-desktop {
            padding: 3.1rem 0 3.5rem;
        }

        h1 {
            font-weight: normal;
            text-transform: uppercase;
            text-align: center;
            max-width: 1400px;
            font-size: 1.8rem;
            margin: 0 auto;
            padding: 0 1rem;

            @include desktop {
                text-align: start;
                font-size: 2rem;
            }

            @include small-desktop {
                font-size: 2.4rem;
            }

            @media (min-width: 1400px) {
                padding: 0;
            }
        }
    }

    &-CmsPages {
        max-width: 100%;
        margin: 0 1rem;
        padding-block-end: 2rem;
        border-bottom: 1px solid #{$c-input-border};

        @media (min-width: 1400px) {
            max-width: 1400px;
            margin: 0 auto;
        }

        h2 {
            margin: 0.8em 0;
            font-size: 1.7rem;

            @include desktop {
                font-size: 1.9rem;
            }

            @include small-desktop {
                font-size: 2.1rem;
            }
        }

        ul {
            display: grid;

            @include after-mobile {
                grid-template-columns: repeat(5, 20%);
            }

            li {
                padding-inline-start: 0;
                margin: 5px 15px 0 0;

                &::before {
                    display: none;
                }

                @include small-desktop {
                    margin: 2px 0;
                }

                a {
                    font-size: 1.2rem;

                    @include desktop {
                        font-size: 1.5rem;
                    }

                    @include small-desktop {
                        font-size: 1.7rem;
                    }

                    &:hover {
                        text-decoration: none;
                        color: var(--color-red);
                    }
                }
            }
        }
    }

    &-Categories {
        max-width: 100%;
        margin: 0 1rem;
        padding-block-end: 2rem;

        @media (min-width: 1400px) {
            max-width: 1400px;
            margin: 0 auto;
        }

        h2 {
            margin: 0.8em 0;
            font-size: 1.7rem;
            display: block;
            width: 100%;

            @include desktop {
                font-size: 1.9rem;
            }

            @include small-desktop {
                font-size: 2.1rem;
            }
        }

        &List {
            display: flex;
            flex-wrap: wrap;

            @include small-desktop {
                justify-content: space-between;
            }
        }

        ul {
            display: inline-block;
            vertical-align: top;
            width: 45%;
            margin: 0 2% 15px 0;

            @include desktop {
                width: 32%;
                margin-inline-end: 2%;

                &:nth-of-type(3n) {
                    margin-inline-end: 0;
                }
            }

            @include small-desktop {
                width: 10%;
                margin-inline-end: 2%;

                &:nth-of-type(4n) {
                    margin-inline-end: 0;
                }

                &:nth-of-type(n+4) {
                    margin-block-start: 0;
                }
            }

            li {
                padding-inline-start: 0;
                margin: 0;

                &::before {
                    display: none;
                }

                a {
                    font-size: 1rem;

                    @include desktop {
                        font-size: 1.3rem;
                    }

                    @include small-desktop {
                        font-size: 1.5rem;
                    }

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }

    &-MainLink {
        text-transform: uppercase;
        font-weight: bold;
        color: #{$c-red};

        &:hover {
            color: #{$black};
        }
    }

    &-SecondLevelLink {
        text-transform: uppercase;
        font-weight: bold;

        &:hover {
            color: #{$c-red};
        }
    }

    &-ThirdLevelLink {
        &:hover {
            color: #{$c-red};
        }

        +.Sitemap-SecondLevelLink {
            margin-block-start: 2rem;
        }
    }
}

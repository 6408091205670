/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

.SubscriptionPopup {
    &-Form {
        color: var(--color-white);

        .Subscription {
            &-FieldWrapper {
                #envelope {
                    width: 30px;
                    height: 30px;
                    inset-inline-end: 2rem;

                    @include mobile {
                        inset-inline-end: 1.5rem;
                        width: 25px;
                    }
                }

                .SubscriptionPopup {
                    &-Gender {
                        @include desktop {
                            display: flex;
                        }

                        .SubscriptionPopup {
                            &-GenderCheckboxes {
                                display: inline-flex;
                                justify-content: center;
                                width: 100%;
                                margin-block-start: 1rem;

                                @include desktop {
                                    flex: 60%;
                                }

                                .Field [type='checkbox']:checked + .input-control {
                                    background-color: transparent;
                                }

                                label {
                                    height: 50px;
                                    width: 50px;

                                    &:has(input[type='checkbox']:checked) {
                                        background: var(--color-red) url('./images/check.png') no-repeat center center;
                                    }

                                    .input-control {
                                        max-width: 100%;
                                        height: 100%;
                                        width: 100%;
                                        margin-inline-end: 0;

                                        &::after {
                                            content: unset;
                                        }
                                    }
                                }
                            }

                            &-GenderLabel {
                                font-size: 14px;
                                color: var(--color-input-label);
                                text-transform: uppercase;
                                letter-spacing: -.065em;
                                font-weight: 700;
                                line-height: 50px;
                                margin-inline-start: 20px;

                                @include desktop {
                                    margin: auto;

                                    @include isArabic {
                                        margin-inline-end: 20px;
                                        margin-inline-start: 20px;
                                    }
                                }
                            }

                            &-GenderSelect {
                                font-size: 16px;

                                @include desktop {
                                    flex: 20%;
                                    margin-block-start: 20px;

                                    @include isArabic {
                                        margin-block-start: 40px;
                                    }
                                }

                                &::after {
                                    content: ' *';
                                    color: var(--primary-dark-color);
                                }
                            }
                        }
                    }

                    &-DatePickerBlock {
                        font-size: 16px;

                        .FieldSelect-Select {
                            @include mobile {
                                pointer-events: none;
                            }
                        }

                        @include desktop {
                            display: flex;
                        }

                        #DatePicker {
                            color: var(--color-black);

                            .DatePicker {
                                &-Years,
                                &-Months {
                                    margin-inline-end: 15px;
                                }
                            }

                            @include desktop {
                                flex: 70%;
                            }

                            .FieldSelect-Options {
                                @include desktop {
                                    max-height: 140px;
                                }

                                @include mobile {
                                    height: unset;
                                    max-height: 195px;
                                }
                            }
                        }

                        label {
                            flex: 30%;
                            margin-block-start: 20px;

                            @include desktop {
                                flex: 10%;
                                margin-block-start: 14px;
                            }
                        }

                        span {
                            font-size: .8em;
                        }
                    }
                }

                .UpperTitle {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    color: var(--color-white);
                    margin-block-end: 2rem;

                    .Popup {
                        &-Title,
                        &-Text {
                            margin: 0;
                            padding: 0;
                            font: 1.7rem var(--font-FFDIN);
                            font-weight: bold;
                            text-align: center;
                            color: var(--color-silver);

                            @include isArabic {
                                font: 1.7rem var(--font-DINArabic);
                            }

                            @include mobile {
                                font-size: 1.1rem;
                            }
                        }

                        &-Title {
                            text-transform: uppercase;
                        }
                    }
                }

                .Form {
                    display: flex;
                    justify-content: center;

                    .Fields {
                        .FieldInput {
                            &-Wrapper {
                                height: 3rem;

                                input {
                                    width: 100%;
                                    height: 3rem;

                                    @include mobile {
                                        height: 2rem;
                                    }

                                    ~ label {
                                        font-size: 1rem;
                                        text-transform: capitalize;

                                        @include mobile {
                                            font-size: .6rem;
                                        }
                                    }
                                }

                                &_type_email {
                                    width: 35rem;

                                    @include mobile {
                                        width: 17rem;
                                        margin: 0;
                                        height: unset;
                                    }
                                }
                            }
                        }

                        .Field {
                            @include mobile {
                                margin-block-end: 3px;
                            }
                        }
                    }

                    .Icon {
                        &-CheckGreen {
                            position: absolute;
                            inset-block-start: 1rem;
                            inset-inline-end: 1rem;
                        }
                    }
                }

                &_isValid {
                    &::after {
                        display: none;
                    }
                }
            }

            &-TermsAndConditionsContainer {
                display: flex;
                justify-content: center;
                width: 85%;
                margin: 30px auto;

                @include mobile {
                    width: 100%;
                }

                label {
                    font: 1.1rem var(--font-FFDIN);
                    color: var(--color-white);
                    cursor: pointer;

                    @include isArabic {
                        font: 1.1rem var(--font-DINArabic);
                    }

                    a {
                        color: $c-red-primary-link;
                        margin: unset;

                        &:hover {
                            text-decoration: underline;
                        }
                    }

                    @include mobile {
                        font-size: .9rem;
                        opacity: .8;
                    }

                    .input-control {
                        max-width: 100%;
                        height: 100%;
                        width: 100%;
                        margin-inline-end: 0;
                    }
                }

                .Field {
                    [type='checkbox'] + label {
                        padding: 8px;
                        background: radial-gradient(var(--color-white), var(--color-silver));
                        margin-block-start: 2px;
                    }

                    [type='checkbox']:checked + label {
                        background: var(--color-red);

                        &::after {
                            margin: 2px;
                        }
                    }
                }
            }

            &-SubmitContainer {
                justify-content: start;
                inset-inline-start: 3.5rem;

                @include mobile {
                    inset-inline-start: 0;
                }

                button {
                    font: 1.2rem var(--font-FFDIN);
                    padding: var(--button-padding);

                    @include isArabic {
                        font: 1.2rem var(--font-DINArabic);
                    }

                    @include mobile {
                        font: 1rem var(--font-FFDIN);
                        padding: .5rem 1rem;

                        @include isArabic {
                            font: 1rem var(--font-DINArabic);
                        }
                    }
                }
            }

            &-TACLabel {
                width: 36rem;

                @include mobile {
                    width: 19rem;
                }
            }

            &-Message {
                margin-block-start: 2rem;
                color: var(--color-silver);
                font: 1.9rem var(--font-FFDIN);
                font-weight: bold;

                @include isArabic {
                    font: 1.9rem var(--font-DINArabic);
                }
            }

            &-CustomError,
            &-AgeError {
                display: none;

                &_isVisible {
                    display: block;
                    color: $c-red-primary-link;
                }
            }
        }
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

.Unsubscribe {
    padding: 4rem;

    h1 {
        margin-block-end: 1rem;
    }

    a,
    h1 {
        position: relative;
        inset-inline-start: 50%;
        transform: translateX(-50%);
        text-align: center;

        @include isArabic {
            inset-inline-start: unset;
            inset-inline-end: 50%;
            transform: translateX(50%);
        }
    }

    .Loader {
        margin-block-start: 2rem;
    }
}

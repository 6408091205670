/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

@media (min-width: 768px) {
    .only-mobile {
        /* stylelint-disable-next-line declaration-no-important */
        display: none !important;
    }
}

@media (min-width: 1025px), (max-width: 767px) {
    .only-tablet {
        /* stylelint-disable-next-line declaration-no-important */
        display: none !important;
    }
}

@media (max-width: 1024px) {
    .only-desktop {
        /* stylelint-disable-next-line declaration-no-important */
        display: none !important;
    }
}

@media only screen and (min-width: 1025px) {
    .hide-on-desktop {
        /* stylelint-disable-next-line declaration-no-important */
        display: none !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .hide-on-tablet {
        /* stylelint-disable-next-line declaration-no-important */
        display: none !important;
    }
}

@media (max-width: 767px) {
    .hide-on-mobile {
        /* stylelint-disable-next-line declaration-no-important */
        display: none !important;
    }
}

.kop-logo {
    max-height: 5rem;

    @media (max-width: 767px) {
        max-height: 4rem;
    }
}

.btn {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: $black;
    font-size: 14px;
    border: solid 1px $black;
    border-radius: 0;
    color: $white;
    cursor: pointer;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
    transition: background-color .3s, color .3s, border-color .3s;
    display: inline-block;
    padding: 15px 16px;
    position: relative;
    outline: none;
    font-weight: bold;
    text-decoration: none;

    @include after-mobile {
        font-size: 12px;
    }

    &:hover,
    &:focus {
        text-decoration: none;
    }
}

.btn_content {
    border: none;
    min-width: 138px;
}

input:is([type='button'], [type='submit'], [type='reset']),
input[type='file']::file-selector-button,
button {
    color: initial;
}

select {
    color: initial;
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

:root {
    --color-links-border: var(--color-grey-e);
    --color-links-hover: var(--color-grey-f6);
    --color-animated-before: var(--color-grey-c);
    --color-animated-after: var(--color-grey-7);
}

.QuickLinksOverlay {
    padding: 30px 40px;
    inset-inline-end: 0;
    inset-block-start: 0;
    width: 330px;

    &-Heading {
        display: flex;
        justify-content: space-between;
        margin-block-end: 7px;
        color: var(--color-gray-text);

        & > div {
            font-size: 12px;
            text-transform: uppercase;
            height: fit-content;
            margin-block-start: 2px;
            color: var(--color-gray-text);
        }

        .Icon-Close {
            inset-inline-start: 8px;
            inset-block-end: 3px;

            @include isArabic {
                inset-inline-start: unset;
                inset-inline-end: 4px;
            }

            &:hover {
                opacity: .6;
            }
        }
    }

    &-LinksWrapper {
        display: flex;
        flex-direction: column;

        a + a {
            margin-inline-start: 0;
        }

        .QuickLink,
        .QuickLink-Language {
            font-size: 18px;
            text-transform: capitalize;
            padding: 6px 20px 7px;
            margin-inline-start: -20px;
            margin-inline-end: -20px;

            &::after {
                content: '';
                transition: opacity .15s ease 0s;
                position: absolute;
                inset-block-start: 100%;
                inset-inline-start: 20px;
                width: calc(100% - 40px);
                height: 1px;
                background-color: var(--color-links-border);
                display: block;
            }

            &:hover {
                text-decoration: none;
                cursor: pointer;
                background-color: var(--color-links-hover);

                &::after {
                    opacity: 0;
                }
            }
        }
    }

    .ChooseLangBlock {
        &-PopupBtn {
            display: flex;
            justify-content: space-between;
            width: 100%;

            & > span {
                text-transform: capitalize;
                font-size: 18px;
            }

            & > div {
                color: var(--color-gray-text);
                display: flex;

                @include isArabic {
                    gap: 0;
                }
            }

            .Icon-Flag {
                display: block;

                @include isArabic {
                    margin-inline-end: 10px;
                }
            }

            .Icon-CustomFlag {
                display: block;
                height: 24px;
                width: 24px;

                @include isArabic {
                    margin-inline-start: 10px;
                }
            }
        }

        &-Prefix {
            font-size: 12px;
            font-family: var(--font-FFDIN);
            font-weight: bold;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-inline-end: 10px;

            @include isArabic {
                margin-inline-end: 0;
                font-family: var(--font-DINArabic);
            }
        }
    }

    &-Authorization {
        margin-block-start: 90px;

        button,
        .Link-Login {
            width: 100%;
            text-transform: capitalize;
            font-family: var(--font-FFDIN);
            font-weight: normal;
            font-size: 15px;

            @include isArabic {
                font-family: var(--font-DINArabic);
            }
        }

        .Link-Login {
            text-align: center;
            text-decoration: none;
        }
    }

    &-Registration {
        margin-block-start: 16px;
        display: flex;
        justify-content: center;

        & > span {
            color: var(--color-gray-mortar);
            margin-inline-end: 10px;

            @include isArabic {
                margin-inline-start: 10px;
            }
        }
    }

    .Link {
        &-Animated {
            text-transform: uppercase;

            &::before {
                content: '';
                position: absolute;
                inset-block-start: 100%;
                width: 100%;
                inset-inline-start: 0;
                height: 2px;
                background-color: var(--color-animated-before);
            }

            &::after {
                transition: width .3s cubic-bezier(.165, .84, .44, 1) 0s;
                inset-inline-start: auto;
                inset-inline-end: 0;
                width: 0;
                content: '';
                position: absolute;
                inset-block-start: 100%;
                height: 2px;
                background-color: var(--color-animated-after);
            }

            &:hover {
                text-decoration: none;

                &::after {
                    inset-inline-start: 0;
                    inset-inline-end: auto;
                    width: 100%;
                }
            }
        }
    }

    &-Mobile {
        background-color: var(--color-links-hover);
        max-width: 580px;
        padding: 50px 24px;
        width: 84%;

        .QuickLink {
            margin-inline-start: 0;
            margin-inline-end: 0;
            padding: 6px 0 7px;

            &::after {
                content: '';
                transition: none;
                position: absolute;
                inset-block-start: 100%;
                inset-inline-start: 0;
                width: 100%;
                height: 1px;
                background-color: var(--color-links-border);
                display: block;
            }

            &:hover {
                text-decoration: none;
                cursor: pointer;
                background-color: var(--color-links-hover);

                &::after {
                    opacity: 1;
                    background-color: var(--color-links-border)
                }
            }
        }

        .QuickLink-MobileLanguage {
            margin-block-start: 10px;

            &::after {
                content: '';
                position: absolute;
                inset-block-start: 100%;
                inset-inline-start: 0;
                width: 50%;
                height: 1px;
                background-color: var(--color-links-border);
                display: block;

                @include isArabic {
                    inset-inline-end: 0;
                    inset-inline-start: unset;
                }
            }

            .ChooseLangBlock {
                &-PopupBtn {
                    display: flex;
                    justify-content: space-between;

                    & > span {
                        text-transform: capitalize;
                        font-size: 18px;
                    }

                    & > div {
                        color: var(--color-gray-text);
                        display: flex;
                    }

                    .Icon-Flag {
                        display: block;
                        margin-inline-start: 10px;
                        padding-inline-start: 35%;

                        @include isArabic {
                            margin-inline-end: 10px;
                            margin-inline-start: 0;
                        }
                    }
                }

                &-Prefix {
                    font-size: 12px;
                    font-family: var(--font-FFDIN-bold);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin-inline-start: 10px;

                    @include isArabic {
                        font-family: var(--font-DINArabic);
                    }
                }
            }
        }

        .Icon-CustomFlag {
            display: block;
            height: 24px;
            width: 24px;
            margin-inline-start: 10px;

            @include isArabic {
                margin-inline-start: 0;
                margin-inline-end: 10px;
            }
        }
    }

    &-TopLinks {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@keyframes placeholderAnimation {
    from { background-position: -1200px 0; }

    to { background-position: 1200px 0; }
}

@keyframes appear {
    from { opacity: 0; }

    to { opacity: 1; }
}

@keyframes rotate {
    from { transform: rotate(0); }

    to { transform: rotate(360deg); }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.Loader {
    &-Main {
        background: transparent;
        border: 0;
        margin: 100px auto;
        width: 40px;
        height: 40px;
        position: relative;
        text-align: center;
        -webkit-animation: rotate 2s infinite linear;
        animation: rotate 2s infinite linear;

        &::after,
        &::before {
            content: none;
        }
    }

    &-Dot1,
    &-Dot2 {
        width: 60%;
        height: 60%;
        display: inline-block;
        position: absolute;
        inset-block-start: 0;
        background-color: #333;
        border-radius: 100%;
        -webkit-animation: bounce 2s infinite ease-in-out;
        animation: bounce 2s infinite ease-in-out;
    }

    &-Dot2 {
        inset-block-start: auto;
        inset-block-end: 0;
        -webkit-animation-delay: -1s;
        animation-delay: -1s;
    }
}

@-webkit-keyframes rotate {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes bounce {
    0%,
    100% {
        -webkit-transform: scale(0);
    }

    50% {
        -webkit-transform: scale(1);
    }
}

@keyframes bounce {
    0%,
    100% {
        transform: scale(0);
    }

    50% {
        transform: scale(1);
    }
}

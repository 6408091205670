/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

.VideoCarouselItem {
    &_isHero {
        max-height: 1536px;

        &:hover {
            .VideoCarouselItem-PlayPauseButton {
                display: block;
            }
        }
    
        @include after-mobile {
            height: 500px;
            max-height: 500px;
        }

        .VideoCarouselItem {
            &-YoutubeVideo, &-Youtube, &-YoutubeContainer {
                width: 100%;
        
                @include after-mobile {
                    height: 100%;
                }
            }
        
            &-Preview {
                img {
                    height: 100%;
                    object-fit: cover;
                }
            }
        
            &-FileOrUrlVideo {
                width: 100%;
                object-fit: cover;
        
                @include after-mobile {
                    height: 100%;
                }
            }
        
            &-Content {
                overflow: hidden;
                position: absolute;
                inset-block-start: 0;
                inset-block-end: 0;
                inset-inline-start: 0;
                inset-inline-end: 0;
                padding: 32px 96px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                pointer-events: none;
        
                @include tablet {
                    padding-inline-start: 50px;
                    padding-inline-end: 50px;
                }
                
                @include mobile {
                    text-align: center;
                    padding: 24px;
                }
            }
        
            &-Title,
            &-Text {
                text-align: center;
                margin-block-start: 0;
                user-select: none;
            }
        
            &-Title {
                font-size: 55px;
                line-height: 1.1;
                width: 90%;
                margin-block-end: 10px;
                margin-inline-start: auto;
                margin-inline-end: auto;
                font-weight: bold;
        
                @include tablet {
                    font-size: 48px;
                }
        
                @include mobile {
                    font-size: 24px;
                }
            }
        
            &-Text {
                font-size: 20px;
                margin-block-end: 0;
        
                @include small-desktop-down {
                    font-size: 18px;
                }
            }
        
            &-PlayPauseButton {
                position: absolute;
                width: 80px;
                height: 80px;
                background-size: 100% 100%;
                background-image: url('../../../public/assets/images/icons/play.svg');
                filter: invert(1);
                z-index: 10;
                inset-block-start: 50%;
                inset-inline-start: 50%;
                transform: translateX(-50%) translateY(-50%);
                backface-visibility: hidden;
                display: none;

                &_isPlaying {
                    background-image: url('../../../public/assets/images/icons/pause.svg');
                }
            }
        }
    }

    &_isItem {
        height: auto;
        margin-inline-end: 10px;

        .VideoCarouselItem {
            &-Title {
                font-size: 16px;
                font-weight: bold;
                color: $c-gray-nero;
                max-height: 48px;
                overflow: hidden;
                word-break: break-all;
            }

            &-Preview {
                img {
                    object-fit: cover;
                    height: calc(var(--slide-width) * 0.5625);
                }
            }
        }
    }
}

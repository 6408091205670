/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --content-min-height: calc(100vh - 665px - var(--header-height) + var(--header-top-banner-height));

    @include small-desktop {
        --content-min-height: calc(100vh - 560px - var(--header-height) + var(--header-top-banner-height));
    }

    @include extra-large-desktop {
        --content-min-height: calc(100vh - 600px - var(--header-height) + var(--header-top-banner-height));
    }
}

.NoMatch {
    display: block;

    h1 {
        font-size: 15px;
        color: $black;
        font-weight: bold;
        margin-block-end: 14px;

        @include after-mobile {
            font-size: 18px;
            margin-block-end: 22px;
        }
    }

    &-Subtitle {
        font-size: 14px;
        margin-block-end: 22px;
        font-weight: normal;

        @include after-mobile {
            font-size: 16px;
        }
    }

    &-Wrapper {
        text-align: start;
        max-width: none;
        padding: 25px 15px;

        @include after-mobile {
            margin-block-end: 0;
            padding: 50px;
        }
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

fieldset {
    border: 0;
}

legend {
    font-size: 1.3rem;
    padding-block-end: .5rem;
    // border-bottom: 1px solid #e0e0e0;
    display: block;
    width: 100%;
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

.VideoCarousel {
    &-Carousel {
        margin: 20px 0;
    }

    &-Header {
        margin-block-start: 23px;
        margin-block-end: 30px;

        @include mobile {
            padding: 0 23px;
        }
    }

    &-Title {
        font-size: 30px;
        margin: 0 0 10px;
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;

        @include tablet {
            font-size: 27px;
        }

        @include mobile {
            font-size: 22px;
        }
    }

    &-Text {
        font-size: 15px;
        margin: 10px 0;
        text-align: center;

        @include tablet {
            font-size: 18px;
        }

        @include mobile {
            font-size: 18px;
        }
    }

    &-Wrapper {
        padding: 0 40px;

        @include desktop {
            padding: 0 70px;
        }

        @include small-desktop {
            padding: 0 90px;
        }
    }

    &-SliderWrapper {
        max-width: 1020px;

        .SwatchSlider-Wrapper {
            display: none;
        }
    }

    &-VideoCarouselItem {
        display: flex;
        justify-content: center;
        align-self: baseline;
        min-width: var(--slide-width);
    }

    &-Arrow {
        inset-block-start: calc(50% - 11px);
        height: 22px;
        width: 22px;
        transition: .3s;
        border: {
            inset-inline-start: 1px solid var(--color-grey-alto);
            inset-block-start: 1px solid var(--color-grey-alto);
        }

        @include small-desktop {
            inset-block-start: calc(50% - 18px);
            height: 35px;
            width: 35px;
            border: {
                inset-inline-start: 2px solid var(--color-grey-alto);
                inset-block-start: 2px solid var(--color-grey-alto);
            }
        }

        &:hover {
            border-color: var(--color-black);
        }

        &_btnType {
            &_left {
                inset-inline-start: -25px;

                @include desktop {
                    inset-inline-start: -45px;
                }

                @include small-desktop {
                    inset-inline-start: -60px;
                }
            }

            &_right {
                inset-inline-end: -25px;

                @include desktop {
                    inset-inline-end: -45px;
                }

                @include small-desktop {
                    inset-inline-end: -60px;
                }
            }
        }
    }
}

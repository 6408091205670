/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.Breadcrumb {
    @include isArabic() {
        padding-inline-end: unset;
        padding-inline-start: 12px;
    }

    &-Link {
        font-size: var(--font-size-medium-small);

        &:hover {
            text-decoration: none;
            color: var(--color-red);
        }

        &:focus {
            text-decoration: none;
        }
    }

    &:not(:last-child) {
        margin: 0;

        &::after {
            content: '';
            position: relative;
            display: inline-block;
            height: var(--crumb-arrow-size);
            width: var(--crumb-arrow-size);
            transform: rotate(-135deg);
            margin-inline-start: var(--crumb-arrow-padding);
            border-color: currentColor;
            border-style: solid;
            border-width: 0 0 1px 1px;
            inset-block-start: -1px;

            @include isArabic {
                transform: rotate(45deg);
            }
        }
    }

    &::before {
        content: '';
        position: relative;
        display: inline-block;
        height: var(--crumb-arrow-size);
        width: var(--crumb-arrow-size);
        transform: rotate(45deg);
        margin-inline-end: 20px;
        border-color: currentColor;
        border-style: solid;
        border-width: 0 0 1px 1px;
        inset-block-start: -1px;
        padding: 0;

        @include isArabic {
            transform: rotate(-135deg);
        }

        @include desktop {
            content: none;
        }
    }

    &:last-child {
        --breadcrumbs-color: var(--color-black);

        pointer-events: all;

        @include desktop {
            --breadcrumbs-color: var(--breadcrumbs-active-color);

            pointer-events: none;
            margin: 0;
        }
    }

    &_isDisabled {
        display: none;
    }

    &_isLoading {
        inset-block-end: 2px;

        &::before {
            content: none;
        }
    }
}

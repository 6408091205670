/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --button-padding: 13px 22px;
    --button-color: #{$white};
    --button-background: #{$black};
    --button-border: #{$black};
    --button-hover-background: #{$black};
    --button-hover-color: #{$white};
    --button-hover-border: #{$black};
    --button-border-width: 1px;
}

.Button {
    @include button();

    &-Primary {
        @include button();

        &_color {
            &_white {
                @include button(
                    #{$white},
                    #{$black},
                    #{$white}
                )
            }

            &_gray {
                @include button(
                    #{$c-gray-nero},
                    #{$white},
                    #{$c-gray-nero}
                )
            }

            &_brown {
                @include button(
                    #{$c-brown-mongoose},
                    #{$white},
                    #{$c-brown-mongoose},
                    #{$c-brown-mongoose-light},
                    #{$white},
                    #{$c-brown-mongoose-light}
                )
            }
        }
    }

    &-AddToCart {
        @include button(
            #{$c-red},
            #{$white},
            #{$c-red},
            #{$c-red-dark},
            #{$white},
            #{$c-red-dark}
        )
    }

    &-Checkout {
        @include button(
            #{$c-red},
            #{$white},
            #{$c-red},
            #{$black},
            #{$white},
            #{$black}
        );

        &_withArrow {
            &::after {
                border: 4px solid transparent;
                border-top: 4px solid var(--color-white);
                border-right: 4px solid var(--color-white);
                content: '';
                display: block;
                position: absolute;
                inset-block-start: 3px;
                inset-inline-end: 3px;
                z-index: 1;
                height: 0;
                width: 0;

                @include isArabic {
                    inset-block-start: 3px;
                    border-right: 4px solid transparent;
                    border-left: 4px solid var(--color-white);
                }
            }
        }
    }
}

button {
    background-color: transparent;
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

.text-block {
    display: flex;
    flex-direction: column;
    max-width: var(--content-wrapper-width);
    margin-inline-start: auto;
    margin-inline-end: auto;
    padding: 23px;

    h1 {
        padding-block-end: 22px;
    }

    div {
        display: inline;
        line-height: 2.2rem;
        margin-block-start: 15px;
    }

    div[className='text-block-more'] { // Isn't working with (class + class selector) :/
        display: none;
    }

    &.expanded {
        div[className='text-block-less'] { // Isn't working with (class + class selector) :/
            display: none;
        }

        div[className='text-block-more'] { // Isn't working with (class + class selector) :/
            display: inline;
        }
    }

    span {
        cursor: pointer;
        font-size: 14px;
        padding-inline-start: 3px;
        text-decoration: underline;
    }

    .tb-cta-btns {
        order: 5;
        display: flex;
        flex-wrap: wrap;

        .ContentWrapper_isLoading & {
            display: none;
        }

        @include mobile {
            justify-content: center;
        }

        .tb-cta-btn {
            margin-inline-end: 5px;

            @include mobile {
                margin: 5px;
            }
        }
    }
}

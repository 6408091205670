/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.Notification {
    max-width: unset;
    width: 100%;
    margin-block-end: 0;
    text-align: center;
    padding: 10px;
    justify-content: center;

    @include desktop {
        max-width: unset;
        width: 100%;
    }

    &_is {
        &_opening {
            animation: fadeIn var(--animation-duration);
        }

        &_closing {
            animation: fadeOut var(--animation-duration);
        }
    }

    &_type {
        &_error,
        &_info {
            --notification-color: var(--color-white);
            --notification-background: var(--c-red-primary-link);
        }

        &_success {
            --notification-color: var(--color-white);
            --notification-background: var(--c-green-notification);
        }
    }

    &-Text {
        font-size: var(--font-size-medium-small);
        word-break: break-all;
        margin-inline-end: 0;

        a {
            text-decoration: unset;
        }

        @include desktop {
            font-size: var(--fs-header-desktop-small);
        }
    }

    @keyframes fadeIn {
        from { opacity: 0; }

        to { opacity: 1; }
    }

    @keyframes fadeOut {
        from { opacity: 1; }

        to { opacity: 0; }
    }
}

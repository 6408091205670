/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

:root {
    --color-input-lightest: rgba(255, 255, 255, .1);
    --color-input-lighter: rgba(255, 255, 255, .2);
    --color-input-light: rgba(255, 255, 255, .3);
}

.Subscription {
    &-Form {
        display: flex;

        .Field {
            margin-block-start: 0;
            flex-grow: 1;

            &Input {
                &-Input {
                    width: 100%;
                    padding: 11px 46px 12px 16px;

                    html[dir='rtl'] & {
                        padding: 11px 16px 12px 46px;
                    }

                    border-width: 1px;
                    border-radius: 2px;
                    font-size: 13px;
                    border-color: var(--color-input-lighter);
                    background-color: var(--color-gray-nero);
                    color: var(--color-white);
                    transition: border-color .15s ease 0s;
                    caret-color: var(--color-white);

                    + .FieldInput-Label {
                        font-size: 13px;
                        color: var(--color-input-light);

                        &::after {
                            display: none;
                        }
                    }

                    &:hover {
                        border-color: var(--color-input-lightest);
                    }

                    &:focus {
                        border-color: var(--color-input-light);

                        + .FieldInput-Label {
                            font-size: 13px;
                            inset-block-start: 50%;
                            color: var(--color-input-lighter);
                        }
                    }

                    &::placeholder {
                        text-transform: uppercase;
                    }

                    &_isFilled {
                        + .FieldInput-Label {
                            display: none;
                        }
                    }
                }

                &-Label {
                    font-size: 13px;

                    &::after {
                        content: '';
                    }
                }

                &-Error {
                    position: absolute;
                    inset-block-end: unset;
                    inset-block-start: calc(100% + 5px);
                }

                &-Wrapper {
                    margin-block-start: 0;
                }
            }
        }
    }

    &-Title {
        padding: 8px 0;
        color: var(--color-white);
        font-size: 14px;
        text-transform: capitalize;
        font-weight: 500;

        @include small-desktop {
            padding: 0;
            margin-block-end: 10px;
        }
    }

    &-Submit {
        position: absolute;
        height: 90%;
        inset-inline-end: 0;
        width: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all .15s ease 0s;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;

        &::before {
            content: url('../../../public/assets/images/icons/arrow-right-white-icon.svg');
            height: 24px;
            width: 24px;
            opacity: .5;

            @include isArabic {
                transform: rotate(180deg);
            }
        }

        &:disabled {
            cursor: text;
        }

        &:not(:disabled) {
            background-color: var(--color-brown-mongoose);

            &::before {
                opacity: 1;
            }
        }
    }

    &-SubmitContainer {
        display: flex;
        justify-content: flex-end;

        button {
            margin-block-start: 15px;
            padding: 15px 16px;
            transition: background-color .3s, color .3s, border-color .3s;

            &:not(:disabled) {
                &:hover {
                    /* stylelint-disable-next-line declaration-no-important */
                    background-color: #fff !important;
                    /* stylelint-disable-next-line declaration-no-important */
                    color: #000 !important;
                }
            }
        }
    }

    &-TermsAndConditionsContainer {
        display: flex;
        flex-direction: row-reverse;
        margin-block-start: 8px;

        label {
            font-size: 14px;

            @media (max-width: 480px) {
                font-size: 10px;
            }
        }
    }

    &-TermsAndConditions {
        height: min-content;

        label {
            outline: 1px solid #000;
            outline-offset: -1px;
            /* stylelint-disable-next-line declaration-no-important */
            width: 13px !important;
            /* stylelint-disable-next-line declaration-no-important */
            height: 13px !important;

            &::after {
                /* stylelint-disable-next-line declaration-no-important */
                inset-block-start: 5px !important;
                /* stylelint-disable-next-line declaration-no-important */
                inset-inline-start: 0 !important;
            }
        }

        &_isChecked {
            label {
                outline: none;
            }
        }
    }

    &-TACLabel {
        margin-inline-start: 21px;
    }

    &-Logo {
        height: 42px;
        box-sizing: content-box;
        display: flex;
        border-bottom: .1rem solid #ccc;
        margin-block-end: 25px;
        padding-block-end: 5px;

        @include desktop {
            height: 45px;
        }

        &::before {
            content: url('../../../public/assets/images/global/logo-black.svg');
            width: 42px;
            height: 42px;

            @include desktop {
                width: 45px;
                height: 45px;
            }
        }
    }

    &-Message {
        font-size: 16px;
    }

    &-EmptyEmailField {
        width: 100%;
    }

    &-CustomError {
        color: var(--color-red);
        font-size: 9px;
        display: none;

        &_isVisible {
            display: block;
        }

        @include small-desktop() {
            font-size: 11px;
        }
    }

    &-FieldWrapper {
        &_isValid {
            &::after {
                width: 12px;
                height: 12px;
                content: url('../../../public/assets/images/icons/check-green-icon.svg');
                display: inline-block;
                position: absolute;
                inset-block-start: calc(50% - 6px);
                inset-inline-end: 12px;

                @include isArabic {
                    inset-inline-end: unset;
                    inset-inline-start: 12px;
                }
            }
        }
    }

    &-Popup {
        @include mobile {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
            padding: 0 21px;
        }

        .Popup {
            &-Content {
                padding: 19px 25px 23px 25px;
                width: 92.5%;
                margin: 0 auto;

                @include after-mobile {
                    width: 70%;
                    padding: 25px 48px;
                }

                @include small-desktop {
                    width: 41.5%;
                    padding-block-end: 53px;
                }

                .Field {
                    &_type_checkbox {
                        display: inline-block;

                        label {
                            display: inline-block;
                        }
                    }

                    &Input {
                        &-Input {
                            width: 100%;
                        }

                        &-Error {
                            display: none;
                        }
                    }
                }
            }

            &-Header {
                position: absolute;
                inset-inline-end: 20px;

                @include desktop {
                    inset-inline-end: 45px;
                }

                @include isArabic {
                    inset-inline-end: unset;
                    inset-inline-start: 28px;

                    @include desktop {
                        inset-inline-start: 45px;
                    }
                }

                .Popup-CloseBtn {
                    inset-inline-end: unset;
                    inset-inline-start: 0;
                    inset-block-start: 0;

                    @include isArabic {
                        inset-inline-start: unset;
                    }
                }
            }
        }
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/* stylelint-disable number-max-precision */

.Field {
    margin-block-start: 0;
    margin-block-end: 5px;

    &_type {
        &_checkbox {
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-end;

            label {
                &:first-of-type {
                    ~ label {
                        margin-inline-end: 1rem;
                        margin-inline-start: 0;
                    }
                }
            }

            &:has(.CheckoutBilling-Checkbox) {
                margin-block-end: 2rem;
            }
        }
    }

    [type='checkbox'],
    [type='radio'] {
        + label {
            border: 1px solid var(--color-input-border);
            height: 2rem;
            inset-block-start: -.1rem;
            inset-inline-start: 0;
            position: absolute;
            transition: none;
            width: 2rem;

            &::after {
                --checkmark-color: transparent;

                background: var(--checkmark-color);
                transition: none;
            }

            &:hover {
                @include after-mobile {
                    --box-color: inherit;
                }

                &::after {
                    @include after-mobile {
                        --checkmark-color: transparent;
                    }
                }
            }
        }

        + .input-control {
            border: 1px solid var(--input-color);

            @include desktop {
                height: 2rem;
                max-width: 2rem;
                width: 2rem;
            }
        }

        &:hover:not(:disabled) + .input-control {
            @include desktop {
                // --box-color: var(--color-red);
                border-color: var(--color-red);

                &::after {
                    @include desktop {
                        --checkmark-color: transparent;
                    }
                }
            }
        }

        &:checked + label {
            background-color: var(--color-red);
            border-color: var(--color-red);

            &::after {
                --checkmark-color: #{$white};

                inset-inline-start: 0.5rem;
                inset-block-start: 0.75rem;
            }
        }

        &:checked {
            + .input-control {
                border-color: var(--color-red);
                background-color: var(--color-red);

                &::after {
                    --checkmark-color: #{$white};
                }
            }
        }

        &:hover:checked {
            + .input-control {
                &::after {
                    --checkmark-color: #{$white};
                }
            }
        }
    }

    [type='checkbox'] {
        + label {
            position: relative;
            display: flex;

            &:first-of-type {
                order: 2;

                ~ label {
                    margin-inline-end: 1rem;
                }
            }

            &::after {
                transition: none;
            }
        }

        &:checked {
            &:hover {
                + label {
                    @include after-mobile {
                        background-color: var(--color-red);
                    }
                }
            }
        }
    }

    [type='radio'] {
        + label {
            &::after {
                transition: none;
            }
        }

        &:focus + label {
            --box-color: var(--secondary-light-color);

            border-color: var(--color-red);

            &::after {
                --checkmark-color: #{$white};
            }
        }


        &:checked + label {
            &::after {
                --checkmark-color: #{$white};

                height: 10px;
                inset-block-start: 0;
                inset-inline-start: 0;
                width: 10px;
            }
        }
    }

    &-ErrorMessage {
        color: var(--color-red);
    }

    &_type_tel {
        input {
            font-size: var(--font-size-medium);
            width: 100%;

            @include after-mobile {
                font-size: var(--font-size-medium);
            }
        }

        label {
            pointer-events: none;

            &.FieldInput {
                &-Label {
                    &_isFocused,
                    &_isFilled {
                        font-size: var(--font-size-pico);
                        inset-block-start: 35%;

                        @include after-mobile {
                            font-size: var(--font-size-nano);
                        }
                    }
                }
            }
        }

        .FieldInput {
            &-Wrapper {
                display: flex;
            }

            &-InputWrapper {
                width: 100%;
            }
        }
    }

    &-Labels {
        span {
            letter-spacing: 1.05px;
        }
    }

    &-Label {
        &_isRequired {
            color: var(--color-input-label);
            inset-inline-end: 3px;
            inset-block-end: 1px;
            font-size: inherit;
            font-weight: unset;
        }
    }

    .FieldInput {
        &-Label {
            font-size: var(--font-size-medium);
            pointer-events: none;

            &_isFocused,
            &_isFilled {
                font-size: var(--font-size-pico);

                @include after-mobile {
                    font-size: var(--font-size-nano);
                }
            }
        }

        &-Input {
            &:focus {
                &::placeholder {
                    opacity: 1;
                }
            }

            &::placeholder {
                opacity: 0.2;
            }
        }

        &-Wrapper {
            position: relative;
            margin-block-end: 5px;

            &_hasError {
                input:not([type='number']),
                textarea,
                select {
                    border: 1px solid var(--color-red);
                }
            }

            input[type=date] {
                color: var(--color-white);

                &:focus {
                    color: var(--color-black);
                }

                &.FieldInput-Input_isFilled {
                    color: var(--color-black);
                }

                @include isArabic {
                    flex-direction: row-reverse;
                }
            }
        }

        &-Error {
            display: inline-block;
            /* stylelint-disable-next-line declaration-no-important */
            inset-block-start: unset !important;
            inset-block-end: unset;
            min-height: 10px;
            position: relative;

            .Field-ErrorMessage:not(:first-child) {
                display: none;
            }
        }
    }

    &-CheckboxLabel {
        font-weight: 700;

        &:not([class*='_isDisabaled']):hover {
            @include desktop {
                color: var(--color-black);
            }
        }
    }
}

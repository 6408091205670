/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

.CmsPage {
    margin-block-start: unset;

    span {
        @include isArabic {
            /* stylelint-disable-next-line declaration-no-important */
            font-family: var(--font-DINArabic)!important;
        }
    }

    &-Wrapper.CmsPage-Wrapper_page_width_full {
        padding: 0;
        margin: 0;

        div[class*='button'] a {
            text-decoration: none;
        }
    }

    &-Content {
        --cms-min-height: 0;

        min-height: var(--cms-min-height);

        h2 {
            text-align: unset;

            &.ProductCarousel-Title {
                /* stylelint-disable-next-line declaration-no-important */
                text-transform: uppercase !important;
            }
        }

        .Image_ratio_square {
            @include mobile {
                padding-block-end: 0;
            }
        }
    }

    &-Wrapper {
        margin: 4.1rem 0 7rem;
        padding: 0 2.1rem;

        &_disableMargins {
            margin: 0;
            padding: 0;
            max-width: none;
        }

        @include desktop {
            margin: 4.7rem 0 7rem;
            padding: 0 13rem 0 9rem;

            &_disableMargins {
                margin: 0;
                padding: 0;
                max-width: none;
            }
        }

        @include mobile {
            margin: 1rem 0 7rem;
        }

        @media (min-width: 1400px) {
            margin: 4.7rem auto 7rem;
            padding: 0;

            &_disableMargins {
                margin: 0;
                padding: 0;
                max-width: none;
            }
        }

        * {
            line-height: 1.5;
        }

        :not(div.split-hero) {
            h2,
            h3,
            h4,
            h5 {
                font-size: 1.5rem;
                margin: 2.6rem 0 1.65rem;
                text-transform: none;
                font-weight: bold;

                @include desktop {
                    font-size: 2.25rem;
                }

                @include small-desktop {
                    font-size: 2.65rem;
                    margin: 1.6rem 0 0 0;
                }

                strong {
                    font-weight: inherit;
                    font-size: inherit;
                }
            }

            p {
                font-size: 1rem;
                margin-block-end: 1rem;

                @include desktop {
                    font-size: 1.3rem;
                }

                p {
                    font-size: 1rem;
                    margin-block-end: 1rem;

                    @include desktop {
                        font-size: 1.3rem;
                        margin-block-end: 2rem;
                    }

                    @include small-desktop {
                        font-size: 1.45rem;
                    }
                }
            }
        }

        ul > li {
            font-size: 1rem;
            color: #{$c-gray-title};
            letter-spacing: -.08rem;

            @include desktop {
                letter-spacing: -.12rem;
                font-size: 1.3rem;
            }

            @include small-desktop {
                font-size: 1.7rem;
            }

            &::before {
                position: absolute;
                margin-inline-start: 0;
                margin-inline-end: 0;
            }
        }

        a {
            text-decoration: underline;
        }

        table {
            width: 100%;
            table-layout: auto;

            tr:nth-child(even) {
                background-color: transparent;
            }

            td .Image-Image {
                position: static;
            }

            td {
                min-width: unset;
            }
        }

        strong {
            font-weight: bold;
        }

        hr {
            height: 1px;
            background-color: #{$c-gray-7};
        }

        .cms-nav-list > li {
            a {
                color: inherit;
                letter-spacing: inherit;
                font-weight: inherit;
                text-decoration: none;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        .cms-contact-wrap {
            a {
                text-decoration: none;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        .cms-title {
            border-bottom: 1px solid #{$black};
            padding-block-end: 2.3rem;
            margin-block-end: 1rem;
            margin-block-start: 1rem;
            font-size: 1.6rem;
            text-transform: uppercase;

            @include desktop {
                margin-block-start: 2rem;
                font-size: 2.2rem;
                padding-block-end: 2.5rem;
            }

            @include small-desktop {
                margin-block-start: 2.26rem;
                font-size: 2.8rem;
            }

            +h3,
            +h2 {
                margin-block-start: 2rem;

                @include desktop {
                    margin-block-start: 4.4rem;
                }

                @include small-desktop {
                    margin-block-start: 5.25rem;
                }
            }
        }

        .cms-contact__title {
            margin-block-end: 1rem;

            @include desktop {
                margin-block-end: 1.5rem;
            }

            @include small-desktop {
                margin-block-end: 2rem;
            }
        }

        .cms_bold {
            font-weight: bold;

            * {
                font-weight: bold;
            }
        }

        .cms_underline {
            text-decoration: underline;
        }

        .accordion-wrap {
            margin-block-end: 2rem;
            border-top: 1px solid #{$c-input-border};
        }

        .accordion__title {
            border-bottom: 1px solid #{$c-input-border};
            text-transform: uppercase;
            padding: 0;

            &-wrap {
                cursor: pointer;
            }

            &-text {
                margin: 0;
                font-size: .95rem;
                padding: 2.2rem 0;
                font-weight: bold;
                line-height: 1;

                @include desktop {
                    font-size: 1.08rem;
                    padding: 3rem 0 3.25rem;
                }

                @include small-desktop {
                    font-size: 1.2rem;
                    padding: 3.6rem 0 3.85rem;
                }
            }
        }

        .accordion__content {
            .Image {
                padding-block-end: 35%;
            }
        }

        .size__subtitle {
            font-size: 2rem;
            font-weight: bold;
        }

        .size-table {
            td {
                border-top: 1px solid #{$c-gray-c};
                font-size: .8rem;
                padding: 1rem;
                vertical-align: middle;

                @include desktop {
                    font-size: 1rem;
                }
            }

            &__bold {
                font-weight: bold;
            }

            &__image {
                /* stylelint-disable-next-line declaration-no-important */
                vertical-align: top !important; //for correct style rules appling for td elements where images are placed
                /* stylelint-disable-next-line declaration-no-important */
                padding: 0 1rem !important;
                /* stylelint-disable-next-line declaration-no-important */
                min-width: 10rem !important;

                .Image {
                    padding-block-end: 275%;
                }
            }
        }
    }

    .Table-Wrapper {
        overflow-x: auto;
    }

    &-Sitemap {
        max-width: 100%;
        padding: 0;
        margin: 0;
    }

    &-HomePagePlaceholder {
        @include placeholder-animation;
    }

    &-HomePagePlaceholderCentered {
        @include after-mobile {
            display: flex;
            justify-content: center;
        }
    }

    &-HomePagePlaceholderSlider {
        height: 400px;
        margin: 20px;

        @include placeholder-animation;

        @include after-mobile {
            width: 100%;
            max-width: 1120px;
            margin: 20px 90px;
        }
    }

    &-HomePagePlaceholderContainer {
        & > .CmsPage-HomePagePlaceholder {
            &:nth-child(1) {
                height: 800px;

                @include after-mobile {
                    margin: 24px 30px;
                    height: 600px;
                }
            }

            &:nth-child(3) {
                height: 600px;
                margin: 24px 0;

                @include after-mobile {
                    height: 500px;
                }
            }
        }
    }

    &-HomePagePlaceholderGroup {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include after-mobile {
            display: none;
        }

        & > .CmsPage-HomePagePlaceholder {
            &:nth-child(1) {
                width: 446px;
                height: 76px;
                margin: 24px;
            }

            &:nth-child(2) {
                width: 249px;
                height: 28px;
                margin: 24px 0;
            }
        }
    }

    .ExpandableContent {
        border-top: solid 1px $c-gray-de;
        border-bottom: solid 1px $c-gray-de;

        &-Button {
            padding: 12px 0 14px 0;

            @include after-mobile {
                padding: 42px 0;
            }

            &_isContentExpanded {
                padding-block-end: 5px;

                .ExpandableContent-Heading,
                .ExpandableContent-SubHeading {
                    color: $c-gray-text;
                }
            }
        }

        &-Heading,
        &-SubHeading {
            text-transform: uppercase;
        }

        &-Content {
            padding: 0 1rem;
            max-height: 0;
            overflow: hidden;
            opacity: 0;

            &_isContentExpanded {
                @include expanded-content;
            }
        }
    }
}

.StyleGuide {
    padding: 0;
}

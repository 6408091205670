/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.NoMatch {
    opacity: 0;
    animation: appear 100ms 300ms forwards;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mobile {
        margin-block-start: 0;
        margin-block-end: 0;
    }

    h1 {
        font-size: 12rem;
        font-weight: 400;
        color: var(--primary-base-color);
        line-height: 1;
    }

    &-Button {
        margin-block-start: 2rem;
    }

    &-Subtitle {
        font-size: 3rem;
        font-weight: 700;
        margin: 0;
    }

    &-Wrapper {
        text-align: center;

        @include after-mobile {
            margin-block-end: 10rem;
        }
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

.navigation-promo-banner {
    flex: 1 auto;
    position: relative
}

.navigation-promo-banner__media-w {
    height: 100%
}

.navigation-promo-banner__media {
    background-position: center;
    background-size: cover;
    display: block;
    height: 100%
}

.navigation-promo-banner__content {
    font: 14px 'FFDIN', Arial, sans-serif;
    font: 1.4rem 'FFDIN', Arial, sans-serif;
    transform: translateY(-50%);
    inset-block-start: 50%;
    padding: 3rem 3.5rem 2rem;
    pointer-events: none;
    position: absolute;
    inset-inline-start: 0;
    width: 55%
}

@media only screen and (min-width: 1025px) and (max-width: 1199px) {
    .navigation-promo-banner__content {
        width: 65%
    }
}

.navigation-promo-banner__title-w {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font: 40px 'FFDIN', Arial, sans-serif;
    font: 4rem 'FFDIN', Arial, sans-serif;
    line-height: 1;
    margin: 0 0 1.6rem;
    text-transform: uppercase
}

@media only screen and (min-width: 1025px) and (max-width: 1199px) {
    .navigation-promo-banner__title {
        font-size: .9em
    }
}

.navigation-promo-banner__text {
    margin: 1.5rem 0
}

.navigation-promo-banner__link {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font: 17px 'FFDIN', Arial, sans-serif;
    font: 1.7rem 'FFDIN', Arial, sans-serif;
    pointer-events: auto;
    text-transform: uppercase
}

.navigation-promo-banner__link::after {
    content: '>'
}

.navigation-promo-banner__button {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font: 14px 'FFDIN', Arial, sans-serif;
    font: 1.4rem 'FFDIN', Arial, sans-serif;
    letter-spacing: .04em;
    padding: 1rem 1.5rem 1.3rem;
    pointer-events: auto
}

.navigation-promo-banner_bleed .navigation-promo-banner__content {
    inset-block-end: 0;
    inset-block-start: auto;
    inset-inline-start: 0;
    inset-inline-end: 0;
    text-align: center;
    transform: none;
    width: 100%
}

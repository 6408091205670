body {
    &:not(.mac) {
        &::-webkit-scrollbar, ::-webkit-scrollbar {
            width: 16px;
        }
        
        &::-webkit-scrollbar-track, ::-webkit-scrollbar-track {
            background-color: $scrollbar-track-color;
        
            &:hover {
                background-color: $scrollbar-track-hover-color;
            }
        }
        
        &::-webkit-scrollbar-thumb, ::-webkit-scrollbar-thumb {
            border-radius: 8px;
            border-width: 5px;
            border-style: solid;
            background-color: $scrollbar-thumb-color;
            border-color: $scrollbar-track-color;
        
            &:hover {
                background-color: $scrollbar-thumb-hover-color;
                border-color: $scrollbar-track-color;
            }
        
            &:active {
                background-color: $scrollbar-thumb-active-color;
            }
        }
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

.SubscriptionBlockFormCMS {
    width: 100%;

    &-Wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include large-desktop {
            padding: 10rem 24rem;
        }

        @include desktop {
            padding: 10rem 16rem;
        }

        @include tablet {
            padding: 6rem;
        }

        @include mobile {
            padding: 3rem 2rem;
        }
    }

    &-Header,
    &-SubHeader {
        color: var(--color-white);
        font-family: var(--font-FFDIN);
        /* stylelint-disable-next-line declaration-no-important */
        font-size: var(--fs-header-desktop-small) !important;
        text-align: center;

        @include isArabic {
            font-family: var(--font-DINArabic);
        }
    }

    &-Header {
        font-weight: 700;
    }

    &-SubHeader {
        /* stylelint-disable-next-line declaration-no-important */
        margin-block-end: 3rem !important;
    }

    .SubscriptionBlock {
        &-Form {
            .SubscriptionBlock {
                &-Grid {
                    display: grid;
                    grid-template-columns: 50% 50%;

                    @include mobile {
                        grid-template-columns: 100%;
                    }

                    .Field_type_text,
                    .Field_type_email {
                        width: 100%;

                        input {
                            width: 80%;

                            @include mobile {
                                width: 100%;
                            }
                        }
                    }
                }

                &-Gender,
                &-DatePickerBlock {
                    max-width: 330px;

                    @include mobile {
                        width: 100%;
                    }
                }

                &-GenderSelect,
                &-DatePickerBlock label {
                    color: var(--color-white);
                    font-weight: 700;
                }

                &-DatePickerBlock {
                    display: flex;
                    flex-direction: column;

                    >div:first-child {
                        @include after-mobile {
                            display: flex;
                        }
                    }

                    label {
                        margin-inline-end: 2rem;
                    }

                    .DatePicker {
                        @include mobile {
                            justify-content: start;
                        }
                    }
                }

                &-GenderSelect {
                    margin-block-start: 15px;
                    margin-inline-end: 1rem;

                    @include mobile {
                        margin-block-start: 0;
                        margin-block-end: 15px;
                    }

                    @include isArabic {
                        margin-inline-end: 0;
                        margin-inline-start: 1rem;
                        margin-block-start: 15px;
                    }
                }

                &-GenderCheckboxes {
                    margin-block-start: 0;

                    @include mobile {
                        justify-content: start;
                    }
                }

                &-GenderLabel {
                    color: var(--color-white);
                    font-weight: 700;
                    margin-inline-start: 1rem;
                    margin-block-start: 2px;
                }

                &-GenderSelect::after {
                    content: none;
                }

                &-Disclaimer {
                    background-color: transparent;

                    >label {
                        color: var(--color-white);
                        min-height: 50px;

                        >p {
                            height: auto;
                        }
                    }
                }

                &-DisclaimerRequire {
                    color: var(--color-white);
                    font-weight: 700;
                    text-align: start;
                    margin-block-start: 2rem;

                    p {
                        margin: 0;

                        &::before {
                            color: var(--color-white);
                        }
                    }
                }

                &-Cta {
                    text-align: center;
                    margin-block-start: 2rem;

                    button {
                        background-color: var(--color-white);
                        color: var(--color-black);
                        font-weight: 700;
                        font-size: var(--font-size-medium-small);
                        width: 25%;

                        @include mobile {
                            width: 100%;
                        }
                    }
                }
            }
        }

        &-Message {
            text-align: center;
            margin-block-start: 2rem;
            font-size: var(--fs-header-desktop-small);
            color: var(--color-white);
            font-weight: 700;
        }
    }
}

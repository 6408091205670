/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

@mixin after-small-mobile() {
    @media (min-width: 460px) {
        @content;
    }
}

@mixin very-small-mobile() {
    @media (max-width: 330px) {
        @content;
    }
}

.SubscriptionBlock {
    &-Popup {
        @include desktop {
            display: flex;
        }

        .Form {
            @include desktop {
                max-width: 400px;
                margin: auto;
            }

            .Fields {
                .Field_type_email {
                    input {
                        width: 100%;
                    }

                    .FieldInput-Label {
                        &::after {
                            content: '*';
                        }
                    }
                }
            }
        }

        .Image {
            display: none;

            @include desktop {
                display: block;
                max-width: 225px;
            }

            img {
                @include desktop {
                    object-fit: cover;
                    width: 300px;
                }
            }
        }
    }

    &-Form {
        .SubscriptionBlock {
            &-FieldWrapper {
                .Popup-Title {
                    font-weight: bold;
                    font-style: normal;
                    text-transform: uppercase;
                    font-size: 20px;
                    text-align: center;
                    margin: 0;
                }
            }

            &-PromoContent {
                text-align: center;
                margin: 1em 0;
                line-height: unset;

                p {
                    font-weight: normal;
                    font-style: normal;
                    font-size: 14px;
                    line-height: unset;

                    strong {
                        font-weight: bold;
                    }
                }
            }

            &-Gender {
                @include desktop {
                    display: flex;
                }

                .SubscriptionBlock {
                    &-GenderCheckboxes {
                        display: inline-flex;
                        justify-content: center;
                        width: 100%;
                        margin-block-start: 1rem;

                        @include desktop {
                            flex: 60%;
                        }

                        .Field [type='checkbox']:checked + .input-control {
                            background-color: transparent;
                        }

                        label {
                            height: 50px;
                            width: 50px;

                            &:has(input[type='checkbox']:checked) {
                                background: var(--color-red) url('./images/check.png') no-repeat center center;
                            }

                            .input-control {
                                max-width: 100%;
                                height: 100%;
                                width: 100%;
                                margin-inline-end: 0;

                                &::after {
                                    content: unset;
                                }
                            }
                        }
                    }

                    &-GenderLabel {
                        font-size: 14px;
                        color: var(--color-input-label);
                        text-transform: uppercase;
                        letter-spacing: -.065em;
                        font-weight: 700;
                        line-height: 50px;
                        margin-inline-start: 20px;

                        @include desktop {
                            margin: auto;

                            @include isArabic {
                                margin-inline-end: 20px;
                                margin-inline-start: 20px;
                            }
                        }
                    }

                    &-GenderSelect {
                        font-size: 16px;

                        @include desktop {
                            flex: 20%;
                            margin-block-start: 20px;

                            @include isArabic {
                                margin-block-start: 40px;
                            }
                        }

                        &::after {
                            content: ' *';
                            color: var(--primary-dark-color);
                        }
                    }
                }
            }

            &-DatePickerBlock {
                font-size: 16px;

                @include desktop {
                    display: flex;
                }

                #DatePicker {
                    @include desktop {
                        flex: 70%;
                    }
                }

                label {
                    flex: 30%;
                    margin-block-start: 20px;

                    @include desktop {
                        flex: 10%;
                        margin-block-start: 14px;
                    }
                }

                span {
                    font-size: .8em;
                }
            }

            &-Disclaimer {
                display: inline-flex;
                background-color: var(--color-grey-e);
                padding: 14px 10px 10px;
                height: 84px;
                margin: 10px 0;

                @include desktop {
                    max-height: 71px;
                }

                .Field {
                    margin: auto;

                    [type='checkbox']:checked + label {
                        background: var(--color-red) url('./images/check.png') no-repeat center center;
                    }

                    label {
                        height: 40px;
                        width: 40px;

                        &::after {
                            content: unset;
                        }
                    }
                }
            }

            &-DisclaimerLabel {
                height: 40px;
                inset-block-start: unset;

                @include after-small-mobile {
                    margin: auto;
                }

                @include very-small-mobile {
                    inset-block-start: -7px;
                }

                p {
                    vertical-align: middle;
                    height: 40px;
                    font-size: .75em;
                    padding-inline-start: 20px;
                    line-height: unset;

                    @include desktop {
                        font-size: .9em;
                    }

                    @include isArabic {
                        padding-inline-start: unset;
                        padding-inline-end: 20px;
                    }

                    a {
                        color: var(--color-gray-text);
                        text-decoration: underline;
                    }
                }
            }

            &-DisclaimerRequire {
                text-align: center;

                p {
                    &::before {
                        content: '* ';
                        color: var(--primary-dark-color);
                    }
                }
            }

            &-Additional {
                margin: 10px 0;

                p {
                    font-size: 9px;
                    color: var(--color-grey-7);
                    text-align: start;
                    line-height: unset;
                    margin: 0;

                    @include isArabic {
                        text-align: end;
                    }

                    a {
                        color: var(--color-gray-text);
                        text-decoration: underline;
                    }
                }
            }

            &-Cta {
                .Button-AddToCart {
                    width: 100%;
                    border: unset;
                }
            }
        }

        .Icon-Puma {
            width: 60px;
            height: 60px;
            background: url('./images/puma-logo.png') center center no-repeat;
            background-size: contain;
        }
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --navigation-tabs-height: 60px;
    --mobilemenu-popup-top: calc(var(--navigation-tabs-height) + var(--visible-global-banner-part));
}

@supports (-webkit-touch-callout: none) {
    .sticky-ios {
        .NavigationTabs {
            position: relative;
            inset-block-start: 0;

            &-IosOffset {
                margin-block-start: 0;
            }
        }
    }
}

.NavigationTabs {
    inset-block-start: 0;
    position: sticky;
    inset-block-end: unset;
    background: var(--color-gray-nero);
    transition: background 200ms linear;
    display: flex;
    justify-content: space-between;
    height: var(--navigation-tabs-height);

    @supports (-webkit-touch-callout: none) {
        position: fixed;

        &-IosOffset {
            margin-block-start: 62px;
        }
    }

    .hideOnScroll & {
        transform: none;
    }

    @include large-desktop {
        display: none;
    }

    @include mobile {
        border-top: unset;
        z-index: 100;
        min-height: 62px;
    }

    &_isItemClicked {
        background: var(--color-white);
        transition: background 200ms linear;
    }

    &-Nav {
        justify-content: space-between;
        margin: unset;
        padding-block-start: 12px;
        padding-inline: 35px;
        width: 100%;

        @include desktop {
            padding-block-start: 4px;
        }

        @include mobile {
            height: 50px;
        }
    }

    &-First,
    &-Second {
        display: flex;
        justify-content: center;

        @include mobile {
            width: 25%;
        }
    }

    &-MainLogo {
        height: 60px;
        width: 92px;
        padding: 16px 25px;
    }

    &-TabletMenu {
        display: flex;

        span {
            inset-block-start: 3px;
            margin: 0 18px 0 0;

            @include isArabic {
                margin: 0 18px 0;
            }
        }
    }

    &-MenuTitle {
        font-size: 12px;
        margin-inline-start: 18px;
        display: none;

        html[dir='rtl'] & {
            margin-inline-end: 18px;
        }

        .NavigationTabs_isItemClicked & {
            margin-inline-start: unset;
        }

        @include desktop {
            display: block;
        }
    }

    &-LanguageSwitch {
        color: var(--color-white);
        font-size: var(--font-size-medium-big);
        padding-inline-end: 18px;

        &_iconColor_black {
            color: var(--color-black);
        }
    }

    &-SearchBlock {
        position: fixed;
        width: 100%;
        height: 100%;
        display: none;

        &:not(.NavigationTabs-SearchBlock_isStatic) {
            @include before-desktop {
                height: 100%;
            }
        }

        &.Overlay {
            background-color: var(--color-white);

            @include tablets-extended {
                position: fixed;
                inset-block-start: var(--overlay-position-top);
            }
        }

        .NavigationTabs {
            &-SearchWrapper {
                padding: 22px 0;
                background-color: var(--color-white-smoke);

                @include large-desktop {
                    padding: 0;
                    background-color: unset;
                }
            }
        }

        &_isVisible {
            display: block;
        }
    }

    &-MiniWishlistItemCount,
    &-MinicartItemCount {
        background-color: var(--minicart-item-count-background);
        border-radius: 50%;
        padding: 4px 7px 3px;
        color: #fff;
        inset-block-end: 10px;
        inset-inline-end: 6px;
        position: absolute;
        line-height: 13px;
        font-size: var(--font-size-small);
        font-weight: 700;

        @include large-desktop {
            inset-block-end: 26px;
        }
    }

    &-EmptySpace {
        height: 100%;
        width: 100%;
    }

    &-Button {
        display: flex;
        padding-inline-end: 18px;

        &_searchIcon {
            svg {
                height: 18px;
                width: 18px;
            }
        }

        &_cartIcon {
            svg {
                height: 24px;
                width: 24px;
            }
        }

        &_menuIcon,
        &_closeIcon {
            svg {
                height: 20px;
                width: 20px;
            }
        }
    }

    &-MiniWishlistIcon {
        width: 21px;
        height: 21px;
        vertical-align: middle;
        cursor: pointer;
        margin-inline-end: 16px;

        &_iconColor_white {
            /* stylelint-disable-next-line declaration-no-important */
            fill: white !important;
        }

        &_iconColor_black {
            fill: black;
        }
    }
}

.Popup_block_MobileMenu {
    background-color: transparent;
    /* stylelint-disable-next-line declaration-no-important */
    inset-block-start: var(--mobilemenu-popup-top) !important;
    overflow-y: hidden;

    &.Popup_isVisible {
        height: unset;
    }

    .Popup {
        &-Content {
            max-width: unset;
            width: 100vw;
            overflow-x: hidden;
            max-height: unset;
            border-top: 1px solid var(--color-grey-e);
            position: absolute;
            overflow-y: hidden;
            inset-block-start: 0;
            padding: 0;
        }

        &-CloseBtn {
            opacity: 0;
        }

        &-Header {
            display: none;
        }
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

.Field {
    margin-block-start: 10px;

    &-LabelContainer {
        display: flex;
        align-items: center;
        line-height: 1;

        .ChevronIcon {
            width: 23px;
        }
    }

    &-Label {
        font-size: 12px;
        padding-block-end: 8px;
    }

    &_type {
        &_file {
            &.Field {
                &_isValid {
                    input,
                    textarea,
                    select {
                        border: 1px solid var(--primary-success-color);
                    }

                    input {
                        &+label {
                            word-break: break-word;
                            text-align: center;
                            border-color: var(--primary-success-color);
                        }
                    }
                }

                &_hasError {
                    input {
                        &+label {
                            word-break: break-word;
                            text-align: center;
                            border-color: var(--primary-error-color);
                        }
                    }
                }

                button,
                .Field-SelectFileBtn {
                    cursor: pointer;
                    z-index: 2;

                    &:hover {
                        color: var(--button-hover-background);
                    }
                }

                .Field-SelectFileBtn {
                    pointer-events: none;
                }
            }
        }
    }

    [type='file'] {
        border-style: dashed;
        cursor: pointer;
        height: 100px;
        opacity: 0;
        max-width: 100%;
        z-index: 1;
        background-color: red;

        +label {
            align-items: center;
            border: 2px dashed var(--input-border-color);
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100px;
            position: absolute;
            inset-block-start: 0;
            width: 100%;
            margin-block-start: 20px;

            p {
                font-size: 16px;
                margin-block-end: 0;
            }

            span,
            button {
                color: var(--primary-base-color);
                font-size: 18px;
                font-weight: 700;
                text-transform: uppercase;
            }

            .UploadIcon {
                margin-block-end: 10px;
                height: 50px;
                width: auto;
            }

            +p,
            +button+p {
                margin-block-start: 5px;
                margin-block-end: 0;
            }
        }
    }

    &-UploaderNote {
        display: block;
        margin-block-end: 12px;
        text-align: center;

        @include desktop {
            max-width: 350px;
        }
    }
}

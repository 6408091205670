/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.SwatchSlider {
    display: flex;
    will-change: transform;
    transition: transform var(--animation-speed);
    transform: translate3d(var(--translateX), 0, 0);
    justify-content: var(--justify-content);

    &-Wrapper {
        max-width: 132px;
        margin: 0 auto;

        @include desktop {
            max-width: 162px;
        }

        .Slider {
            --slide-width: 0;
            --justify-content: unset;
        }

        .ProductCard-ColorAttribute {
            min-width: var(--slide-width);
        }
    }

    &-Arrow {
        border-left: 1px solid var(--color-black);
        border-top: 1px solid var(--color-black);
        height: 7px;
        position: absolute;
        inset-block-start: calc(50% - 3px);
        width: 7px;
        transform: rotate(-45deg);
        z-index: 1;

        @include desktop {
            height: 10px;
            width: 10px;
            inset-block-start: calc(50% - 4px);
        }

        &_isDesktop {
            &:hover {
                border-color: var(--color-red-vivid);
            }
        }

        &_btnType {
            &_left {
                transform: rotate(-45deg);
                inset-inline-start: -8px;

                @include desktop {
                    inset-inline-start: -15px;
                }

                @include isArabic {
                    transform: rotate(135deg);
                }
            }

            &_right {
                transform: rotate(135deg);
                inset-inline-end: -8px;

                @include desktop {
                    inset-inline-end: -15px;
                }

                @include isArabic {
                    transform: rotate(-45deg);
                }
            }
        }
    }

    .Slider {
        --slide-width: 0;
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --crumb-arrow-padding: 16px;
    --crumb-arrow-size: 6px;
    --breadcrumbs-height: 60px;
    --breadcrumbs-active-color: var(--color-gray-crumb);
}

.Breadcrumbs {
    display: flex;
    height: var(--breadcrumbs-height);
    background-color: var(--color-white);
    margin-inline-start: 20px;

    @include desktop {
        margin-inline-start: 80px;
    }

    &-List {
        line-height: 47px;
        height: 100%;
        padding: 10px 0;
    }

    &-SearchResults {
        display: none;
        padding: 10px 20px 10px 0;
        line-height: 45px;
        font-size: var(--font-size-medium-small);
        color: var(--breadcrumbs-active-color);

        @include desktop {
            display: block;
        }
    }

    &-Home {
        display: block;
        padding: 10px 20px 10px 0;
        margin-inline-start: 12px;
        line-height: 45px;
        font-size: var(--font-size-medium-small);

        &::before,
        &::after {
            position: relative;
            display: inline-block;
            height: var(--crumb-arrow-size);
            width: var(--crumb-arrow-size);
            border-color: currentColor;
            border-style: solid;
            border-width: 0 0 1px 1px;
            inset-block-start: -1px;
        }

        &::before {
            content: '';
            transform: rotate(45deg);
            margin-inline-end: 10px;

            @include isArabic {
                transform: rotate(-135deg);
            }

            @include desktop {
                content: none;
            }
        }

        &::after {
            content: none;

            @include desktop {
                content: '';
                transform: rotate(-135deg);
                margin-inline-start: var(--crumb-arrow-padding);

                @include isArabic {
                    transform: rotate(45deg);
                }
            }
        }

        &:hover,
        &:focus {
            text-decoration: none;
        }

        span {
            &:hover {
                color: var(--color-red);
            }
        }

        &_isNotHomeBreadcrumb {
            display: none;

            @include desktop {
                display: block;
            }
        }
    }

    li {
        padding-inline-start: 0;

        &::before {
            padding-inline-end: 0.1em;
        }
    }
}
